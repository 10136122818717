import React, { useState } from 'react';
import { Menu, MenuItem, MenuList, IconButton } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';

// Api
import api from '../../api';
import { config } from '../../utills/constants';

// Options Components
import ReportTrip from '../../components/Modal/ReportTrip';
import ShareTrip from '../../components/Modal/ShareTrip';
import { FireStore } from '../../utills/FireStore';

// Icons
import blockIcon from '../../assets/messaging/block-red.png';
import followIcon from '../../assets/menu-icons/Follow.png';
import profileIcon from '../../assets/menu-icons/Profile.png';
import offersIcon from '../../assets/menu-icons/trade-offers.png';

const dynamicMenuItemStyle = {
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: 10,
};

const dynamicTextStyle = {
  fontFamily: 'Poppins-Medium',
  fontSize: 'clamp(12px,1.5vw,14px)',
  color: 'rgb(16, 27, 16)',
  textTransform: 'capitalize',
};

function TripBoxMenu({ data, handleChange, onBlockSuccess, openMakeOfferModal }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const { userInfo, isAuth } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };

  const isCurrentUser = (userId, hostId) => {
    if (userId === hostId) {
      return true;
    } else {
      return false;
    }
  };

  const isMenuOpen = Boolean(anchorEl);

  const handleViewProfile = () => {
    if (!isAuth) {
      handleChange('restrictionModel', true);
      return;
    }
    navigate(`/profile/public/${data?.hostId?._id}`);
  };

  const handleBlockUser = async () => {
    if (!isAuth) {
      handleChange('restrictionModel', true);
      return;
    }
    const body = { url: `/user/blockAUser/${userInfo?._id}/${data?.hostId?._id}` };
    try {
      const response = await api.put(body.url, body, config);
      if (
        response?.data?.message === `This user doesn't exist.` ||
        response?.data?.message === 'This user has blocked you.'
      ) {
        toast.error('Failed to Block User, please try again');
        return;
      }
      FireStore.updateUserinFirestoreOnlyRoom(userInfo?._id, response?.data?.data);
      toast.success(`Blocked User Successfully`);
      onBlockSuccess();
      console.log('response: ', response);
    } catch (error) {
      console.error('error: ', error);
    }
  };

  const handlefollowUser = async (type) => {
    if (!isAuth) {
      handleChange('restrictionModel', true);
      return;
    }
    try {
      const followAction = type === 'Follow' ? 'followAUser' : 'unFollowAUser';
      const body = { url: `/user/${followAction}/${userInfo?._id}/${data?.hostId?._id}` };
      const response = await api.put(body.url, body, config);
      if (
        response?.data?.message === `This user doesn't exist.` ||
        response?.data?.message === 'This user has blocked you.'
      ) {
        toast.error('Failed to Follow User, please try again');
        return;
      }
      const message = `${type === 'Follow' ? 'Following' : 'Un-Followed'} User Successfully`;
      toast.success(message);
      onBlockSuccess();
    } catch (error) {
      console.error('error: ', error);
    }
  };

  const isFollower = data?.hostId?.followers?.find((el) => el?.userId === userInfo?._id)?.follower;

  const isRoute = (allowedPaths) => {
    return allowedPaths?.some((path) => location?.pathname?.startsWith(path));
  };

  const items = [
    {
      condition: isRoute(['/profile']),
      component: <ShareTrip data={data} title="Share Trip" />,
    },
    {
      condition: !isCurrentUser(userInfo?._id, data?.hostId?._id) && isRoute(['/profile']),
      component: (
        <span onClick={() => openMakeOfferModal(data)} style={dynamicMenuItemStyle}>
          <img src={offersIcon} width="23" />
          <span style={dynamicTextStyle}>Make Offer</span>
        </span>
      ),
    },
    {
      condition: !isCurrentUser(userInfo?._id, data?.hostId?._id) && !isRoute(['/profile']),
      component: (
        <span onClick={handleViewProfile} style={dynamicMenuItemStyle}>
          <img src={profileIcon} width="23" />
          <span style={dynamicTextStyle}>View Profile</span>
        </span>
      ),
    },
    {
      condition: !isCurrentUser(userInfo?._id, data?.hostId?._id) && !isRoute(['/profile']),
      component: (
        <span
          onClick={() => handlefollowUser(isFollower ? 'UnFollow' : 'Follow')}
          style={dynamicMenuItemStyle}
        >
          <img src={followIcon} width="23" />
          <span style={dynamicTextStyle}>{isFollower ? 'UnFollow' : 'Follow'}</span>
        </span>
      ),
    },
    {
      condition: !isCurrentUser(userInfo?._id, data?.hostId?._id) && !isRoute(['/profile']),
      component: (
        <span onClick={handleBlockUser} style={dynamicMenuItemStyle}>
          <img src={blockIcon} width="23" />
          <span style={dynamicTextStyle}> Block User</span>
        </span>
      ),
    },
    {
      condition: !isCurrentUser(userInfo?._id, data?.hostId?._id),
      component: <ReportTrip data={data} handleChange={handleChange} />,
    },
  ];

  return (
    <div>
      <IconButton onClick={handleMenuClick} sx={{ margin: 0, padding: 0.2 }}>
        <MoreHoriz />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        style={{ display: isOpen ? 'block' : 'none' }}
      >
        <MenuList>
          {items?.map((el, index) => {
            return (
              <>
                {el?.condition && (
                  <MenuItem onClick={() => setIsOpen(false)} key={index}>
                    {el?.component}
                  </MenuItem>
                )}
              </>
            );
          })}
        </MenuList>
      </Menu>
    </div>
  );
}

export default TripBoxMenu;
