import { db } from '../firebase';
import { config, getFullName } from './constants';
import {
  collection,
  addDoc,
  doc,
  setDoc,
  query,
  where,
  getDocs,
  arrayUnion,
  deleteDoc,
  updateDoc,
  orderBy,
  limit,
  serverTimestamp,
} from 'firebase/firestore';

import api from '../api';
import { useSelector } from 'react-redux';

const chatroomsRef = collection(db, 'chatrooms');
const getMessage = (text) => {
  if (text.length > 70) {
    text = text.slice(0, 70) + '...';
    return text;
  } else return text;
};
async function sendMessage(
  sender,
  receiver,
  message,
  messageType,
  messageImage,
  success,
  profileInfo
) {
  console.log('sendMessage', profileInfo);
  const userId1 = sender._id;
  const userId2 = receiver._id;
  const timestamp = serverTimestamp();

  const user = { [userId1]: true, [userId2]: true };
  const createRoomObject = {
    latestMessage: null,
    user,
    userId1: sender,
    userId2: receiver,
    createdAt: timestamp,
    updatedAt: timestamp,
  };

  const chatMessageObject = {
    user: sender,
    isRead: false,
    message: message,
    type: messageType,
    image: messageImage,
    deletedBy: [],
    createdAt: timestamp,
    updatedAt: timestamp,
  };
  const chatRoomArr = await getChatRoom();
  // const room = chatRoomArr.find(
  //   item =>
  //     (item?.userId1?._id == userId1 && item?.userId2?._id == userId2) ||
  //     (item?.userId1?._id == userId2 && item?.userId2?._id == userId1),
  // );
  const room = chatRoomArr.find(
    (item) => item.user.hasOwnProperty(userId1) && item.user.hasOwnProperty(userId2)
  );

  if (room) {
    console.log('worked from if');
    sendChatMessage(
      room._id,
      userId2,
      chatMessageObject,
      success,
      'read',
      false,
      profileInfo,
      sender
    );
    return;
  } else {
    console.log('worked from else');

    const receiverName = getFullName(sender);

    createChatRoom(
      createRoomObject,
      userId2,
      chatMessageObject,
      success,
      receiverName,
      getMessage(message),
      sender?.image,
      sender,
      profileInfo
    );
  }
}

async function getChatRoom() {
  const classesCollection = await getDocs(chatroomsRef);
  let data = [];
  return new Promise((resolve, reject) => {
    try {
      classesCollection?.forEach((querySnapshot) => {
        const objectquerySnapshot = { ...querySnapshot.data(), _id: querySnapshot.id };
        data?.push(objectquerySnapshot);
      });
      resolve(data);
    } catch (error) {
      reject('Error getChatRoom: ' + error?.message || error);
    }
  });
}
const getSubsctiptionStatus = async (Id) => {
  const customerId = Id; // Replace with the actual customer ID
  let status = '';
  await api.get(`/stripe/getActive/${customerId}`, config).then((res) => {
    status = res.data.status;
  });
  return status;
};

async function createChatRoom(
  createRoomObject,
  receiverId,
  chatMessageObject,
  success,
  senderName,
  message,
  image,
  sender,
  profileInfo
) {
  try {
    addDoc(chatroomsRef, createRoomObject).then(async (res) => {
      const getchat = await getSubsctiptionStatus(profileInfo?.customerId);
      if (getchat !== 'Premium' && getchat !== 'PremiumC') {
        const notificationBody = {
          title: `New message`,
          message:
            'You have received a new message from a trader. Subscribe to view the full conversation',
          userId: receiverId,
          roomId: res.id,
          senderId: sender?._id,
          icon: image,
          data: {
            topic: 'newMessage',
            senderId: sender?._id,
            sender: {
              firstName: sender?.firstName,
              lastName: sender?.lastName,
              _id: sender?._id,
              customerId: sender?.customerId,
              followers: sender?.followers,
            },
            roomId: res.id,
          },
        };
        console.log('notificationBody', notificationBody);
        await api.post(`/user/sendNotification/${receiverId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: notificationBody,
        });
        sendChatMessage(
          res.id,
          receiverId,
          chatMessageObject,
          success,
          'read',
          true,
          profileInfo,
          sender
        );
      } else {
        const notificationBody = {
          title: `New message from ${senderName}`,
          message: message,
          userId: receiverId,
          senderId: sender?._id,
          roomId: res.id,
          icon: image,
          data: {
            topic: 'newMessage',
            senderId: sender?._id,
            sender: {
              firstName: sender?.firstName,
              lastName: sender?.lastName,
              _id: sender?._id,
              customerId: sender?.customerId,
              followers: sender?.followers,
            },
            roomId: res.id,
          },
        };
        console.log('notificationBody', notificationBody);
        await api.post(`/user/sendNotification/${receiverId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: notificationBody,
        });
        sendChatMessage(
          res.id,
          receiverId,
          chatMessageObject,
          success,
          'read',
          true,
          profileInfo,
          sender
        );
      }
    });
  } catch (error) {
    console.log('Error createChatRoom:', error);
  }
}

async function sendChatMessage(
  roomId,
  receiverId,
  chatMessageObject,
  success,
  check,
  isNewChat = false,
  profileInfo = null,
  sender = null
) {
  try {
    const docRef = doc(db, 'chatrooms', roomId);
    const colRef = collection(docRef, 'messages');
    console.log('Chat is', localStorage?.getItem('customerId'));

    const getchat = await getSubsctiptionStatus(localStorage?.getItem('customerId'));
    console.log('getChat', getchat);
    if (getchat !== 'Premium' && getchat !== 'PremiumC') {
      const notificationBody = {
        title: `New message`,
        userId: receiverId,
        roomId: roomId,
        message:
          'You have received a new message from a trader. Subscribe to view the full conversation',
        icon: chatMessageObject?.user?.image,
        senderId: chatMessageObject?.user?._id,
        data: {
          topic: 'newMessagePush',
          senderId: chatMessageObject?.user?._id,
          sender: {
            firstName: sender?.firstName,
            lastName: sender?.lastName,
            _id: sender?._id,
            customerId: sender?.customerId,
            followers: sender?.followers,
          },
          roomId: roomId,
        },
      };
      console.log('notificationBody', notificationBody);

      addDoc(colRef, chatMessageObject).then(async (res) => {
        if (!isNewChat) {
          await api.post(`/user/sendPushNotification/${receiverId}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: notificationBody,
          });
        }

        updateLatestMessageinRoom(roomId, receiverId, chatMessageObject, success, check);
      });
    } else {
      const notificationBody = {
        title: `New message from ${getFullName(chatMessageObject?.user)}`,
        userId: receiverId,
        roomId: roomId,
        message: chatMessageObject?.message,
        icon: chatMessageObject?.user?.image,
        senderId: chatMessageObject?.user?._id,
        data: {
          topic: 'newMessagePush',
          senderId: chatMessageObject?.user?._id,
          sender: {
            firstName: sender?.firstName,
            lastName: sender?.lastName,
            _id: sender?._id,
            customerId: sender?.customerId,
            followers: sender?.followers,
          },
          roomId: roomId,
        },
      };
      console.log('notificationBody', notificationBody);

      addDoc(colRef, chatMessageObject).then(async (res) => {
        if (!isNewChat) {
          await api.post(`/user/sendPushNotification/${receiverId}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: notificationBody,
          });
        }

        updateLatestMessageinRoom(roomId, receiverId, chatMessageObject, success, check);
      });
    }
  } catch (error) {
    console.log('Error sendChatMessage:', error);
  }
}

function updateLatestMessageinRoom(roomId, receiverId, chatMessageObject, success, check) {
  try {
    const docRef = doc(db, 'chatrooms', roomId);
    setDoc(docRef, { latestMessage: chatMessageObject }, { merge: true }).then(() => {
      success();
      if (check == 'read') readAllMessageInRoom(roomId, receiverId);
    });
  } catch (error) {
    console.log('Error updateLatestMessageinRoom:', error);
  }
}

async function getAllCurrentUserRooms(userId) {
  const catchFunc = (error) => {
    console.log('Error getAllCurrentUserRooms: ' + error?.message || error);
    // setibl(false);//set loader
  };

  try {
    const q = query(chatroomsRef, where(`user.${userId}`, '==', true));
    let data = [];
    let finalData = [];
    const querySnapshot = await getDocs(q);
    console.log('querySnapshot: =========>', querySnapshot);
    if (querySnapshot.size > 0) {
      querySnapshot.forEach((doc) => {
        console.log(doc, 'doc======>');
        if (doc) {
          data?.push({ ...doc.data(), _id: doc?.id || '' });
        }
      });
    }
    data.forEach((item) => {
      if (item) {
        let c = false;
        const arr = item?.latestMessage?.deletedBy || [];
        arr.forEach((element) => {
          if (element && element == userId) {
            c = true;
          }
        });
        if (!c) {
          finalData.push(item);
        }
      }
    });
    finalData.sort((a, b) => b?.latestMessage?.updatedAt - a?.latestMessage?.updatedAt);
    return finalData;
  } catch (error) {
    catchFunc(error);
  }
}

function updateLatestMessageObjectinRoom(roomId, currentUserId, deleteSuccess, type) {
  const catchFunc = (error) => {
    console.log('Error updateLatestMessageObjectinRoom:' + error?.message || error);
  };

  const obj =
    type == 'deletedBy'
      ? {
          'latestMessage.deletedBy': arrayUnion(currentUserId),
        }
      : {
          'latestMessage.isRead': true,
        };

  try {
    const docRef = doc(db, 'chatrooms', roomId);
    updateDoc(docRef, obj, { merge: true })
      .then(() => {
        deleteSuccess();
      })
      .catch((error) => {
        catchFunc(error);
      });
  } catch (error) {
    catchFunc(error);
  }
}

function readAllMessageInRoom(roomId, receiverId) {
  const catchFunc = (error) => {
    console.log('Error readAllMessageInRoom: ' + error?.message || error);
  };

  const messagesRef = query(
    collection(chatroomsRef, roomId, 'messages'),
    where('user._id', '==', receiverId)
    // where('isRead', '==', false)
  );

  const lastMessagesQuery = query(
    collection(chatroomsRef, roomId, 'messages'),
    orderBy('createdAt', 'desc'),
    limit(1)
  );

  try {
    getDocs(messagesRef)
      .then(async (querySnapshot) => {
        if (querySnapshot.size > 0) {
          querySnapshot.docs.forEach((data) => {
            const obj = {
              isRead: true,
            };
            updateDoc(data.ref, obj, { merge: true });
          });

          const lastMessage = await getDocs(lastMessagesQuery);

          if (lastMessage.docs.length > 0) {
            const msg = lastMessage.docs[0].data();
            if (msg.user._id === receiverId)
              updateLatestMessageObjectinRoom(roomId, '', () => {}, 'isRead');
          }
        }
      })
      .catch((error) => {
        catchFunc(error);
      });
  } catch (error) {
    catchFunc(error);
  }
}

function deleteChatRoom(roomId, deleteSuccess) {
  const catchFunc = (error) => {
    console.log('Error deleteChatRoom: ' + error?.message || error);
  };

  try {
    // const messagesRef = chatroomsRef.doc(roomId).collection('messages');
    const docRef = doc(db, 'chatrooms', roomId);
    const messagesRef = collection(docRef, 'messages');

    deleteDoc(docRef)
      .then(() => {
        getDocs(messagesRef).then((querySnapshot) => {
          Promise.all(querySnapshot.docs.map((d) => deleteDoc(d.ref)));
        });

        deleteSuccess();
      })
      .catch((error) => {
        catchFunc(error);
      });
  } catch (error) {
    catchFunc(error);
  }
}

function deleteChat(inbox, roomId, currentUserId, search, searrchData, setSearchData, closeSwipe) {
  const catchFunc = (error) => {
    console.log('Error deleteChat: ' + error?.message || error);
  };

  const deleteSuccess = () => {
    let i = 0;
    let ii = 0;
    const dd = [...inbox];
    if (dd.length > 0) {
      const ind = dd.findIndex((x) => x._id === roomId);
      if (ind > -1) {
        i = ind;
      }
    }
    let ddd = [...searrchData];
    if (ddd.length > 0) {
      const ind = ddd.findIndex((x) => x._id === roomId);
      if (ind > -1) {
        ii = ind;
      }
    }

    // closeSwipe();
    dd.splice(i, 1);
    // setinbox(dd);
    if (search != '') {
      ddd.splice(ii, 1);
      setSearchData(ddd);
    }
  };

  // const messagesRef = doc(db, "chatrooms", roomId);
  const docRef = doc(db, 'chatrooms', roomId);
  const messagesRef = collection(docRef, 'messages');

  // const messagesRef = chatroomsRef.doc(roomId).collection('messages');

  try {
    // messagesRef
    // .get()
    getDocs(messagesRef)
      .then((querySnapshot) => {
        const sortArr = querySnapshot.docs.sort((a, b) => a.data().createdAt - b.data().createdAt);
        sortArr.forEach((data, index, arr) => {
          const obj = { deletedBy: arrayUnion(currentUserId) };

          updateDoc(data.ref, obj);

          if (index == arr.length - 1) {
            if (data.data().deletedBy.length < 1)
              updateLatestMessageObjectinRoom(roomId, currentUserId, deleteSuccess, 'deletedBy');
            else deleteChatRoom(roomId, deleteSuccess);
          }
        });
      })
      .catch((error) => {
        catchFunc(error);
      });
  } catch (error) {
    catchFunc(error);
  }
}

function getAllMessageInRoom(userId, roomId, receiverId, setGetdata, setData) {
  const catchFunc = (error) => {
    console.log('Error getAllMessageInRoom: ' + error?.message || error);
  };

  const curentUserId = userId;

  const docRef = doc(db, 'chatrooms', roomId);
  const messagesRef = collection(docRef, 'messages');

  try {
    getDocs(messagesRef)
      .then((querySnapshot) => {
        let messagesArr = [];
        if (querySnapshot.size > 0) {
          messagesArr = querySnapshot.docs
            .map((data) => ({
              ...data.data(),
              _id: data.id,
            }))
            .sort((a, b) => a.createdAt - b.createdAt)
            .filter((item) => {
              let isShow = true;
              item.deletedBy.forEach((element) => {
                if (element == curentUserId) {
                  isShow = false;
                }
              });
              if (isShow) return item;
            });
        }

        setGetdata(true);
        setData(messagesArr);
        readAllMessageInRoom(roomId, receiverId);
      })
      .catch((error) => {
        catchFunc(error);
      });
  } catch (error) {
    catchFunc(error);
  }
}

async function updateUserinFirestore(currentUserId, userObj) {
  const catchFunc = (error) => {
    console.log('Error updateUserinFirestore: ' + error?.message || error);
  };
  const q = query(chatroomsRef, where(`user.${currentUserId}`, '==', true));

  try {
    await getDocs(q)
      .then((querySnapshot) => {
        if (querySnapshot.size > 0) {
          querySnapshot.docs.forEach((doc) => {
            const roomId = doc.id;
            const data = doc.data();
            let obj = {};
            if (data.latestMessage.user._id == currentUserId) {
              obj = { 'latestMessage.user': userObj };
            }
            if (data.userId1._id == currentUserId) {
              obj.userId1 = userObj;
            } else {
              obj.userId2 = userObj;
            }
            updateDoc(doc.ref, obj, { merge: true });

            const ref2 = query(
              collection(chatroomsRef, roomId, 'messages'),
              where('user._id', '==', currentUserId)
            );

            getDocs(ref2).then((querySnapshot2) => {
              if (querySnapshot2.size > 0) {
                querySnapshot2.docs.forEach((doc2) => {
                  const obj = { user: userObj };
                  updateDoc(doc2.ref, obj, { merge: true });
                });
              }
            });
          });
        }
      })
      .catch((error) => {
        catchFunc(error);
      });
  } catch (error) {
    catchFunc(error);
  }
}

async function updateUserinFirestoreOnlyRoom(currentUserId, userObj) {
  const catchFunc = (error) => {
    console.log('Error updateUserinFirestoreOnlyRoom: ' + error?.message || error);
  };

  const q = query(chatroomsRef, where(`user.${currentUserId}`, '==', true));

  try {
    await getDocs(q)
      .then((querySnapshot) => {
        if (querySnapshot.size > 0) {
          querySnapshot.docs.forEach((doc) => {
            const data = doc.data();
            let obj = null;
            if (data?.userId1?._id == currentUserId) {
              obj = { userId1: userObj };
            } else {
              obj = { userId2: userObj };
            }
            updateDoc(doc.ref, obj, { merge: true });
          });
        }
      })
      .catch((error) => {
        catchFunc(error);
      });
  } catch (error) {
    catchFunc(error);
  }
}
export const FireStore = {
  updateUserinFirestore,
  updateUserinFirestoreOnlyRoom,
  sendMessage,
  getAllCurrentUserRooms,
  getAllMessageInRoom,
  sendChatMessage,
  deleteChat,
  readAllMessageInRoom,
};
