import React from 'react';
import { Avatar, Badge, Box } from '@mui/material';
import { getFullName } from '../../utills/constants';
import star from '../../assets/home/star.png';
import verified from '../../assets/profile/verified.png';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { NameSection } from '../../pages/Elements';
import { RatingBox, RatingCount, ReviewCount } from '../../pages/Search/Elements';

const UserInfoWidget = ({ user, handleChange }) => {
  const { userInfo, isAuth } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleUserNavigation = () => {
    if (!isAuth) {
      handleChange('restrictionModel', true);
      return;
    }

    if (userInfo?._id === user?._id) {
      navigate(`/profile/${user?._id}`);
    } else {
      navigate(`/profile/public/${user?._id}`);
    }
  };
  return (
    <span
      style={{ display: 'flex', cursor: 'pointer', width: 'fit-content' }}
      onClick={handleUserNavigation}
    >
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
          user?.identityStatus === 'verified' && (
            <img alt="Remy Sharp" src={verified} style={{ width: '20px', height: '20px' }} />
          )
        }
      >
        <Avatar src={user?.image} sx={{ width: 40, height: 40 }} />
      </Badge>
      <NameSection>
        <span>{getFullName(user)}</span>
        <RatingBox>
          <img className="imageShadow" src={star} alt="star" />
          <RatingCount> {user?.rating > 0 ? user?.rating : ''} </RatingCount>
          <ReviewCount> {user?.reviews} reviews</ReviewCount>
        </RatingBox>
      </NameSection>
    </span>
  );
};

export default UserInfoWidget;
