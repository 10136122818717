import React, { useState, useEffect } from 'react';

import * as yup from 'yup';
import moment from 'moment';
import api from '../../api';
import Loader from '../../assets/loader1.gif';
import toast from 'react-hot-toast';

import { Text } from './Elements';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { LoaderSpan } from '../SignIn/Elements';
import { config, subtractPercentage } from '../../utills/constants';
import { BrowseFiles, UserName } from '../Elements';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { updateAccount } from '../../features/auth/action';
import { Box, Button, Divider, FormHelperText, Grid } from '@mui/material';
import { CustomGreenLargeButton } from '../../components/Button';
import { CustomLabel2 } from '../../components/InputFields/Elements';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Details, Light, Dark, DarkWithLine } from '../Join/Components/Elements';
import { Input, PromoInput, CustomCheckboxWithSmallLabel } from '../../components/InputFields';

import PaymentSuccess from '../../components/Modal/PaymentSuccessfull';

import './style.css';
import './stripe.css';
import { stopLoading } from '../../features/auth/reducer';
import PaymentCard from '../../components/cards/PaymentCard';
import { getTheAddedCards } from '../../features/stripe/action';

// const schema = yup.object().shape({
//   fullName: yup.string().required('Please enter your full name.'),
//   terms: yup.boolean().oneOf([true], 'Agreement to Terms and Conditions is required.'),
// });
const getValidationSchema = (isManualEntry) =>
  yup.object().shape({
    fullName: yup.string().when([], {
      is: () => isManualEntry,
      then: yup.string().required('Please enter your full name.'),
      otherwise: yup.string().nullable(),
    }),
    terms: yup.boolean().oneOf([true], 'Agreement to Terms and Conditions is required.'),
  });

const Step2 = ({ plan, clientSecret, subscriptionId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const date = new Date();
  const [state, setState] = useState({});
  const { loading, userInfo } = useSelector((state) => state.auth);
  const { plans } = useSelector((state) => state.general);
  console.log(userInfo?.subscriptions?.title, plans);
  const [code, setcode] = useState('');
  const [isPromo, setIsPromo] = useState(false);
  const [promoDisc, setpromoDisc] = useState('');
  const [codevalid, setcodevalid] = useState(null);
  const [promoDiscAmt, setpromoDiscAmt] = useState(0);
  const [customerId, setCustomerId] = useState('');
  const { freemiumRecieveOfferTrip } = useSelector((state) => state.trip);
  const freemiumMakeOffer = useSelector((state) => state.trip);
  const [promoId, setPromoId] = useState('');

  // Card Selection States
  const StripeData = useSelector((state) => state.stripe?.stripeAddedCard);
  const [selectedCard, setSelectedCard] = useState({});
  const [isManualEntry, setIsManualEntry] = useState(true);
  const [primaryCard, setPrimaryCard] = useState({});

  // STRIPE CODE
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  // const [clientSecret, setClientSecret] = useState(clientSecret);
  const stripe = useStripe();
  const elements = useElements();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(getValidationSchema(isManualEntry)) });

  const handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const fetchPrimaryCard = async () => {
    try {
      const {
        data: { subscriptions = {} },
      } = await api.get(`/stripe/subscriptions/${userInfo?.customerId}`);
      const { default_payment_method: primaryCard = {} } = subscriptions?.data?.find(
        (el) => el?.default_payment_method?.id
      );

      setPrimaryCard(primaryCard);
    } catch (error) {
      console.error('error: ', error);
    }
  };

  // const fetchPrimaryCard = async () => {
  //   const resp = await api.get(`/stripe/subscriptions/${userInfo?.customerId}`);
  //   const result = resp?.data?.subscriptions?.data;
  //   // console.log('Respponse of the primary key', resp?.data?.subscriptions?.data);

  //   let data = null;
  //   if (result.length > 0) {
  //     const filterData = result.filter(
  //       (val) =>
  //         (val?.status === 'active' || val?.status === 'canceled') && val?.default_payment_method
  //     );

  //     for (let index = 0; index < result.length; index++) {
  //       const element = result[index];
  //       data = element;
  //       if (filterData.length > 0) {
  //         if (element.status === 'active' && data?.default_payment_method) {
  //           break;
  //         }

  //         if (element.status === 'canceled' && data?.default_payment_method) {
  //           data = element;
  //           break;
  //         }
  //       } else {
  //         break;
  //       }
  //     }
  //   }
  //   resp?.data?.subscriptions?.data && setPrimaryCard(data);
  //   console.log('data: -============> ', data);
  // };

  const handlePyamentType = () => {
    setIsManualEntry(!isManualEntry);
    reset();
  };

  const checkDefaultSelected = () => {
    console.log('primaryCard: =======> ', primaryCard);
    if (primaryCard?.id) {
      const isCard = StripeData.find((el) => el?.id === primaryCard?.id);
      setSelectedCard(isCard);
      setIsManualEntry(false);
      setDisabled(false);
    }
  };

  useEffect(() => {
    checkDefaultSelected();
  }, [primaryCard, StripeData]);

  useEffect(() => {
    fetchPrimaryCard();
    dispatch(getTheAddedCards(userInfo?.customerId));
  }, []);

  const submitFunction = async () => {
    if (userInfo?.customerId) {
      let body = {
        subscriptionStatus: 'paid',
        subscription: {
          promoCode: code,
          status: 'active',
          title: state.plan,
          amtPaid: state.total,
          startDate: new Date(),
          charges: state.monthly,
          discount: state.discount,
          promoCodeDiscount: promoDisc,
          promoCodeDiscountAmt: promoDiscAmt,
          endDate: state.plan === 'annual' ? moment().add(12, 'month') : moment().add(1, 'month'),
        },
      };
      if (Object.keys(freemiumRecieveOfferTrip).length !== 0) {
        // navigate(-1)
        dispatch(updateAccount(body, navigate('/tradeOffer'), 'otherPage'));
      } else if (Object.keys(freemiumMakeOffer?.freemiumMakeOfferTrip).length !== 0) {
        dispatch(updateAccount(body, navigate('/search'), 'otherPage'));
      }
      dispatch(updateAccount(body, handleChange('open', true), 'otherPage'));

      // dispatch(updateAccount(body, handleChange('open', true), 'otherPage'));
    } else {
      let body = {
        // customerId: customerId,
        subscriptionStatus: 'paid',
        subscription: {
          promoCode: code,
          status: 'active',
          title: state.plan,
          amtPaid: state.total,
          startDate: new Date(),
          charges: state.monthly,
          discount: state.discount,
          promoCodeDiscount: promoDisc,
          promoCodeDiscountAmt: promoDiscAmt,
          endDate: state.plan === 'annual' ? moment().add(12, 'month') : moment().add(1, 'month'),
        },
      };
      if (Object.keys(freemiumRecieveOfferTrip).length !== 0) {
        // navigate(-1)
        dispatch(updateAccount(body, navigate('/tradeOffer'), 'otherPage'));
      } else if (Object.keys(freemiumMakeOffer?.freemiumMakeOfferTrip).length !== 0) {
        dispatch(updateAccount(body, navigate('/search'), 'otherPage'));
      }
      dispatch(updateAccount(body, handleChange('open', true), 'otherPage'));
    }
  };

  const handlePromoInput = (e) => {
    setError(null);
    setcode(e.target.value);
    setcodevalid(null);
  };
  const handleRemove = () => {
    setState({
      ...state,
      selectedTotal: state?.selectedTotal,
      total: state?.selectedTotal,
    });
    handleChange();
    setcode('');
    setpromoDisc(0);
    setpromoDiscAmt(0);
    setcodevalid(null);
    setPromoId('');
  };

  async function fetchData(value) {
    await api
      .post('/stripe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: userInfo?.customerId
          ? JSON.stringify({
              customerId: userInfo?.customerId,
              uemail: userInfo?.email,
              description: state?.plan,
              amount: value.toFixed(2) * 100,
            })
          : JSON.stringify({
              email: userInfo?.email,
              description: state?.plan,
              amount: value.toFixed(2) * 100,
            }),
      })
      .then((data) => {
        setCustomerId(data?.data?.customerId);

        // setClientSecret(data.data.clientSecret);
      });
  }

  async function sendPaymentFailedNotification() {
    const notificationBody = {
      title: `Your subscription payment failed`,
      userId: userInfo?._id,
      message: `Please review your payment details. You can update your payment method under Settings`,
      icon: 'https://triptrader-assets.s3.amazonaws.com/dispute-1677063279066.png',
      data: { topic: 'paymentFailed' },
    };
    await api.post(`/user/sendNotification/${userInfo?._id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: notificationBody,
    });
  }

  const getPlan = () => {
    const results = plans?.filter((obj) => obj.type === plan)[0];
    const charges =
      results.charges * (results.type === 'annual' ? 12 : 1) -
      (results.type === 'annual' ? 0.01 : 0);
    handleChange('plan', results.type);
    handleChange(
      'selectedTotal',
      results.charges * (results.type === 'annual' ? 12 : 1) -
        (results.type === 'annual' ? 0.01 : 0)
    );
    handleChange(
      'total',
      results.charges * (results.type === 'annual' ? 12 : 1) -
        (results.type === 'annual' ? 0.01 : 0)
    );
    handleChange('monthly', results.charges);
    handleChange('discount', results.discount);
    // fetchData(charges);
  };
  useEffect(() => {
    getPlan();
    dispatch(stopLoading());
    // eslint-disable-next-line
  }, []);
  const cardStyle = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#32325d',
        },
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  const handleChangeKey = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  const handleSubmitStripe = async (ev) => {
    setProcessing(true);
    const results = plans?.filter((obj) => obj.type === state.plan)[0];
    const body = {
      customerId: userInfo?.customerId,
      priceId: results?.stripeId,
      promoCode: promoId,
    };

    await api
      .post(`/stripe/create-subscription`, body, config)
      .then(async (data) => {
        // const payload = await stripe.confirmCardPayment(clientSecret, {

        if (data?.data?.clientSecret !== null) {
          let values = {};

          if (selectedCard?.id && !isManualEntry) {
            values = {
              payment_method: selectedCard?.id,
            };
          } else {
            values = {
              payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                  name: ev?.fullName,
                  email: userInfo?.email,
                },
              },
              setup_future_usage: 'off_session',
            };
            const cardElement = elements.getElement(CardElement);
            const { error, token } = await stripe.createToken(cardElement);
            const card = token?.card
            if (error) {
              toast.error(error?.response ? error?.response?.message : 'Try Again!', { duration: 4000 });
              setProcessing(false);
              return;
            }
      
            const isDuplicate = StripeData.some(
              (item) =>
                item?.card?.last4 === card?.last4 &&
                item?.card?.exp_month === card?.exp_month &&
                item?.card?.exp_year === card?.exp_year
            );
      
            if(isDuplicate){
              toast.error("This card already exists.", { duration: 4000 });
              setProcessing(false);
              return
            }
          }

          const payload = await stripe.confirmCardPayment(data?.data?.clientSecret, values);

          const body = {
            // subscriptionId: subscriptionId, // subscribed key in the primaryCard
            subscriptionId: data?.data?.subscriptionId,
            newPaymentMethod: payload?.paymentIntent?.payment_method, //card Id
          };
          await api.post('/cardmangment/UpdateSubcribedCardInformation', body);

          if (payload.error) {
            setcode('');
            setPromoId('');
            setError(`Payment failed ${payload.error.message}`);
            sendPaymentFailedNotification();
            setProcessing(false);
          } else {
            setError(null);
            setProcessing(false);
            setSucceeded(true);
            submitFunction();
          }
        }
      })
      .catch((err) => {
        console.log('err', err);
        if (err.response) {
          setcode('');
          setPromoId('');
          setError(`Payment failed ${err.response.data.error.message}`);
          sendPaymentFailedNotification();
          setProcessing(false);
        }
      });
  };

  const handlePromoCode = () => {
    setError(null);

    api
      .get(`/stripe/getCodeDetails/${code}`, config)
      .then((data) => {
        if (data?.data?.coupon?.valid == true) {
          setpromoDisc(data?.data?.coupon?.percent_off);
          setpromoDiscAmt(data?.data?.coupon?.percent_off);
          setPromoId(data?.data?.coupon?.id);
          setcodevalid(true);
          const finalAmount = subtractPercentage(state?.total, data?.data?.coupon?.percent_off);
          handleChange('total', finalAmount);
        } else {
          setcodevalid(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          setError(`Payment failed ${err.response.data.error.message}`);
          setcodevalid(false);
        }
      });
  };

  return (
    <>
      {userInfo?.subscription?.title === state?.plan && userInfo?.subscrption?.endDate >= date ? (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                fontFamily: 'Poppins-SemiBold',
                fontSize: '2rem',
                color: '#30563A',
                textAlign: 'center',
              }}
            >
              You have already subscribed for this {state?.plan} plan
            </p>
            <Link
              style={{
                fontFamily: 'Poppins-SemiBold',
                fontSize: '10px',
                color: '#8d8d8d',
                textDecoration: 'none',
              }}
              to="/settings"
            >
              Cancel and go back
            </Link>
          </div>
        </>
      ) : (
        <form onSubmit={handleSubmit(handleSubmitStripe)} className="submitForm">
          <UserName sx={{ fontSize: '18px', mx: 2, mt: 1, mb: 1.5 }}>
            {'Payment Information'}
          </UserName>
          <Grid sx={{ ml: 2 }}>
            <Text>Your subscription will start after you make your first payment below.</Text>
          </Grid>
          <Divider sx={{ m: 2 }} />
          <Grid sx={{ mx: 2, my: 1 }}>
            <Details>
              <Light>Total Due Now:&nbsp; </Light>
              {promoDisc > 0 ? (
                <>
                  <Dark>
                    <s>
                      {state?.plan === 'annual'
                        ? `$${state?.selectedTotal}`
                        : `$${state?.monthly} /mo`}
                    </s>
                  </Dark>
                  &nbsp;&nbsp;
                  <DarkWithLine>
                    <span style={{ color: '#1E3625 !important' }}>
                      {`$${Number(state?.total).toFixed(2)} (${promoDisc} % discount)`}
                    </span>
                  </DarkWithLine>
                </>
              ) : (
                <Dark>
                  {state?.plan === 'annual'
                    ? `$${state?.selectedTotal} ( $${state?.monthly} /mo )`
                    : `$${state?.monthly} /mo`}
                </Dark>
              )}
            </Details>
            <Details>
              <Light>Your Plan:&nbsp; </Light>
              <Dark> {state?.plan === 'annual' ? 'Annual' : 'Monthly'}</Dark>
              &nbsp;&nbsp; <BrowseFiles onClick={() => navigate(-1)}>Change</BrowseFiles>
            </Details>
          </Grid>
          <Grid sx={{ mx: 2, my: 1 }}>
            {isManualEntry && (
              <Input
                title="Full Name"
                placeholder="Card holder’s first and last name"
                error={errors.fullName?.message}
                register={register}
                registerFor="fullName"
              />
            )}
          </Grid>
          <Grid sx={{ mx: 2, my: 1 }}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <CustomLabel2>Card</CustomLabel2>
              {selectedCard?.id && (
                <Button type="button" size="small" onClick={handlePyamentType}>
                  {isManualEntry ? 'Select Card' : 'Enter Manually'}
                </Button>
              )}
            </Box>
            {isManualEntry ? (
              <CardElement id="card-element" options={cardStyle} onChange={handleChangeKey} />
            ) : (
              <PaymentCard
                primaryCard={primaryCard}
                card={selectedCard}
                setSelectedCard={setSelectedCard}
                StripeData={StripeData}
              />
            )}

            {error && (
              <FormHelperText
                sx={{
                  fontFamily: 'Poppins-Medium',
                  color: '#d32f2f',
                  marginTop: 0,
                }}
              >
                {error}
              </FormHelperText>
            )}
          </Grid>
          <Grid sx={{ m: 2 }}>
            {!isPromo ? (
              <BrowseFiles onClick={() => setIsPromo(true)}>I have a promo code</BrowseFiles>
            ) : (
              <PromoInput
                handleRemove={handleRemove}
                handlePromoInput={handlePromoInput}
                codevalid={codevalid}
                code={code}
                isPromo={isPromo}
                error={codevalid}
                setcode={setcode}
                title="Promo Code"
                handlePromoCode={handlePromoCode}
                placeholder="Add a promo code"
              />
            )}
          </Grid>
          <Grid sx={{ mx: 2, my: 1 }}>
            <CustomCheckboxWithSmallLabel
              register={register}
              registerFor="terms"
              error={errors.terms?.message}
              title={
                <>
                  I agree to Trip Trader’s <a href="/#/termsandconditions">Terms and Conditions</a>{' '}
                  and understand that upon clicking “Subscribe” below, I will be charged $
                  {state?.total?.toFixed(2)} {state.plan === 'annual' ? 'annually' : 'monthly'}.
                </>
              }
            />
          </Grid>
          <Grid sx={{ mx: 2, my: 1 }}>
            <CustomGreenLargeButton
              type="submit"
              variant="contained"
              disabled={processing || disabled || succeeded}
              disableripple
            >
              {processing ? (
                <LoaderSpan>
                  <img src={Loader} width={30} alt="loading..." />
                  Processing payment...
                </LoaderSpan>
              ) : (
                'Subscribe'
              )}
            </CustomGreenLargeButton>
          </Grid>
        </form>
      )}
      <PaymentSuccess open={state.open} />
    </>
  );
};

export default Step2;
