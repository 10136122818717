import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../../api';
import { FireStore } from '../../../utills/FireStore';
import toast from 'react-hot-toast';
import { Box } from '@mui/material';
import { config } from '../../../utills/constants';
import { startLoading, stopLoading } from '../../../features/auth/reducer';

const DetailStatus = ({ detailStatus, onSuccess, hostId }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const handleUnblock = async () => {
    dispatch(startLoading());
    let body = {
      name: 'blocked',
      url: `/user/unBlockAUser/${userInfo?._id}/${hostId}`,
    };
    try {
      const response = await api.put(body.url, body, config);
      FireStore.updateUserinFirestoreOnlyRoom(userInfo?._id, response?.data?.data);
      onSuccess();
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message, { duration: 4000 });
      }
    } finally {
      dispatch(stopLoading());
    }
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          width: '100%',
          textAlign: 'center',
        }}
      >
        {detailStatus === 'User is Blocked' ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 0.5,
              flexWrap: 'wrap',
              textAlign: 'center',
            }}
          >
            <span
              style={{
                fontFamily: 'Poppins-Light',
                fontSize: 14,
              }}
            >
              Sorry, you can't view the trip because you have blocked this user
            </span>
            <span
              onClick={handleUnblock}
              style={{
                fontFamily: 'Poppins-Medium',
                cursor: 'pointer',
                color: '#3C6B49',
                textDecoration: 'underline',
                fontSize: 12,
              }}
            >
              Unblock
            </span>
          </Box>
        ) : (
          <span
            style={{
              fontFamily: 'Poppins-Light',
              fontSize: 14,
            }}
          >
            {detailStatus}
          </span>
        )}
      </Box>
    </>
  );
};

export default DetailStatus;
