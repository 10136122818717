import React, { useEffect, useState } from 'react';
import { Avatar, Box, Checkbox, Grid, Modal } from '@mui/material';
import { Header } from './ModalHeader';
import { Description, RedButton, style, DisputeDescription, GreenButton } from './Elements';
import { LoaderSpan } from '../../pages/SignIn/Elements';
import { CancelButton } from '../Button';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import api from '../../api';
import Loader from '../../assets/loader1.gif';
import NoData from '../../pages/Profile/ReviewComponent/NoData';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import { TransparentBtn } from '../../pages/Elements';

const OpenLibrary = ({ statePhotos, handleChange = () => {} }) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo, isAuth } = useSelector((state) => state.auth);
  const [photos, setPhotos] = useState([]);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [params, setParams] = useState({
    limit: 99999999999,
    userId: userInfo?._id,
  });

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    if (!isAuth) {
      handleChange('restrictionModel', true);
      return;
    }
    setShow(true);
    setSelectedPhotos(statePhotos);
  };

  const handleError = (error) => {
    if (error?.response?.data?.message === 'No records found') {
      setPhotos([]);
    }
  };

  const getPhotos = async (params) => {
    setIsLoading(true);
    try {
      const response = await api.get(`usergallery`, { params });
      if (response?.data?.data) {
        setPhotos(response.data.data || []);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckBox = (photoUrl) => {
    setSelectedPhotos((prev) => {
      if (prev.includes(photoUrl)) {
        // If photo is already selected, remove it
        return prev.filter((url) => url !== photoUrl);
      } else {
        // If photo is not selected, add it
        return [...prev, photoUrl];
      }
    });
  };

  const handleOnSubmit = () => {
    handleChange('photos', selectedPhotos); // Update the main state with selected photos
    setShow(false);
  };

  useEffect(() => {
    getPhotos(params);
  }, [params]);

  return (
    <>
      <TransparentBtn onClick={handleShow}>
        <PhotoLibraryIcon />
        Open Library
      </TransparentBtn>
      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            alignItems: 'center',
            width: 800,
            height: 500,
            px: 3,
            pb: 3,
            pt: 2,
          }}
        >
          <Box
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}
          >
            <Header heading={'My Photos'} handleClose={handleClose} />
            <DisputeDescription sx={{ textAlign: 'center' }}>
              Select Photos for the Trip from "My Photos"
            </DisputeDescription>

            <Grid container sx={{ maxHeight: 300, overflow: 'auto' }}>
              {isLoading ? (
                <Grid
                  item
                  xs="12"
                  sx={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <LoaderSpan>
                    <img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} />
                    <span style={{ fontFamily: 'Poppins-SemiBold', fontSize: '13px' }}>
                      Loading...
                    </span>
                  </LoaderSpan>
                </Grid>
              ) : photos?.length > 0 ? (
                photos?.map((res, index) => (
                  <Grid
                    item
                    xs={6}
                    sm={3}
                    key={index}
                    sx={{
                      pr: 1,
                      pb: 1,
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: 100,
                        background: '#000',
                        position: 'relative',
                        display: 'inline-block',
                        borderRadius: 5,
                      }}
                    >
                      <img
                        src={res?.fileId}
                        alt="images"
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain',
                        }}
                      />
                      <div className="close">
                        <Checkbox
                          size="small"
                          checked={selectedPhotos.includes(res?.fileId)} // controlled state
                          onChange={() => handleCheckBox(res?.fileId)} // toggle photo selection
                        />
                      </div>
                    </div>
                  </Grid>
                ))
              ) : (
                <Grid
                  item
                  xs="12"
                  sx={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <NoData text={'No photos available.'} />
                </Grid>
              )}
            </Grid>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'baseline',
              justifyContent: 'flex-end',
              gap: 0.5,
              pt: 0.5,
            }}
          >
            <GreenButton
              onClick={handleOnSubmit}
              sx={{ width: '100%', whiteSpace: 'nowrap' }}
              variant="contained"
              disableripple
              disabled={!photos?.length}
            >
              Submit
            </GreenButton>
            <CancelButton
              onClick={handleClose}
              type="button"
              variant="contained"
              disableripple
              sx={{ width: '100%' }}
            >
              Cancel
            </CancelButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default OpenLibrary;
