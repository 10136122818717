import React, { useEffect, useState } from 'react';

import { UserName } from '../../Elements';
import { CountName, DotsBtn } from '../Elements';
import { LoaderSpan } from '../../SignIn/Elements';
import { FireStore } from '../../../utills/FireStore';
import { FollowerCountRow } from '../Private/Elements';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Avatar, useMediaQuery, Badge } from '@mui/material';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { getFunction } from '../../../features/auth/action';
import { saveData } from '../../../features/profile/reducer';
import { getFullName, config, swalWithStyledButton } from '../../../utills/constants';
import { FreeUserRestriction } from '../../../components/Modal/FreeUser';
import { stopLoading, startLoading } from '../../../features/auth/reducer';
import { Menu, MenuItem, ListItemButton, ListItemIcon } from '@mui/material';
import {
  CoverGrid,
  Count,
  FollowerBox,
  ReviewTabs,
  FollowBtn,
  MessageBtn,
} from '../Private/Elements';
import {
  ReportUser,
  ReportSuccessful,
  BlockSuccessful,
  BlockUser,
  FirstMessage,
  MessageSent,
} from '../../../components/Modal/Message';

import Trips from './Trips';
import Photos from './Photos';
import api from '../../../api';
import Reviews from './Reviews';
import toast from 'react-hot-toast';
import Loader from '../../../assets/loader1.gif';
import star from '../../../assets/profile/star.png';
import dots from '../../../assets/profile/dots.png';
import blockRed from '../../../assets/messaging/block-red.png';
import reportRed from '../../../assets/messaging/report-red.png';
import verified from '../../../assets/profile/verified.png';
import { reload } from '../../../features/auth/action';

import '../style.css';
import PhotoModal from '../../../components/Modal/PhotoModal';
import MyPhotos from '../components/MyPhotos';

const ProfileBox = ({ apiCall, handleDrawerToggle }) => {
  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const matches600 = useMediaQuery('(max-width:600px)');
  const [loading, setloading] = useState(false);
  const [isFollowed, setisFollowed] = useState(false);
  const [selectedTab, setSelectedTab] = useState('one');
  const [isBlockLoading, setIsBlockLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const { userInfo } = useSelector((state) => state.auth);

  const { profileInfo, follower, following } = useSelector((state) => state.profile);
  console.log('profileInfo', profileInfo);

  let location = useLocation();

  const getClassName = (value) => {
    if (value === selectedTab) {
      return 'tab selectedTab';
    } else return 'tab';
  };

  const checkIfExists = async () => {
    const fData = await api.get(`/user/followers/${id}`, config);
    dispatch(saveData({ name: 'follower', data: fData?.data?.follower }));

    const filter =
      fData?.data?.follower?.filter((res) => {
        return res?.userId?._id === userInfo?._id;
      }) || [];
    if (filter.length > 0) {
      setisFollowed(true);
      setloading(false);
    } else {
      setisFollowed(false);
      setloading(false);
    }
  };

  const checkIfBlocked = async () => {
    const updatedBlocked = await api.get(`/user/blockedUsers/${userInfo?._id}`, config);
    updatedBlocked?.data['blocked']?.forEach((res) => {
      if (res?.userId?._id === profileInfo?._id) {
        _handleChange('isBlocked', true);
      }
    });
  };
  useEffect(() => {
    checkIfExists();
    checkIfBlocked();
    if (location?.hash === '#trips') {
      setSelectedTab('two');
    } else {
      setSelectedTab('one');
    }
    // eslint-disable-next-line
  }, []);

  const [state, setState] = useState({
    openPhoto: false,
    openReportUser: false,
    openBlock: false,
    openMessageSent: false,
    openBlockSuccessful: false,
    openReportSuccessful: false,
    freeUser: false,
    openMessage: false,
  });

  const _handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
    handleClose();
  };

  const options = [
    { name: 'Block', handleClick: _handleChange, value: 'openBlock', icon: blockRed },
    { name: 'Report', handleClick: _handleChange, value: 'openReportUser', icon: reportRed },
  ];

  const handleBlockedProfile = async () => {
    const subsStatus = await getSubsctiptionStatus();
    if (subsStatus !== 'Premium' && subsStatus !== 'PremiumC') {
      _handleChange('freeUser', true);
      setloading(false);
      return;
    } else {
      const updatedBlocked = await api.get(`/user/blockedUsers/${userInfo?._id}`, config);
      updatedBlocked?.data['blocked']?.forEach((res) => {
        if (res?.userId?._id === profileInfo?._id) {
          _handleChange('isBlocked', true);
          checkIfExists();
        }
      });
    }
  };
  const getSubsctiptionStatus = async () => {
    const customerId = userInfo?.customerId; // Replace with the actual customer ID
    let status = '';
    await api.get(`/stripe/getActive/${customerId}`, config).then((res) => {
      status = res.data.status;
    });
    return status;
  };

  const openMessageBox = async () => {
    const subsStatus = await getSubsctiptionStatus();
    if (subsStatus !== 'Premium' && subsStatus !== 'PremiumC') {
      _handleChange('freeUser', true);
      return;
    } else {
      _handleChange('selectedUser', profileInfo);
      _handleChange('openMessage', true);
    }
  };

  const followAUser = async () => {
    // const subsStatus = await getSubsctiptionStatus();
    // if (subsStatus !== 'Premium' && subsStatus !== 'PremiumC') {
    //   // _handleChange('freeUser', true);
    //   setloading(false);
    //   return;
    // } else {
      setloading(true);
      const body = { url: `/user/followAUser/${userInfo?._id}/${id}` };
      // dispatch(updateFuntion(body))
      dispatch(startLoading());
      await api
        .put(body.url, body, config)
        .then((res) => {
          if (
            res.data.message == `This user doesn't exist.` ||
            res.data.message == 'This user has blocked you.'
          ) {
            swalWithStyledButton
              .fire({
                icon: 'error',
                allowOutsideClick: false,
                allowEscapeKey: false,
                text: res.data.message,
                confirmButtonText: 'Reload',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
              })
              .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  dispatch(reload(userInfo?._id, navigate));
                } else if (result.isDenied) {
                  dispatch(reload(userInfo?._id, navigate));
                }
              });
          }
          dispatch(stopLoading());
        })
        .catch((err) => {
          if (err.response) {
            toast.error(err.response.data.message, { duration: 4000 });
            dispatch(stopLoading());
          }
        });
      setTimeout(() => {
        checkIfExists();
      }, 1000);
    
  };

  const unFollowAUser = async () => {
    // const subsStatus = await getSubsctiptionStatus();
    // if (subsStatus !== 'Premium' && subsStatus !== 'PremiumC') {
    //   _handleChange('freeUser', true);
    //   return;
    // } else {
      setloading(true);
      const body = { url: `/user/unFollowAUser/${userInfo?._id}/${id}` };
      // dispatch(updateFuntion(body))

      await api
        .put(body.url, body, config)
        .then((res) => {
          if (res.data.message == `This user doesn't exist.`) {
            swalWithStyledButton
              .fire({
                icon: 'error',
                allowOutsideClick: false,
                allowEscapeKey: false,
                text: res.data.message,
                confirmButtonText: 'Reload',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
              })
              .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  dispatch(reload(userInfo?._id, navigate));
                } else if (result.isDenied) {
                  dispatch(reload(userInfo?._id, navigate));
                }
              });
          }
          dispatch(stopLoading());
        })
        .catch((err) => {
          if (err.response) {
            toast.error(err.response.data.message, { duration: 4000 });
            dispatch(stopLoading());
          }
        });
      setTimeout(() => {
        checkIfExists();
      }, 1000);
  };

  const unBlock = async () => {
    const subsStatus = await getSubsctiptionStatus();
    if (subsStatus !== 'Premium' && subsStatus !== 'PremiumC') {
      _handleChange('freeUser', true);
      setloading(false);
      return;
    } else {
      setIsBlockLoading(true);
      let _body = { name: 'blocked', url: `/user/blockedUsers/${userInfo?._id}` };
      let body = {
        name: 'blocked',
        url: `/user/unBlockAUser/${userInfo?._id}/${profileInfo?._id}`,
      };
      // dispatch(updateFuntion(body))
      await api
        .put(body.url, body, config)
        .then((res) => {
          if (res.data.message == `This user doesn't exist.`) {
            swalWithStyledButton
              .fire({
                icon: 'error',
                allowOutsideClick: false,
                allowEscapeKey: false,
                text: res.data.message,
                confirmButtonText: 'Reload',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
              })
              .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  dispatch(stopLoading());
                  dispatch(reload(userInfo?._id, navigate));
                } else if (result.isDenied) {
                  dispatch(stopLoading());
                  dispatch(reload(userInfo?._id, navigate));
                }
              });
            setTimeout(() => {
              setIsBlockLoading(false);
            }, 1000);
          } else {
            dispatch(getFunction(_body));
            _handleChange('isBlocked', false);
            setTimeout(() => {
              setIsBlockLoading(false);
            }, 1000);
            dispatch(stopLoading());
            FireStore.updateUserinFirestoreOnlyRoom(userInfo?._id, res?.data?.data);
          }
        })
        .catch((err) => {
          if (err.response) {
            toast.error(err.response.data.message, { duration: 4000 });
            dispatch(stopLoading());
          }
        });
    }
  };

  if (state?.isBlocked) {
    swalWithStyledButton
    .fire({
      icon: 'error',
      allowOutsideClick: false,
      allowEscapeKey: false,
      text: "You Have Blocked this User",
      confirmButtonText: 'Reload',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    })
    .then((result) => {
      if (result.isConfirmed) {
        navigate('/search')
      } else if (result.isDenied) {
        navigate('/search')
      }
    });
    return
    // return navigate('/search');
  }

  return (
    <Grid container sx={{ background: 'white', height: 'max-content' }}>
      <CoverGrid item xs={12} sm={12} md={12} lg={12} image={profileInfo?.coverImage} />
      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        lg={4}
        sx={{ mt: -10, display: 'flex', alignItems: 'center', paddingLeft: matches600 ? 2 : 4 }}
      >
        {profileInfo?.identityStatus === 'verified' ? (
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              <img alt="Remy Sharp" src={verified} style={{ width: '30px', height: '30px' }} />
            }
          >
            <Avatar
              src={profileInfo?.image}
              sx={{ width: 100, height: 100, cursor: 'pointer' }}
              onClick={() => _handleChange('openPhoto', true)}
            />
          </Badge>
        ) : (
          <Avatar
            src={profileInfo?.image}
            sx={{ width: 100, height: 100, cursor: 'pointer' }}
            onClick={() => _handleChange('openPhoto', true)}
          />
        )}
      </Grid>
      {!state?.isBlocked && (
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={8}
          sx={{
            mt: 2,
            mb: matches600 ? 2 : 0,
            width: matches600 ? '70%' : '90%',
            display: 'flex',
            justifyContent: 'flex-end',
            pr: 2,
          }}
        >
          <DotsBtn onClick={handleClick} sx={{ mr: 1 }}>
            <img src={dots} alt="dots" />
          </DotsBtn>
          <MessageBtn onClick={openMessageBox} sx={{ mr: 1 }}>
            Message
          </MessageBtn>
          {userInfo?._id !== profileInfo?._id && (
            <>
              {isFollowed ? (
                <button className="btnFollow" disabled={loading}>
                  <FollowBtn onClick={unFollowAUser}>
                    {loading && (
                      <LoaderSpan>
                        <img src={Loader} width={22} alt="loading..." style={{ marginRight: 6 }} />
                      </LoaderSpan>
                    )}{' '}
                    Unfollow
                  </FollowBtn>
                </button>
              ) : (
                <button className="btnFollow" disabled={loading}>
                  <FollowBtn onClick={followAUser}>
                    {' '}
                    {loading && (
                      <LoaderSpan>
                        <img src={Loader} width={22} alt="loading..." style={{ marginRight: 6 }} />
                      </LoaderSpan>
                    )}
                    Follow
                  </FollowBtn>
                </button>
              )}
            </>
          )}
        </Grid>
      )}
      {state?.isBlocked && (
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={8}
          sx={{ pr: 2, mt: 2, width: '90%', display: 'flex', justifyContent: 'flex-end' }}
        >
          <FollowBtn onClick={unBlock}>
            {' '}
            {isBlockLoading && (
              <LoaderSpan>
                <img src={Loader} width={22} alt="loading..." style={{ marginRight: 6 }} />
              </LoaderSpan>
            )}
            Unblock
          </FollowBtn>
        </Grid>
      )}

      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          paddingLeft: matches600 ? 2 : 4,
        }}
      >
        <UserName>
          {getFullName(profileInfo)}
          {profileInfo?.rating > 0 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: 10,
              }}
            >
              <img src={star} className="imageShadow" style={{ marginRight: 2 }} alt={'star'} />
              {profileInfo?.rating}
            </div>
          )}
        </UserName>
        <FollowerCountRow>
          <FollowerBox onClick={handleDrawerToggle}>
            <Count>{follower?.length}</Count>
            <CountName>Followers</CountName>
          </FollowerBox>
          <FollowerBox onClick={handleDrawerToggle}>
            <Count>{following?.length}</Count>
            <CountName>Following</CountName>
          </FollowerBox>
        </FollowerCountRow>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} sx={{ height: '54.4vh', background: 'white' }}>
        <ReviewTabs>
          <span value="one" className={getClassName('one')} onClick={() => setSelectedTab('one')}>
            Reviews
          </span>
          <span value="two" className={getClassName('two')} onClick={() => setSelectedTab('two')}>
            Trips
          </span>
          <span
            value="three"
            className={getClassName('three')}
            onClick={() => setSelectedTab('three')}
          >
            Photos
          </span>
        </ReviewTabs>
        {selectedTab === 'one' && <Reviews apiCall={apiCall} />}
        {selectedTab === 'two' && <Trips />}
        {/* {selectedTab === 'three' && <Photos />} */}
        {selectedTab === 'three' && <MyPhotos/>}
      </Grid>
      <FirstMessage
        data={state}
        open={state.openMessage}
        handle={_handleChange}
        stateName="openMessage"
      />
      <MessageSent
        data={state}
        open={state.openMessageSent}
        handle={_handleChange}
        stateName="openMessageSent"
      />
      <FreeUserRestriction open={state.freeUser} handle={_handleChange} stateName="freeUser" />

      <BlockUser
        handleConversation={handleBlockedProfile}
        data={profileInfo}
        open={state.openBlock}
        handle={_handleChange}
        stateName="openBlock"
      />
      <ReportUser
        data={profileInfo}
        open={state.openReportUser}
        handle={_handleChange}
        stateName="openReportUser"
      />
      <BlockSuccessful
        data={profileInfo}
        open={state.openBlockSuccessful}
        handle={_handleChange}
        stateName="openBlockSuccessful"
      />
      <ReportSuccessful
        data={profileInfo}
        open={state.openReportSuccessful}
        handle={_handleChange}
        stateName="openReportSuccessful"
      />
      <PhotoModal
        data={profileInfo}
        open={state.openPhoto}
        handle={_handleChange}
        stateName="openPhoto"
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
      >
        {options?.map((res, index) => {
          return (
            <MenuItem
              key={index}
              sx={{ my: 1, pl: 1, pr: 4, py: 0, fontSize: '16px' }}
              onClick={() => res?.handleClick(res.value, true)}
            >
              <ListItemButton
                sx={{ color: '#B93B3B', fontFamily: 'Poppins-Medium !important', pr: 4 }}
              >
                <ListItemIcon sx={{ pr: 3 }}>
                  {<img src={res?.icon} alt={res?.name} width="20" />}
                </ListItemIcon>
                {res?.name}
              </ListItemButton>
            </MenuItem>
          );
        })}
      </Menu>
    </Grid>
  );
};
export default ProfileBox;
