import React from "react";
import moment from "moment";

import star from "../../../assets/profile/star.png"
import dispute from "../../../assets/profile/dispute.png"

import { UserName } from "../../Elements"
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Avatar, Badge, Box, Grid } from "@mui/material";
import { Date, ReviewUserDetail, } from "../Private/Elements"
import { getFullName, getUserProfile } from "../../../utills/constants";
import verified from '../../../assets/profile/verified.png';
import { Message, DisputeText, ReplyUserName, Text, Edit, Delete, ReviewBox } from "../Elements"

const PublicReviewBox = ({ handleChange, res }) => {
    let isMyReview = false
    const navigate = useNavigate()
    const { userInfo, role } = useSelector(state => state.auth)

    if (userInfo?._id === res?.guestId?._id) { isMyReview = true }

    const handleModals = (type) => {
        if (type === 'reply') { handleChange('openReviewModal', true); handleChange('replied', res) }
        else if (type === 'dispute') { handleChange('openDisputeModal', true); handleChange('dispute', res) }
        else if (type === 'edit') { handleChange('openEditModal', true); handleChange('edited', res); handleChange('editData', res?.messages[0]) }
        else if (type === 'delete') { handleChange('openDeleteReview', true); handleChange('delete', res) }
    }

    const handleNavigation = (res) => {
        if (role === 'user') {
            if (userInfo?._id === res?._id) {
                navigate(`/profile/${res?._id}`, { replace: true })
                // window.location.reload();
            }
            else navigate(`/profile/public/${res?._id}`, { replace: true })
            // window.location.reload();
        }
        else return;
    }
    return <>
        <ReviewBox>
            <Grid sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <ReviewUserDetail>
                    <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            badgeContent={
                                res?.guestId?.identityStatus === 'verified' && (
                                <img alt="Remy Sharp" src={verified} style={{ width: '20px', height: '20px' }} />
                            )
                            }
                        >
                        <Avatar src={getUserProfile(res?.guestId)} alt={'Photo1'} sx={{ width: 40, height: 40 }} />
                    </Badge>
                    <Box sx={{ pl: 1.5, cursor: 'pointer' }} onClick={() => res?.guestId != null ? handleNavigation(res?.guestId) : {}}>
                        <p>{res?.guestId != null ? getFullName(res?.guestId) : 'Trip Trader User'}</p>
                        <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>Posted on {moment(res?.createdAt).format('MMM DD, yyyy')} {res?.messages[0]?.isEdited && <i>• Edited</i>}</span>
                    </Box>
                </ReviewUserDetail>
                <UserName>
                    <img src={star} className="imageShadow" alt={'star'} style={{ marginBottom: 4, marginRight: 3 }} /> {res?.guestRating}
                </UserName>
            </Grid>
            <Message>{res?.messages[0]?.message}</Message>
            {res?.status === "dispute" && <DisputeText><img src={dispute} style={{ marginRight: 4 }} alt="dispute" />This review is in dispute.</DisputeText>}
            {isMyReview && res?.status === "active" && <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
                {isMyReview && res?.messages?.length < 2 && <Edit onClick={() => handleModals('edit')}>Edit Review</Edit>}
                <Delete onClick={() => handleModals('delete')}>Delete Review</Delete>
            </Grid>}
        </ReviewBox>
        {res?.messages?.length > 1 && res?.status === "active" &&
            <Grid sx={{ display: 'flex', flexDirection: 'column', width: '85%', mx: 'auto', mb: 1, border: '1px solid #E5E5E5', borderRadius: '5px', padding: 2 }}>
                <ReplyUserName>{getFullName(res?.hostId)} &nbsp; </ReplyUserName>
                <Date sx={{ mb: 1 }}>Replied {moment(res?.messages[1]?.createdAt).format('DD MMM, yyyy')} </Date>
                <Text sx={{ textAlign: 'start', wordBreak:"break-word" }}>{res?.messages[1]?.message}</Text>
            </Grid>}

    </>
}
export default PublicReviewBox