import React from 'react';

import { daysdropdownStyles } from './styles';
import { Option } from '../../pages/MakeOffer/Elements';
import { InputAdornment, TextField } from '@mui/material';
import { CustomAutocomplete, CustomLabel } from './Elements';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';

export const Dropdown = ({
  title,
  value,
  placeholder,
  data,
  handleChange,
  handleFor,
  icon,
  label,
}) => {
  let defaultValue = { name: value === undefined ? '' : value };
  const classes = daysdropdownStyles();

  return (
    <>
      <CustomLabel sx={{ fontFamily: 'Poppins-Semibold', fontWeight: '600' }}>{title}</CustomLabel>
      <CustomAutocomplete
        disableClearable
        placeholder={placeholder}
        // defaultValue={defaultValue}
        value={data?.find((el) => el?.name === value) || {name:""}}
        onChange={(e, value) => {
          if (handleFor === 'species') {
            handleChange('species', value?.name);
            handleChange('category', value?.category?.name);
          } else handleChange(handleFor, value?.name);
        }}
        options={data}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option) => {
          return <Option {...props}>{option?.name}</Option>;
        }}
        popupIcon={<KeyboardArrowDownIcon />}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={label}
            className={classes.root}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  {icon ? <img src={icon} alt={label} /> : ''}
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default Dropdown;
