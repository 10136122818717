import { Grid, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import Sidebar from '../Sidebar';

const Layout = ({ children }) => {
  const [show, setShow] = useState(false);
  const md = useMediaQuery("(max-width:991px)");
  const sm = useMediaQuery("(max-width:600px)");

  console.log('show', show);

  return (
    <Grid container>
      <Grid item>
        <Sidebar setShow={setShow} show={show} />
      </Grid>
      <Grid item container xs={12} sx={{ marginLeft: !md ? show ? '290px' : '90px' : !sm && "90px" }}>
        {children}
      </Grid>
    </Grid>
  );
};

export default Layout;
