import React, { useEffect, useState } from 'react';

import moment from 'moment';
import api from '../../api';
import Sidebar from '../../components/Sidebar';
import Cancel from '../../components/Modal/Cancel';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { useNavigate } from 'react-router-dom';
import { config, getDate } from '../../utills/constants';
import { MainBox, UserName } from '../Elements';
import { useMediaQuery, Grid, Divider } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { getFunction, updateFuntion } from '../../features/auth/action';
import Swal from 'sweetalert2';
import {
  Back,
  FormBox,
  PlanName,
  FormBoxA,
  SideHeading,
  GreenButton,
  RedButton,
  Text,
} from './Elements';
import ChangePlan from '../../components/Modal/ChangePlan';
import toast from 'react-hot-toast';
import { BtnRow, PriceRow, Save, Savings } from '../Join/Components/Elements';
import { deleteTheRequest, getPaymentRequest, getPlans } from '../../features/general/action';
import SwitchPlan from '../../components/Modal/SwitchPlan';
import { paymentrequest } from '../../features/general/reducer';

const ManageSubscription = () => {
  const [state, setState] = useState({ open: false });
  const [primaryCard, setPrimaryCard] = useState('');
  const [stateChangePlan, setChangePlan] = useState(false);
  const [openSwitch, setOpenSwitch] = useState(false);
  const handleSwitchModal = () => {
    if (
      userInfo?.subscription?.status === 'Premium' ||
      userInfo?.subscription?.status === 'PremiumC'
    ) {
      setOpenSwitch(true);
    } else {
      navigate('/freePlan');
    }
  };
  const handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleonChangePlan = () => {
    setChangePlan(true);
  };
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const matches600 = useMediaQuery('(max-width:600px)');
  const matches400 = useMediaQuery('(max-width:400px)');

  const { userInfo, isAuth } = useSelector((state) => state.auth);
  const { plans, paymentRequest } = useSelector((state) => state.general);
  const [states, setStates] = useState({
    plan: userInfo?.subscription?.title,
    step: 1,
    total: 0,
    monthly: 0,
  });
  const handlePlanChange = (name, value) => {
    setStates((prevState) => ({ ...prevState, [name]: value }));
  };
  const [data, setData] = useState();
  const [cardData, setCardData] = useState();
  const currentDate = new Date();
  const [open, setOpen] = useState(false);
  const getAll = useSelector((state) => state.general);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = currentDate.toLocaleDateString('en-US', options);
  const getActiveRequest =
    paymentRequest && paymentRequest?.length && paymentRequest?.find((e) => e?.status === 'active');

  console.log(formattedDate);

  const handleopen = () => setOpen(true);
  const checkprimaryCard = async () => {
    const resp = await api.get(`/stripe/subscriptions/${userInfo?.customerId}`);
    const result = resp?.data?.subscriptions?.data;
    // console.log('Respponse of the primary key', resp?.data?.subscriptions?.data);

    let data = null;
    if (result.length > 0) {
      const filterData = result.filter(
        (val) =>
          (val?.status === 'active' || val?.status === 'canceled') && val?.default_payment_method
      );

      for (let index = 0; index < result.length; index++) {
        const element = result[index];
        data = element;
        if (filterData.length > 0) {
          if (element.status === 'active' && data?.default_payment_method) {
            break;
          }

          if (element.status === 'canceled' && data?.default_payment_method) {
            data = element;
            break;
          }
        } else {
          break;
        }
      }
    }
    resp?.data?.subscriptions?.data && setPrimaryCard(data);
  };
  const deletePaymentRequest = () => {
    dispatch(deleteTheRequest(getActiveRequest?._id, navigate));
  };
  const requestForUpdatePlan = async () => {
    try {
      if (userInfo?.subscription?.title === states.plan) {
        toast.error(`You have already subscribed for this ${userInfo?.subscription?.title} plan`, {
          duration: 8000,
        });
        setOpenSwitch(false);
      } else {
        const respData = await getAll?.plans?.find((e) => e?.type === states.plan);
        console.log(respData);
        const body = {
          userId: userInfo?._id,
          customerId: userInfo?.customerId,
          subscriptionId: primaryCard?.id,
          priceId: respData?.stripeId,
          itemId: primaryCard?.items?.data[0]?.id,
          subscription: {
            title: respData?.type,
            charges: respData?.charges,
            discount: respData?.discount,
          },
          status: 'active',
          paymentMethodId: primaryCard?.default_payment_method?.id,
        };
        console.log(body);
        const response = await api.post('/planrequest', body);
        // toast.success(response?.data?.message, { duration: 4000 });
        toast.success('Your request to change plan is saved', { duration: 4000 });
        navigate('/settings');
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { duration: 4000 });
    }
  };
  console.log('data is cosnoling by primaryCard', primaryCard);
  const cancelFunction = async () => {
    await api
      .post(`/stripe/cancel-subscription`, { subscriptionId: primaryCard?.id }, config)
      .then((data) => {
        handleChange('clientSecret', data?.data?.clientSecret);
        handleChange('subscriptionId', data?.data?.subscriptionId);
      });
    dispatch(getPaymentRequest(userInfo?.customerId));

    dispatch(paymentrequest({ name: 'paymentRequest', data: [] }));
    if (moment(userInfo?.subscription?.endDate, 'YYYY/MM/DD').isBefore(moment())) {
      let body = {
        url: `/user/${userInfo?._id}`,
        subscriptionStatus: 'freemium',
        subscription: { ...userInfo?.subscription, status: 'canceled' },
      };
      dispatch(paymentrequest({ name: 'paymentRequest', data: [] }));
      dispatch(updateFuntion(body));
      handleChange('open', false);
      navigate(`/freePlan`);
    } else {
      let body = {
        url: `/user/${userInfo?._id}`,
        subscription: { ...userInfo?.subscription, status: 'canceled' },
      };
      dispatch(updateFuntion(body));
      handleChange('open', false);
      navigate(`/settings`);
      dispatch(paymentrequest({ name: 'paymentRequest', data: [] }));
    }
  };
  const cancelChangePlan = async () => {
    await api
      .post(`/stripe/cancel-subscription`, { subscriptionId: data?.id }, config)
      .then((data) => {
        handleChange('clientSecret', data?.data?.clientSecret);
        handleChange('subscriptionId', data?.data?.subscriptionId);
        toast.success(
          'Now you can choose your desired plan after the completion of your current subscription.',
          { duration: 8000 }
        );
      });

    if (moment(userInfo?.subscription?.endDate, 'YYYY/MM/DD').isBefore(moment())) {
      let body = {
        url: `/user/${userInfo?._id}`,
        subscriptionStatus: 'freemium',
        subscription: { ...userInfo?.subscription, status: 'canceled' },
      };
      dispatch(updateFuntion(body));
      handleChange('open', false);
      navigate(`/freePlan`);
    } else {
      let body = {
        url: `/user/${userInfo?._id}`,
        subscription: { ...userInfo?.subscription, status: 'canceled' },
      };
      dispatch(updateFuntion(body));
      handleChange('open', false);
      navigate(`/settings`);
    }
  };
  const apiCall = async () => {
    if (userInfo?.customerId) {
      await api
        .get(`/stripe/subscriptions/${userInfo?.customerId}`, config)
        .then((resp) => {
          const result = resp?.data?.subscriptions?.data;
          let data = null;
          if (result.length > 0) {
            const filterData = result.filter(
              (val) => val?.status === 'active' || val?.status === 'canceled'
            );

            for (let index = 0; index < result.length; index++) {
              const element = result[index];
              data = element;
              if (filterData.length > 0) {
                if (element.status === 'active') {
                  break;
                }

                if (element.status === 'canceled') {
                  data = element;
                  break;
                }
              } else {
                break;
              }
            }
          }

          setData(data);
          setCardData(data?.default_payment_method?.card);

          // // console.log('management Data', data);
        })
        .catch((err) => {
          setData([]);
        });

      await api
        .get(`/stripe?customerId=${userInfo?.customerId}`, config)
        .then((data) => {
          // setCardData(data?.data?.data?.data);
        })
        .catch((err) => {
          setCardData([]);
        });
    } else {
      setData([]);
    }
  };

  const getUsersData = () => {
    const body = {};
    body['name'] = 'userInfo';
    body['url'] = `/user/getUser/${userInfo?._id}`;
    dispatch(getFunction(body));
  };

  useEffect(() => {
    apiCall();
    if (isAuth) {
      getUsersData();
    }

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    checkprimaryCard();

    dispatch(getPaymentRequest(userInfo?.customerId));
    dispatch(getPlans());
    // eslint-disable-next-line
  }, []);
  const getPlanPrice = (planType) => {
    const results = plans?.filter((obj) => obj?.type === planType);
    if (results?.length > 0) {
      return `$${results[0]['charges']}`;
    }
  };
  console.log('state?.plans', states.plan);
  const returnEndDate = () => {
    return (
      <Grid sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <Text sx={{ fontSize: 14, mb: 2, marginLeft: '16px' }}>
          Your current subscription is granting you access to our Premium services until{' '}
          <PlanName sx={{ fontSize: 14 }}>{getDate(data?.current_period_end)}</PlanName>. When you
          decide to cancel, please note that you current subscription will be deactivated only after
          your current plan has expired.
        </Text>
        <PlanName sx={{ fontSize: 14, marginLeft: '16px' }}>
          Are you sure you want to cancel?
        </PlanName>
      </Grid>
    );
  };
  const returnChangePlan = () => {
    return (
      <Grid sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <Text sx={{ fontSize: 14, mb: 2 }}>
          Your current subscription is granting you access to our Premium services until{' '}
          <PlanName sx={{ fontSize: 14 }}>{getDate(data?.current_period_end)}</PlanName>. When you
          decide to make changes, please note that you will need to subscribe to your desired plan,
          but only after your current subscription has expired.
        </Text>
      </Grid>
    );
  };

  return (
    <MainBox sx={{ marginTop: matches600 ? 6 : 0 }}>
      <Sidebar />
      <FormBox>
        {userInfo?.subscription?.status === 'PremiumC' ? (
          <FormBoxA sx={{ height: 'max-content' }}>
            <Back sx={{ mt: 1 }} onClick={() => navigate('/settings')}>
              <ChevronLeftIcon sx={{ fontSize: 'medium' }} />
              Back to Settings
            </Back>
            <UserName sx={{ fontSize: '18px', ml: 1, mt: 1, mb: 1.5 }}>
              Manage Subscription
            </UserName>

            <Grid sx={{ mx: 2 }}>
              <Text>
                Your subscription plan has been canceled and will not be renewed at the end of your
                billing cycle. You will continue to have full access until your plan ends on{' '}
              </Text>
              <PlanName>{getDate(data?.current_period_end)}</PlanName>
            </Grid>
            <Divider sx={{ m: 2 }} />
            <Grid sx={{ display: 'flex', flexDirection: 'row', mt: 1 }}>
              <SideHeading sx={{ ml: 2 }}>Current plan </SideHeading> :{' '}
              <PlanName> {userInfo?.subscription?.title} (Canceled)</PlanName>
            </Grid>
            <Grid sx={{ display: 'flex', flexDirection: 'row', mb: 1, mt: 0.5 }}>
              <SideHeading sx={{ ml: 2 }}>Card </SideHeading> :{' '}
              <PlanName>
                {cardData && cardData?.brand}••••
                {cardData && cardData?.last4}
              </PlanName>
            </Grid>
            {/* <Grid sx={{ ml: 2, display: 'flex', flexDirection: 'row', mb: 1, mt: 0.5 }}>
              <GreenButton sx={{ mr: 2 }} onClick={() => navigate('/freePlan')}>
                Renew Subscription
              </GreenButton>
            </Grid> */}
          </FormBoxA>
        ) : (
          <FormBoxA sx={{ height: 'max-content' }}>
            <Back sx={{ ml: matches400 ? 0 : 1, mt: 1 }} onClick={() => navigate('/settings')}>
              <ChevronLeftIcon sx={{ fontSize: 'medium' }} />
              Back to Settings
            </Back>
            <UserName sx={{ fontSize: '18px', ml: matches400 ? 0 : 2, mt: 1, mb: 1.5 }}>
              Manage Subscription
            </UserName>
            {paymentRequest?.length > 0 ? (
              <Text sx={{ marginLeft: '16px' }}>
                You are currently subscribed to{' '}
                {userInfo?.subscription?.title === 'annual' ? 'an' : 'a'}
                <PlanName sx={{ fontWeight: 600 }}>
                  {userInfo?.subscription?.title ? userInfo?.subscription?.title : 'Free'}
                </PlanName>{' '}
                plan. which will be deactivated on{''}
                <PlanName sx={{ fontWeight: 600 }}>
                  {moment(userInfo?.subscription?.endDate).format('MM/DD/YYYY')}.
                </PlanName>
                {''}
                You will be switched to{' '}
                <PlanName sx={{ fontWeight: 600 }}>
                  {getActiveRequest?.subscription?.title}
                </PlanName>{' '}
                plan automatically after the current plan ends.
              </Text>
            ) : userInfo.subscription?.status === 'PremiumC' ? (
              <Text sx={{ fontSize: 14, mb: 2, marginLeft: '16px' }}>
                Your subscription plan has been canceled and will not be renewed at the end of your
                billing cycle. You will continue to have full access until your plan ends on{' '}
                <PlanName>{getDate(data?.current_period_end)}</PlanName>
              </Text>
            ) : (
              ''
            )}
            <Divider sx={{ m: 2 }} />
            <BtnRow sx={{ mb: 2, mx: 2 }}>
              <button
                className={
                  states.plan === 'annual'
                    ? 'leftBtn selectedPlanBtn'
                    : 'leftBtn notSelectedPlanBtn'
                }
                onClick={() => handlePlanChange('plan', 'annual')}
              >
                Annual
              </button>
              <button
                className={
                  states.plan === 'monthly'
                    ? 'rightBtn selectedPlanBtn'
                    : 'rightBtn notSelectedPlanBtn'
                }
                onClick={() => handlePlanChange('plan', 'monthly')}
              >
                Monthly
              </button>
            </BtnRow>
            <Grid sx={{ mx: 2 }}>
              <PriceRow>
                <span>
                  {states.plan === 'annual' ? getPlanPrice('annual') : getPlanPrice('monthly')}
                </span>
                <span>{states.plan === 'annual' ? '/month (Billed annually)' : '/month'}</span>
              </PriceRow>
              {states.plan === 'annual' ? (
                <Savings>Best Value • $20 Savings</Savings>
              ) : (
                <Save>
                  Save $20 with an&nbsp;
                  <Savings onClick={() => handlePlanChange('plan', 'annual')}>Annual Plan</Savings>
                </Save>
              )}
            </Grid>
            <Divider sx={{ m: 2 }} />

            <Grid sx={{ display: 'flex', flexDirection: 'row', mt: 1 }}>
              <SideHeading sx={{ ml: matches400 ? 0 : 2 }}>Current plan </SideHeading> :{' '}
              <PlanName>
                {' '}
                {userInfo?.subscription?.title} (${userInfo?.subscription?.charges} /mo)
              </PlanName>
            </Grid>
            <Grid sx={{ display: 'flex', flexDirection: 'row', mb: 1, mt: 0.5 }}>
              {' '}
              <SideHeading sx={{ ml: matches400 ? 0 : 2 }}>Card </SideHeading> :{' '}
              <PlanName>
                {cardData && cardData?.brand}••••
                {cardData && cardData?.last4}
              </PlanName>
            </Grid>
            {paymentRequest?.length > 0 ? (
              <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
                <PlanName sx={{ marginLeft: '16px' }}>
                  {' '}
                  You have requested to switch to {getActiveRequest?.subscription?.title} Plan
                </PlanName>
                <PlanName
                  onClick={handleopen}
                  sx={{
                    color: 'red',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    marginLeft: '0.5rem',
                  }}
                >
                  Cancel Request
                </PlanName>
              </Grid>
            ) : (
              ''
            )}

            {userInfo?.subscription?.status === 'PremiumC' ? (
              ''
            ) : // <Text sx={{ fontSize: 14, mb: 2, marginLeft: '16px' }}>
            //   {' '}
            //   We have received your request to change your current plan. You can subscribe to your
            //   new plan from here once your current subscription ends on{' '}
            //   <PlanName>{getDate(data?.current_period_end)}</PlanName>
            // </Text>
            matches400 ? (
              <Grid
                container
                sx={{
                  ml: matches400 ? 0 : 2,
                  display: 'flex',
                  flexDirection: 'row',
                  mb: 1,
                  mt: 0.5,
                  width: '100%',
                }}
              >
                {paymentRequest?.length > 0 && getActiveRequest.status === 'active' ? (
                  ''
                ) : (
                  <Grid item xs={12}>
                    <GreenButton
                      sx={{ mr: matches400 ? 0 : 2, width: '100%', mb: 2 }}
                      onClick={handleSwitchModal}
                    >
                      Change plan
                    </GreenButton>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <RedButton
                    sx={{ mr: matches400 ? 0 : 2, width: '100%' }}
                    onClick={() => handleChange('open', true)}
                  >
                    Cancel subscription
                  </RedButton>
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                sx={{ pl: 2, display: 'flex', flexDirection: 'row', mb: 1, mt: 0.5, width: '100%' }}
              >
                {paymentRequest?.length > 0 && getActiveRequest.status === 'active' ? (
                  ''
                ) : (
                  <GreenButton
                    sx={{ mr: 2 }}
                    // onClick={() => {
                    //   navigate('/freePlan');
                    // }}
                    onClick={handleSwitchModal}
                  >
                    Change plan
                  </GreenButton>
                )}
                <RedButton
                  onClick={() => {
                    handleChange('open', true);
                  }}
                >
                  Cancel subscription
                </RedButton>
              </Grid>
            )}
          </FormBoxA>
        )}
      </FormBox>
      <Cancel
        open={state.open}
        returnEndDate={returnEndDate}
        cancelFunction={cancelFunction}
        handleChange={handleChange}
      />
      <ChangePlan open={open} cancelFunction={deletePaymentRequest} setOpen={setOpen} />
      <SwitchPlan open={openSwitch} cancelFunction={requestForUpdatePlan} setOpen={setOpenSwitch} />
    </MainBox>
  );
};
export default ManageSubscription;
