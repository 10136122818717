import React, { useState } from 'react';
import Loader from '../../assets/loader1.gif';
import PhoneInput from 'react-phone-number-input';
import toast from 'react-hot-toast';

import { FP, LoaderSpan } from './Elements';
import { CustomGreenLargeButton } from '../../components/Button';
import { FormHelperText } from '@mui/material';
import { RecaptchaVerifier } from 'firebase/auth';
import { signInWithPhoneNumber } from 'firebase/auth';
import { authentication } from '../../firebase';
import api from '../../api';

const Phone = ({ setStep, handleScreen }) => {
  const [state, setState] = useState({
    phoneCountryCode: 'US',
    phone: '',
    loading: false,
  });
  const handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const [isPhoneError, setPhoneError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
        callback: (response) => {},
      },
      authentication
    );
  };
  const submitFunction = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    if (state?.phone) {
      handleChange('loading', true);
      generateRecaptcha();
      let appVerifier = window.recaptchaVerifier;

      api
        .get(`user/verifyPhoneNumber?phone=${state?.phone.replace("+","").trim()}`)
        .then(() => {
          signInWithPhoneNumber(authentication, state?.phone, appVerifier)
            .then((confirmationResult) => {
              window.confirmationResult = confirmationResult;
              handleScreen('phone', state?.phone);
              toast.success(`Otp sent to ${state?.phone}`, { duration: 4000 });
              setStep(2);
              handleChange('loading', false);
            })
            .catch((error) => {
              console.log(error.code);
              if (error.message == 'Firebase: TOO_LONG (auth/invalid-phone-number).') {
                toast.error('Invalid phone number.  Please try again', { duration: 4000 });
              } else if (error.message == 'reCAPTCHA has already been rendered in this element') {
                toast.error('Please reload and try again', { duration: 4000 });
              } else if (error.message == 'Firebase: TOO_SHORT (auth/invalid-phone-number).') {
                toast.error('Invalid phone number.  Please try again', { duration: 4000 });
              } else if (error.message == "Firebase: Error (auth/billing-not-enabled)."){
                toast.error('Billing not Enabled', { duration: 4000 });
              } else {
                toast.error('Please try again.', { duration: 4000 });
              }
              handleChange('loading', false);
            })
            .finally(() => {
              setIsLoading(false);
            });
        })
        .catch((error) => {
          toast.error(error.response.data.message, { duration: 4000 });
        })
        .finally(() => {
          setIsLoading(false);
          handleChange('loading', false);
        });
    } else {
      setPhoneError(true);
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={submitFunction} className="submitForm">
      <div id="recaptcha-container"></div>

      <PhoneInput
        defaultCountry={state?.phoneCountryCode}
        international
        countryCallingCodeEditable={false}
        placeholder="Enter phone number"
        value={state?.phone}
        onChange={(e) => handleChange('phone', e)}
      />
      {isPhoneError ? (
        <FormHelperText
          sx={{
            fontFamily: 'Poppins-Medium',
            color: '#d32f2f',
            marginTop: 0,
          }}
        >
          {'Phone is required'}
        </FormHelperText>
      ) : null}
      <FormHelperText sx={{ fontFamily: 'Poppins-Medium', color: '#d32f2f', marginTop: 0 }}>
        {state?.error}
      </FormHelperText>

      <CustomGreenLargeButton type="submit" variant="contained" disableripple disabled={state?.loading || isLoading}>
        {' '}
        {state?.loading || isLoading ? (
          <LoaderSpan>
            <img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} />
            Send Code
          </LoaderSpan>
        ) : (
          'Send Code'
        )}{' '}
      </CustomGreenLargeButton>

      <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
        <FP
          onClick={() => {
            handleScreen('withPhone', false);
            handleScreen('withEmail', true);
          }}
        >
          Use Email Address instead
        </FP>
      </div>
    </form>
  );
};
export default Phone;
