import React, { useState, useEffect } from 'react';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Sidebar from '../../components/Sidebar';

import { MainBox } from '../Elements';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import { getDropdowns } from '../../features/general/action';

const CreateTrip = () => {
  const matches600 = useMediaQuery('(max-width:600px)');
  const dispatch = useDispatch();

  const [state, setState] = useState({
    steps: 1,
    tradeOptions:[],
    photos: [],
    freeUser: false,
    acceptTradeOffers: false,
    durationValue: 1,
    durationTitle: 'days',
    unAvailableDays: {
      dayWeekText: '',
      excludeDateText: '',
      daysOfWeek: [],
      disableDates: [],
      disableDatesText: '',
      repeatEvery: 'weeks',
      repeatEveryNumber: 1,
      disableDatesFormated: [],
      unavailableDaysOfWeek: [],
      allUnavailableDates: [],
      excludeSpecificDates: [],
    },
    tripNotes:'',
  });
  const [selectedDayRange, setSelectedDayRange] = useState({ from: null, to: null });
  const [tempSelectedDate, setTempSelectedDate] = useState({ from: null, to: null });
  console.log('selectedDayRange', selectedDayRange);
  const handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    dispatch(getDropdowns());
    // eslint-disable-next-line
  }, []);

  return (
    <MainBox
      sx={{
        marginTop: matches600 ? 6 : 0,
        height: state.steps === 2 ? '100vh' : state.steps === 3 ? '100vh' : 'inherit',
      }}
    >
      <Sidebar />
      {state.steps === 1 && (
        <Step1
          state={state}
          handleChange={handleChange}
          selectedDayRange={selectedDayRange}
          setSelectedDayRange={setSelectedDayRange}
          setTempSelectedDate={setTempSelectedDate}
          tempSelectedDate={tempSelectedDate}
        />
      )}
      {state.steps === 2 && <Step2 state={state} handleChange={handleChange} />}
      {state.steps === 3 && (
        <Step3 state={state} handleChange={handleChange} selectedDayRange={selectedDayRange} />
      )}
    </MainBox>
  );
};
export default CreateTrip;
