import api from "../../api";

import { config } from "../../utills"
import { saveData, startLoading, stopLoading } from "./reducer";

export const getFunction = (body) => async (dispatch) => {
    dispatch(saveData({ name: body.name, data: [] }));
    dispatch(startLoading())
    let response = null
    await api.get(body.url, config)
        .then((data) => {
            response = data

            if(body.name === "reviews"){
                dispatch(saveData({ name: body.name, data: data.data }));
            }else{
                dispatch(saveData({ name: body.name, data: data.data[body.name] || data.data.data || data.data.doc }));
            }
            dispatch(stopLoading())
        })
        .catch((err) => {
            response = err
            if (err.response) {
                dispatch(saveData({ name: body.name, data: [] }))
                dispatch(stopLoading())
            }
        });

    return response
};

