import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { daysDropdown } from '../../data';
import { Text } from '../CreateTrip/Elements';
import { monthName, getZero, config } from '../../utills/constants';
import { Box, Grid, FormHelperText, Checkbox, Skeleton, FormControlLabel } from '@mui/material';
import { GreyText, Description } from './Elements';
import { CustomLabel2 } from '../../components/InputFields/Elements';
import { UnAvailableDays } from '../../components/Modal/UnAvailableDays';
import { InstructionsSpan, GreenButton, TransparentBtn } from '../Elements';
import { DateRangeInput } from '../../components/InputFields/DateRangeInput';
import {
  Input,
  Dropdown,
  SpeciesDropdown,
  EmptyDropdown,
} from '../../components/InputFields/CreateTripFormFields';
import moment from 'moment';
import { Header } from '../../components/Modal/ModalHeader';
import speciesIcon from '../../assets/home/species.png';
import api from '../../api';
import { FullTextArea } from '../../components/InputFields/Input';

const EditOffer = ({ handleNestedChange, handleClose, handleChange, state }) => {
  const { types, species, states } = useSelector((state) => state.general);

  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null,
  });
  const [tempSelectedDate, setTempSelectedDate] = useState({ from: null, to: null });

  const [optionLoading, setOptionLoading] = useState(true);

  const handleOpenDaysModal = () => {
    // console.log(selectedDayRange?.from===);
    if (selectedDayRange.from!==null &&selectedDayRange.to!==null) {
      handleChange('openUnAvailableDays', true);
      setSelectedDayRange({ ...selectedDayRange });
    }
  };
  const handleState = (name, value) => {
    handleNestedChange('unAvailableDays', value);
  };
  const handleChangeStep = () => {
    if (!state?.hostOffer?.tradeType) {
      handleChange('tripTypeError', true);
      return;
    } else if (!state?.hostOffer?.locationCity?.trim()) {
      handleChange('locationCityError', true);
      return;
    } else if (!state?.hostOffer?.locationState) {
      handleChange('locationStateError', true);
      return;
    } else if (!state?.hostOffer?.species) {
      handleChange('speciesError', true);
      return;
    } else if (!state?.hostOffer?.durationTitle || !state?.hostOffer?.durationValue) {
      handleChange('durationError', true);
      return;
    } else if (!selectedDayRange?.from || !selectedDayRange?.to) {
      handleChange('availablityDateError', true);
      return;
    }
    if (selectedDayRange?.from == null) {
      handleChange('availablityDateError', true);
    } else {
      const days = {
        title: state?.hostOffer?.durationTitle,
        value: state?.hostOffer?.durationValue,
      };
      const location = {
        city: state?.hostOffer?.locationCity,
        state: state?.hostOffer?.locationState,
      };
      const availableFrom = `${monthName(selectedDayRange?.from?.month)} ${
        selectedDayRange?.from?.day
      }, ${selectedDayRange?.from?.year} `;
      const availableTo = `${monthName(selectedDayRange?.to?.month)} ${
        selectedDayRange?.to?.day
      }, ${selectedDayRange?.to?.year}`;
      //************************************* */
      let disablesDatesFormat = [];
      state?.hostOffer?.unAvailableDays?.disableDatesFormated?.forEach((res) => {
        disablesDatesFormat.push(res.month + '/' + res.day + '/' + res.year);
      });
      let title = '';
      let dt = '';
      let dtitle = state?.hostOffer?.durationTitle;
      dt = state?.hostOffer?.durationValue + ' ' + dtitle;
      title = dt + ' ' + state?.hostOffer?.species;
      const unAvailableDays = {
        ...state?.hostOffer?.unAvailableDays,
        repeatEvery: {
          endRepeatOn: new Date(),
          value: 1,
          title: 'weeks',
        },
      };
      handleChange('step', 3);
      handleNestedChange('duration', days);
      handleNestedChange('unAvailableDays', unAvailableDays);
      handleNestedChange('location', location);
      handleNestedChange('availableTo', availableTo);
      handleNestedChange('availableFrom', availableFrom);
    }
  };

  useEffect(() => {
    handleNestedChange('unAvailableDays', {
      dayWeekText: '',
      excludeDateText: '',
      daysOfWeek: [],
      disableDates: [],
      disableDatesText: '',
      repeatEvery: 'weeks',
      repeatEveryNumber: 1,
      allUnavailableDates: [],
      disableDatesFormated: [],
      unavailableDaysOfWeek: [],
      excludeSpecificDates: [],
    });

    handleNestedChange('locationCity', state?.hostOffer?.location?.city);
    handleNestedChange('locationState', state?.hostOffer?.location?.state);
    handleNestedChange('durationTitle', state?.hostOffer?.duration?.title);
    handleNestedChange('durationValue', state?.hostOffer?.duration?.value);
    // eslint-disable-next-line
  }, []);

  const getUnAvailableDays = () => {
    if (selectedDayRange.from != null) {
      return (
        state?.hostOffer?.unAvailableDays?.dayWeekText +
        ' ' +
        state?.hostOffer?.unAvailableDays?.excludeDateText
      );
    }
  };

  const handleCity = (e) => {
    handleChange('locationCityError', false);
    handleNestedChange('locationCity', e.target.value);
  };

  const handletripTypeChange = (name, value) => {
    handleNestedChange('tradeOptions', []);
    handleChange('tripTypeError', false);
    handleNestedChange(name, value);
    handleSpeciesChange('species', undefined);
  };

  const handleStateChange = (name, value) => {
    handleChange('locationStateError', false);
    handleNestedChange(name, value);
  };

  const handleSpeciesChange = (name, value) => {
    handleChange('speciesError', false);
    handleNestedChange(name, value);
  };

  // const handleDurationDropdownChange = (name, value) => {
  //     handleChange('durationError', false)
  //     handleNestedChange(name, value)
  // }
  const [isChange, setIsChange] = useState(false);

  useEffect(() => {
    setIsChange(false);
    const filteredSpecies = species?.filter(
      (elm) => elm?.type?.name === state?.hostOffer?.tradeType
    );
    handleChange('speciesData', filteredSpecies);
    setTimeout(() => {
      setIsChange(true);
    }, 500);
  }, [state?.hostOffer?.tradeType]);

  const handleDurationDropdownChange = (name, value) => {
    handleChange('durationError', false);
    if (selectedDayRange?.from == null && selectedDayRange?.to == null) {
      handleNestedChange('durationTitle', value);
    } else if (selectedDayRange?.from != null && selectedDayRange?.to != null) {
      const fromDate = moment.utc(
        selectedDayRange?.from?.year +
          '-' +
          getZero(selectedDayRange?.from?.month) +
          '-' +
          selectedDayRange?.from?.day +
          ' 00:00:00',
        'YYYY-MM-DD HH:mm:ss'
      );
      const toDate = moment.utc(
        selectedDayRange?.to?.year +
          '-' +
          getZero(selectedDayRange?.to?.month) +
          '-' +
          selectedDayRange?.to?.day +
          ' 24:00:00',
        'YYYY-MM-DD HH:mm:ss'
      );
      const diffInDays = toDate.diff(fromDate, 'days');
      const _input =
        value == 'weeks'
          ? Number(state?.hostOffer?.durationValue) * 7
          : state?.hostOffer?.durationValue;
      if (_input > diffInDays) {
        setSelectedDayRange({ from: null, to: null });
        setTempSelectedDate({ from: null, to: null });
        handleNestedChange('durationTitle', value);
      } else {
        handleNestedChange('durationTitle', value);
      }
    }
  };
  const handleDaysDropdownChange = (e) => {
    handleChange('durationError', false);
    const input = e.target.value;
    if (selectedDayRange?.from == null && selectedDayRange?.to == null) {
      if (/^\d*$/.test(input)) {
        handleNestedChange('durationValue', e.target.value);
      }
    } else if (selectedDayRange?.from != null && selectedDayRange?.to != null) {
      const fromDate = moment.utc(
        selectedDayRange?.from?.year +
          '-' +
          getZero(selectedDayRange?.from?.month) +
          '-' +
          selectedDayRange?.from?.day +
          ' 00:00:00',
        'YYYY-MM-DD HH:mm:ss'
      );
      const toDate = moment.utc(
        selectedDayRange?.to?.year +
          '-' +
          getZero(selectedDayRange?.to?.month) +
          '-' +
          selectedDayRange?.to?.day +
          ' 24:00:00',
        'YYYY-MM-DD HH:mm:ss'
      );
      const diffInDays = toDate.diff(fromDate, 'days');
      const _input = state?.hostOffer?.durationTitle == 'weeks' ? Number(input) * 7 : input;
      if (_input > diffInDays) {
        setSelectedDayRange({ from: null, to: null });
        setTempSelectedDate({ from: null, to: null });
        if (/^\d*$/.test(input)) {
          handleNestedChange('durationValue', e.target.value);
        }
      } else {
        if (/^\d*$/.test(input)) {
          handleNestedChange('durationValue', e.target.value);
        }
      }
    }
    // handleNestedChange('durationValue', e.target.value)
  };

  useEffect(() => {
    const uniqueCategoryNames = [...new Set(species?.map((item) => item?.type?.name))];
    const filteredCategoriesArray = types?.filter((item) =>
      uniqueCategoryNames?.includes(item?.name)
    );
    handleChange('typesData', filteredCategoriesArray);
  }, []);

  const getTradeTypeOptions = async (tradeType) => {
    setOptionLoading(true)
    handleChange('typeOptionsData', []);
    const typeId = types?.find((el) => el?.name === tradeType)?._id;
    try {
      const response = await api.get(`/tradetypeoptions/list?typeId=${typeId}&status=active`, config);
      const activeOptions = response?.data?.data?.filter((el) => el?.status == 'active')
      handleChange('typeOptionsData', activeOptions);
    } catch (error) {
      console.error('error: ', error);
    } finally{
      setOptionLoading(false)
    }
  };

  useEffect(() => {
    if (state?.hostOffer?.tradeType) {
      getTradeTypeOptions(state?.hostOffer?.tradeType);
    }
  }, [state?.hostOffer?.tradeType]);

  const handleCheckboxChange = (e, option) => {
    const { checked } = e.target;
    const tradeOption = {
      _id: option?._id,
      name: option?.name,
      image: option?.image,
    };

    if (checked) {
      // Add the trade option if it's checked
      handleNestedChange('tradeOptions', [...state?.hostOffer?.tradeOptions, tradeOption]);
    } else {
      // Remove the trade option if it's unchecked
      const updatedTradeOptions = state?.hostOffer?.tradeOptions?.filter((item) => item?.name !== option?.name);
      handleNestedChange('tradeOptions', updatedTradeOptions);
    }
  };


  return (
    <>
      {/* ========= THIS IS WHEN A TRIP IS SELCTED DURING MAKE OFFER ======= */}
      <Box sx={{ height: '600px' }}>
        <Header handleClose={handleClose} heading="Edit Offer" />
        <Description sx={{ textAlign: 'left' }}>
          Now, let's fill out the details of your offer.
        </Description>
        <Grid container sx={{ overflowY: 'scroll', height: '80%' }}>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginRight: 2 }}>
            <Dropdown
              value={state?.hostOffer?.tradeType}
              placeholder="Example: Central NC Whitetail Hunting"
              data={types}
              handleChange={handletripTypeChange}
              handleFor="tradeType"
            />
            {state?.tripTypeError && (
              <FormHelperText
                sx={{
                  fontFamily: 'Poppins-Medium',
                  color: '#d32f2f',
                  marginTop: 1,
                }}
              >
                {'Please select trip type.'}
              </FormHelperText>
            )}
          </Grid>
          {optionLoading ? (
            <Grid item xs={12} sx={{ mr: 2 }}>
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            </Grid>
          ) : (
            state?.typeOptionsData?.length > 0 && (
              <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mr: 2 }}>
                <CustomLabel2>Using</CustomLabel2>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {state?.typeOptionsData?.map((option) => {
                    return (
                      <FormControlLabel
                        key={option?._id}
                        control={
                          <Checkbox
                            checked={state?.hostOffer?.tradeOptions?.some(
                              (el) => el?.name === option?.name
                            )}
                            onChange={(e) => handleCheckboxChange(e, option)}
                          />
                        }
                        label={
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            <img src={option?.image} width="20" height="20" />
                            <CustomLabel2>{option?.name}</CustomLabel2>
                          </Box>
                        }
                      />
                    );
                  })}
                </Box>
              </Grid>
            )
          )}
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginRight: 2 }}>
            <CustomLabel2>Located in... </CustomLabel2>
            <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
              <Grid item xs={6} lg={8}>
                <Input
                  value={state?.hostOffer?.locationCity}
                  placeholder={'Example: Southeastern'}
                  onChange={handleCity}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <Dropdown
                  value={state?.hostOffer?.locationState || state?.hostOffer?.location?.state}
                  placeholder="State"
                  data={states}
                  handleChange={handleStateChange}
                  handleFor="locationState"
                />
              </Grid>
            </Grid>
            {state?.locationStateError && (
              <FormHelperText
                sx={{
                  fontFamily: 'Poppins-Medium',
                  color: '#d32f2f',
                  marginTop: 0,
                }}
              >
                {'Please select state.'}
              </FormHelperText>
            )}
            {state?.locationCityError && (
              <FormHelperText
                sx={{
                  fontFamily: 'Poppins-Medium',
                  color: '#d32f2f',
                  marginTop: 0,
                }}
              >
                {'Please enter country.'}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginRight: 2 }}>
            <CustomLabel2>Please enter the species </CustomLabel2>

            {isChange ? (
              <SpeciesDropdown
                value={state?.hostOffer?.species}
                label="Any species"
                title="Species"
                data={state?.speciesData}
                handleChange={handleSpeciesChange}
                handleFor="species"
                placeholder="Example: Whitetail Deer"
                icon={speciesIcon}
              />
            ) : (
              <EmptyDropdown placeholder="Example: Whitetail Deer" />
            )}

            {state?.speciesError && (
              <FormHelperText
                sx={{
                  fontFamily: 'Poppins-Medium',
                  color: '#d32f2f',
                  marginTop: 0,
                }}
              >
                {'Please select species.'}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginRight: 2 }}>
            <CustomLabel2>Trip Duration </CustomLabel2>
            <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
              <Grid item xs={3} lg={2}>
                <Input
                  value={state?.hostOffer?.durationValue}
                  onChange={handleDaysDropdownChange}
                />
              </Grid>
              <Grid item xs={3} lg={3}>
                <Dropdown
                  value={state?.hostOffer?.durationTitle || state?.hostOffer?.duration?.title}
                  placeholder={'Days'}
                  data={daysDropdown}
                  handleChange={handleDurationDropdownChange}
                  handleFor="durationTitle"
                />
              </Grid>
            </Grid>
            {state?.durationError && (
              <FormHelperText
                sx={{
                  fontFamily: 'Poppins-Medium',
                  color: '#d32f2f',
                  marginTop: 0,
                }}
              >
                {'Please select trip duration.'}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomLabel2>Trip Availability </CustomLabel2>
            <InstructionsSpan sx={{ fontSize: { xs: '11px', sm: '15px' } }}>
              Guests will be able to choose between these dates working.
            </InstructionsSpan>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginRight: 2 }}>
            <DateRangeInput
              state={state}
              title=""
              sx={{ width: '100%' }}
              width="100%"
              handleChange={handleChange}
              selectedDayRange={selectedDayRange}
              setSelectedDayRange={setSelectedDayRange}
              setTempSelectedDate={setTempSelectedDate}
              tempSelectedDate={tempSelectedDate}
            />
            {state?.availablityDateError && (
              <FormHelperText
                sx={{
                  fontFamily: 'Poppins-Medium',
                  color: '#d32f2f',
                  marginTop: 0,
                }}
              >
                {'Please select availability dates.'}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {state?.hostOffer?.unAvailableDays?.allUnavailableDates?.length > 0 ? (
              <Text sx={{ textAlign: 'start', mt: 2 }}>
                <CustomLabel2>Unavailable Days</CustomLabel2>
                {getUnAvailableDays()}
                <span
                  style={{ marginLeft: 20 }}
                  onClick={() => {
                    handleOpenDaysModal();
                    handleChange('isEdit', true);
                  }}
                >
                  Edit
                </span>
              </Text>
            ) : (
              <>
                {selectedDayRange.from !== null && selectedDayRange.to !== null && (
                  <Text sx={{ textAlign: 'start', mt: 2 }} onClick={handleOpenDaysModal}>
                    <span>Set unavailable days</span>
                  </Text>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mr: 2 }}>
            <CustomLabel2>Trip Note</CustomLabel2>
            <FullTextArea
              rows={2}
              onChange={(e) => handleNestedChange('tripNotes', e.target.value)}
              placeholder={'Optional'}
              title="Trip Note"
              value={state?.hostOffer?.tripNotes}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            mt: 2,
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <GreyText
            sx={{
              fontSize: { xs: '12px', sm: '12px', md: '14px', lg: '14px' },
            }}
          >
            Step 3 of 4
          </GreyText>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <TransparentBtn sx={{ px: { lg: 3 }, mr: 2 }} onClick={() => handleChange('step', 2)}>
              Back
            </TransparentBtn>
            <GreenButton onClick={handleChangeStep} type="submit" variant="contained" disableripple>
              Review Offer
            </GreenButton>
          </Box>
        </Box>
      </Box>
      <UnAvailableDays
        grandState={state}
        parentState={state}
        open={state.openUnAvailableDays}
        dateRange={selectedDayRange}
        stateName="openUnAvailableDays"
        handle={handleChange}
        handleState={handleState}
      />
    </>
  );
};

export default EditOffer;
