import React from 'react';

import GreenLoader from '../../assets/greenLoader.gif';

const FullPageLoader = ({ isLoading, width, background = '#e7e5e599' }) => {
  const loaderStyles = {
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    // left: '38%',
    // top: '50%',
    background: background,
    zIndex: 2,
    //   width: '100%',
    width: '-webkit-fill-available',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    //   pointerEvents: 'none',
  };

  if (!isLoading) {
    return <></>;
  }

  return (
    <>
      <div style={loaderStyles}>
        <img src={GreenLoader} width={width} alt="GreenLoader" />
      </div>
    </>
  );
};

export default FullPageLoader;
