import React, { useState, useEffect } from 'react';
import sort from '../../assets/messaging/sort.png';
import search from '../../assets/search/search.png';

import { UserName } from '../Elements';
import { Check } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { SearchInput } from '../../components/InputFields';
import { getFullName, getUserProfile } from '../../utills/constants';
import { saveData } from '../../features/auth/reducer';
import moment from 'moment';
import {
  Grid,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemButton,
  useMediaQuery,
} from '@mui/material';
import { db } from '../../firebase';
import {
  LeftGrid,
  MessageBox,
  Message,
  NameSectionUnRead,
  NameSectionRead,
  Time,
  NameSection,
  CountName,
} from './Elements';
import { collection, where, query, onSnapshot } from 'firebase/firestore';
import { FireStore } from '../../utills/FireStore';
import { Link } from 'react-router-dom';
import { conversationMessage } from '../../features/trip/action';

const getMessage = (text) => {
  if (text?.length > 75) {
    text = text.slice(0, 75) + '...';
    return text;
  } else return text;
};
const CustomMessageBox = ({ res, handleParentChange }) => {
  let user;
  const { userInfo } = useSelector((state) => state.auth);

  if (userInfo?._id === res?.userId1?._id) {
    user = res?.userId2;
  } else if (userInfo?._id === res?.userId2?._id) {
    user = res?.userId1;
  }

  const handleSelection = () => {
    localStorage.setItem('customerId', res.userId2?.customerId);
    handleParentChange('selectedChat', res);
    handleParentChange('receiverId', user?._id);
    handleParentChange('receiverFollowers', user?.followers);
  };
  const checkifConvoIsRead = () => {
    return res?.latestMessage?.isRead === false && res?.latestMessage?.user?._id !== userInfo?._id;
  };
  console.log('The Selected Chat is', res);

  const compare = (d, dd) => {
    let d1 = moment(d).format('YYYY-MM-DD');

    let d2 = moment(dd).format('YYYY-MM-DD');

    if (d2 > d1) {
      return 'greater';
    } else if (d2 < d1) {
      return 'smaller';
    } else {
      return 'equal';
    }
  };

  const diff_minutes = (dt2, dt1) => {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;

    diff /= 60;

    return Math.abs(Math.round(diff));
  };

  const formatDate = (d) => {
    let t = '';

    let ud = new Date(d); //update date

    let cd = new Date(); //current date

    let udcy = false; //is update date  current year

    let udy = parseInt(ud.getFullYear());

    let cdy = parseInt(cd.getFullYear());

    if (udy == cdy) {
      udcy = true;
    }

    // && min < 1440 // 1 daya minure

    let sd = ud; //start date

    let ed = cd; //end date

    let ics = compare(sd, ed); //is check date

    if (ics == 'greater') {
      if (udcy) {
        t = moment(ud).format('MMM DD');
      } else {
        t = moment(ud).format('MMM DD, YYYY');
      }
    } else {
      let min = diff_minutes(ed, sd);

      t = moment(ud).format('hh:mm a');
    }

    return t;
  };

  let create = formatDate(new Date());

  if (res?.latestMessage) {
    const timestamp = res?.latestMessage?.updatedAt;
    const date = new Date(timestamp?.seconds * 1000 + timestamp?.nanoseconds / 1000000);
    const serializableValue = new Date(date.getTime());
    create = formatDate(serializableValue);
  }

  return (
    <MessageBox
      onClick={userInfo?.subscription?.status === 'freemium' ? ' ' : handleSelection}
      sx={{
        filter: userInfo?.subscription?.status === 'freemium' ? 'blur(5px)' : '',
        background:
          userInfo?.subscription?.status === 'freemium'
            ? 'unset !important'
            : checkifConvoIsRead()
            ? '#EAF1E3'
            : 'white',
        p: 2,
      }}
    >
      <Avatar src={getUserProfile(user)} alt="user" />
      {checkifConvoIsRead() ? (
        <NameSection>
          <NameSectionUnRead>
            {user != null ? getFullName(user) : 'Trip Trader User'}
            <Time sx={{ color: '#3C6B49' }}>{create}</Time>
          </NameSectionUnRead>
          <Message
            sx={{
              // #101B10
              color: '',
            }}
          >
            {getMessage(res?.latestMessage?.message)}
          </Message>
        </NameSection>
      ) : (
        <NameSection>
          <NameSectionRead>
            {user != null ? getFullName(user) : 'Trip Trader User'}
            <Time>{create}</Time>
          </NameSectionRead>
          <Message>{getMessage(res?.latestMessage?.message)}</Message>
        </NameSection>
      )}
    </MessageBox>
  );
};

const Inbox = ({ data, setData, getInbox, handleParentChange }) => {
  const { inbox = [] } = useSelector((state) => state.auth);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = useState(1);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { userInfo } = useSelector((state) => state.auth);

  const handleFilter = (event, query) => {
    const searchWord = query;
    const newFilter = inbox?.filter((value) => {
      return (
        value?.userId1?.firstName?.toLowerCase().includes(searchWord.toLowerCase()) ||
        value?.userId2?.firstName?.toLowerCase().includes(searchWord.toLowerCase())
      );
    });
    setData([...newFilter]);
    if (searchWord === '') {
      setData(inbox);
    } else {
      setData(newFilter);
    }
  };
  const handleSort = (type) => {
    let newFilter;
    if (type === 'Date sent') {
      setSelectedIndex(1);
      newFilter = [...inbox]?.sort((a, b) => {
        return new Date(b?.createdAt) - new Date(a?.createdAt);
      });
      setData(newFilter);
    } else if (type === 'Date received') {
      setSelectedIndex(2);
      newFilter = [...inbox]?.sort((a, b) => {
        return new Date(a?.createdAt) - new Date(b?.createdAt);
      });
      setData(newFilter);
    } else if (type === 'Has attachments') {
      setSelectedIndex(3);
      newFilter = inbox?.filter((value) => {
        return value?.latestMessage?.image?.length > 0;
      });
      setData(newFilter);
    } else if (type === 'Unread') {
      setSelectedIndex(0);
      newFilter = inbox?.filter((value) => {
        return (
          value?.latestMessage?.isRead === false &&
          value?.latestMessage?.user?._id !== userInfo?._id
        );
      });
      setData(newFilter);
    }
  };
  const getUnReadCount = () => {
    return inbox?.filter((value) => {
      return (
        value?.latestMessage?.isRead === false && value?.latestMessage?.user?._id !== userInfo?._id
      );
    }).length;
  };
  const matches700 = useMediaQuery('(max-width:700px)');

  const options = [
    { name: 'Unread', handleClick: handleSort },
    { name: 'Date sent', handleClick: handleSort },
    { name: 'Date received', handleClick: handleSort },
  ];

  const getCountOfMessages = () => {
    const length = data?.filter(function (res) {
      return !res?.latestMessage?.deletedBy?.includes(userInfo?._id);
    }).length;
    return length;
  };
  useEffect(() => {
    var initState = true;
    const chatroomsRef = collection(db, 'chatrooms');
    const userQuery = query(chatroomsRef, where(`user.${userInfo?._id}`, '==', true));

    const observer = onSnapshot(userQuery, async (querySnapshot) => {
      if (initState) {
        initState = false;
      } else {
        const inbox = await FireStore.getAllCurrentUserRooms(userInfo?._id);
        dispatch(saveData({ name: 'inbox', data: inbox }));
      }
    });
    return () => observer();
  }, []);
  return (
    <>
      <LeftGrid item xs={12} sm={matches700 ? 12 : 5} md={4} lg={4}>
        <UserName sx={{ px: 3, pt: 2 }}>Inbox</UserName>
        <Grid sx={{ px: 2, py: 1 }}>
          <SearchInput
            icon={search}
            handleSearch={handleFilter}
            handleChange={handleFilter}
            isEndAdornment={false}
          />
        </Grid>
        <CountName sx={{ px: 2.6, pt: 2, pb: 1 }}>
          {data?.length > 0 && `${getCountOfMessages()} message(s), ${getUnReadCount()} unread`}
          {data?.length === 0 && '0 message(s), 0 unread'}
          <img src={sort} alt="sort" onClick={handleClick} style={{ textAlign: 'end' }} />
        </CountName>
        {/* {data?.length > 0 && (
          <CountName sx={{ px: 2.6, pt: 2, pb: 1 }}>
            {getCountOfMessages()} message(s), {getUnReadCount()} unread
            <img src={sort} alt="sort" onClick={handleClick} />
          </CountName>
        )} */}
        {/* {data?.length === 0 && (
          <CountName sx={{ px: 2.6, pt: 2, pb: 1 }}>0 message(s), 0 unread</CountName>
        )} */}
        {data.length > 0 && userInfo?.subscription?.status === 'freemium' ? (
          <div
            style={{
              position: 'relative',
              color: 'black',
              lineHeight: 1.8,
              zIndex: '999999',
              top: '5rem',
              marginLeft: '1rem',
            }}
          >
            <p style={{ color: 'rgba(17, 17, 17, 0.4);', fontFamily: 'Poppins-Medium' }}>
              you are currently exploring Trip Trader on free trial.
              <Link
                style={{
                  color: 'white',
                  fontFamily: 'Poppins-Medium',
                  backgroundColor: '#283c2e',
                  padding: '0.2rem 0.4rem',
                  borderRadius: '10px',
                }}
                to="/freePlan"
              >
                {/* Subscribe to view conversations */}
                Subscribe
              </Link>{' '}
              to view the conversations
            </p>
          </div>
        ) : (
          ''
        )}
        <div style={{ height: '80vh', overflowY: 'auto' }}>
          {data?.map((res, i) => {
            return (
              <CustomMessageBox
                getInbox={getInbox}
                handleParentChange={handleParentChange}
                res={res}
                key={i}
              />
            );
          })}
        </div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{ 'aria-labelledby': 'basic-button' }}
        >
          {options?.map((res, index) => {
            return (
              <MenuItem
                sx={{
                  my: 0,
                  p: 0,
                  fontFamily: 'Poppins-Regular !important',
                  fontSize: 'small',
                }}
                selected={index === selectedIndex}
                onClick={() => res?.handleClick(res.name)}
              >
                <ListItemButton>
                  <ListItemIcon>
                    {index === selectedIndex && <Check sx={{ color: '#3C6B49', mr: 1 }} />}
                  </ListItemIcon>
                  {res?.name}
                </ListItemButton>
              </MenuItem>
            );
          })}
        </Menu>
        {/* <Grid
          sx={{
            px: 2.6,
            pt: 2,
            pb: 1,
            background: '#F9F9F9',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {<CountName>{'End of messages'}</CountName>}
        </Grid> */}
      </LeftGrid>
    </>
  );
};
export default Inbox;
