import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { FeaturedItemImage } from '../../pages/Search/Elements';
import CustomLightBox from './CustomLightBox';

const CustomCarousel = ({ images = [] }) => {
  const [open, setOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onNext = () => {
    if (currentImageIndex < images.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  const onPrev = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  return (
    <div>
      <Carousel showThumbs={false} autoPlay={false} showStatus={false}>
        {images?.map((res, index) => (
          <FeaturedItemImage onClick={() => handleImageClick(index)} key={index}>
            <img src={res} alt={`img-${index}`} style={{ width: '100%', cursor: 'pointer' }} />
          </FeaturedItemImage>
        ))}
      </Carousel>

      <CustomLightBox
        open={open}
        images={images}
        currentIndex={currentImageIndex}
        onClose={onClose}
        onNext={onNext}
        onPrev={onPrev}
      />
    </div>
  );
};

export default CustomCarousel;
