import React, { useEffect } from 'react';

import moment from 'moment';
import Sidebar from '../../components/Sidebar';
import rightArrow from '../../assets/rightArrow.png';
import GreenLoader from '../../assets/greenLoader.gif';

import { MainBox } from '../Elements';
import { getTrip } from '../../features/trip/action';
import { useDispatch, useSelector } from 'react-redux';
import { getFunction } from '../../features/auth/action';
import { useMediaQuery, Grid, Box } from '@mui/material';
import { FormBox3, Edit, TransparentBtn } from './Elements';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { UserName, ActivityDetail, ActivityHeading } from '../Elements';
import { capitalize, modifyDurationTitle } from '../../utills/constants';

import './style.css';
import '../Profile/style.css';

const SuccessCreateTrip = () => {
  const matches600 = useMediaQuery('(max-width:600px)');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const { trip, loading } = useSelector((state) => state.trip);
  const { id } = useParams();

  useEffect(() => {
    dispatch(getTrip(id));
    const body = { name: 'trips', url: `/trip?hostId=${userInfo?._id}` };
    dispatch(getFunction(body));
    // eslint-disable-next-line
  }, []);

  const getUnAvailableDays = () => {
    const a = trip[0]?.unAvailableDays?.dayWeekText || '';
    const b = trip[0]?.unAvailableDays?.excludeDateText || '';
    const c = a + ' ' + b;
    return c;
  };
  return (
    <MainBox sx={{ marginTop: matches600 ? 6 : 0, height: '100vh' }}>
      <Sidebar />
      {/* <FormBox3 sx={{ height: '470px' }}> */}
      <FormBox3 sx={{ height: '550px' }}>
        {loading && (
          <div
            style={{
              position: 'absolute',
              marginLeft: 'auto',
              marginRight: 'auto',
              textAlign: 'center',
              left: '38%',
              top: '50%',
            }}
          >
            <img src={GreenLoader} width="80" alt="GreenLoader" />
          </div>
        )}
        <UserName sx={{ fontSize: '18px' }}>Trip Created Successfully!</UserName>
        {/* {userInfo?.subscriptionStatus === 'freemium' && <div className="subscribeAlert">
                <span><Link to="/freePlan">Subscribe</Link> to make this trip available and receive trade offers.</span> <Link to="/freePlan"><img src={rightArrow} alt="rightArrow" /></Link>
            </div>} */}
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
            <ActivityHeading>OFFERING</ActivityHeading>
            <ActivityDetail sx={{ color: '#101B10' }}>
              {trip[0]?.species} {trip[0]?.tradeType}{' '}
            </ActivityDetail>
            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 0.2, mt: 2 }}>
                    {trip?.[0]?.tradeOptions?.map((option) => {
                    return <img src={option?.image} key={option?._id} width="20" height="20" />;
                })}
          </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mb: 2 }}>
            <ActivityHeading>TRIP LOCATION</ActivityHeading>
            <ActivityDetail sx={{ color: '#101B10' }}>
              {capitalize(trip[0]?.location?.city) + ', ' + trip[0]?.location?.state}
            </ActivityDetail>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mb: 2 }}>
            <ActivityHeading>TRIP DURATION</ActivityHeading>
            <ActivityDetail sx={{ color: '#101B10' }}>
              {trip[0]?.duration?.value +
                ' ' +
                modifyDurationTitle(trip[0]?.duration?.title, trip[0]?.duration?.value)}
            </ActivityDetail>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mb: 2 }}>
            <ActivityHeading>TRIP Availability</ActivityHeading>
            <ActivityDetail sx={{ color: '#101B10' }}>
              {moment.utc(trip[0]?.availableFrom).format('MMM DD') +
                ' - ' +
                moment.utc(trip[0]?.availableTo).format('MMM DD')}
                {console.log("trip[0]?.availableFrom",trip[0]?.availableFrom)}
            </ActivityDetail>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mb: 2 }}>
            <ActivityHeading>UNAVAILABLE DAYS</ActivityHeading>
            <ActivityDetail sx={{ color: '#101B10' }}>{getUnAvailableDays()}</ActivityDetail>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mb: 2 }}>
            <ActivityHeading>TRIP NOTE</ActivityHeading>
            <ActivityDetail sx={{ color: '#101B10' }} className="truncate-two-lines">{trip?.[0]?.tripNotes}</ActivityDetail>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <ActivityHeading>TRIP PHOTOS</ActivityHeading>
            <Grid
              container
              sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
            >
              {trip[0]?.photos?.map((res, i) => {
                return (
                  <Grid item key={i} xs={2} sm={2} md={2} lg={2} className="uploadedImages">
                    <img src={res} alt="images" />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'row',
            mt: 2,
            mb: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <TransparentBtn
              onClick={() => navigate(`/editTrip/${trip[0]?._id}`)}
              sx={{ px: { lg: 4 } }}
            >
              Edit Trip
            </TransparentBtn>
            <Edit onClick={() => navigate(`/profile/${userInfo?._id}`)}>Go to My Profile</Edit>
          </Box>
        </Grid>
      </FormBox3>
    </MainBox>
  );
};
export default SuccessCreateTrip;
