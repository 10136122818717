import React, { useEffect, useState } from 'react';

import api from '../../api';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import Loader from '../../assets/loader1.gif';
import info from '../../assets/profile/info.png';

import { Header } from './ModalHeader';
import { CancelButton } from '../Button';
import { TextArea } from '../InputFields/Input';
import { Heading, Description } from './Elements';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LoaderSpan } from '../../pages/SignIn/Elements';
import { getFunction } from '../../features/profile/action';

import { config, swalWithStyledButton } from '../../utills/constants';
import { stopLoading, startLoading } from '../../features/trip/reducer';
import { Box, Modal, Grid, Rating, Tooltip } from '@mui/material';
import { capitalize, getFullName } from '../../utills/constants';
import {
  style,
  GreenButton,
  InfoHeading,
  InfoDescription,
  RedButton,
  DisputeDescription,
} from './Elements';
import { reload } from '../../features/auth/action';

export const ReviewFeedback = ({ open, handle, stateName }) => {
  const handleClose = () => handle([stateName], false);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, width: 'max-content', height: 250, alignItems: 'flex-start', p: 3 }}>
          <Heading>Review submitted!</Heading>
          <Description>
            Thanks for your feedback! We appreciate you taking time to share your <br />
            experiences with the Trip Trader community.
          </Description>

          <Box sx={{ display: 'flex', mt: 2 }}>
            <GreenButton
              onClick={handleClose}
              type="submit"
              variant="contained"
              disableripple
              sx={{ mr: 2 }}
            >
              Done
            </GreenButton>
            <CancelButton onClick={handleClose} type="submit" variant="contained" disableripple>
              Go to my review
            </CancelButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
export const LeaveReviewModal = ({ apiCall, open, handle, stateName }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [state, setState] = React.useState({
    message: '',
    title: '',
    rating: 0,
    openFeedbackModel: false,
  });

  const handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => handle([stateName], false);

  const { loading, userInfo, confirmedTrips } = useSelector((state) => state.auth);

  useEffect(() => {
    const newArray = confirmedTrips?.filter(function (el) {
      return el?.offeredTo?._id === id || el?.offeredBy?._id === id;
    });
    const sortedTrip =
      newArray?.length > 0 &&
      newArray?.sort(function (a, b) {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      })[0];
    if (sortedTrip?.offeredBy?._id === userInfo?._id) {
      handleChange('title', sortedTrip?.offeredTrip?.title);
    } else if (sortedTrip?.offeredTo?._id === userInfo?._id) {
      handleChange('title', sortedTrip?.hostTrip?.title);
    }
    // eslint-disable-next-line
  }, []);

  const handleFunction = () => {
    handleClose();
    apiCall('profileInfo');
    apiCall('reviews');
    handleChange('rating', 0);
    handleChange('openFeedbackModel', true);
    dispatch(stopLoading());
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    dispatch(startLoading());
    if (state.message) {
      const body = {
        url: `/review`,
        message: state.message,
        role: 'host',
        hostId: id,
        featuredDate: new Date(),
        guestId: userInfo?._id,
        tripDetails: { title: state.title },
        guestRating: state.rating,
        status: 'active',
        guestName: capitalize(userInfo?.firstName) + ' ' + capitalize(userInfo?.lastName),
        guestImage: userInfo?.image,
        messages: [{ role: 'guest', message: state.message }],
      };
      await api
        .post(body.url, body, config)
        .then((res) => {
          if (
            res.data.message === `This user doesn't exist.` ||
            res.data.message == 'This user has blocked you.'
          ) {
            handleClose();

            swalWithStyledButton
              .fire({
                icon: 'error',
                allowOutsideClick: false,
                allowEscapeKey: false,
                text: res.data.message,
                confirmButtonText: 'Reload',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
              })
              .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  dispatch(stopLoading());
                  dispatch(reload(userInfo?._id, navigate));
                } else if (result.isDenied) {
                  dispatch(stopLoading());
                  dispatch(reload(userInfo?._id, navigate));
                }
              });
          } else {
            handleFunction();
          }
        })
        .catch((err) => {
          if (err.response) {
            toast.error(err.response.data.message, { duration: 4000 });
            dispatch(stopLoading());
          }
        })
        .finally(()=> setIsSubmitting(false));
    }
  };


  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: 'max-content',
            height: 'max-content',
            alignItems: 'flex-start',
            p: 3,
          }}
        >
          <Header heading="Leave a review" handleClose={handleClose} />
          <Grid container>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <InfoHeading>
                Trip Description
                <Tooltip
                  placement="top-start"
                  title={
                    <span>
                      We automatically selected this trip from <br /> your trade history with this
                      member.
                    </span>
                  }
                >
                  <img style={{ marginLeft: 5 }} src={info} alt="info" />
                </Tooltip>
              </InfoHeading>
              <InfoDescription>{state?.title}</InfoDescription>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <InfoHeading>Rate your overall experience</InfoHeading>
              <InfoDescription>
                <Rating
                  name="half-rating"
                  onChange={(event, newValue) => {
                    handleChange('rating', newValue);
                  }}
                  defaultValue={state.rating}
                  precision={0.5}
                />
              </InfoDescription>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={12}>
              <InfoHeading>Message</InfoHeading>
              <TextArea handle={handleChange} placeholder="Tell others about your trip..." />
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', mt: 2 }}>
            <GreenButton
              disabled={isSubmitting || loading}
              type="submit"
              onClick={handleSubmit}
              variant="contained"
              disableripple
              sx={{ mr: 2, opacity: !state.message ? 0.4 : 1 }}
            >
              {isSubmitting || loading ? (
                <LoaderSpan>
                  <img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} /> Submit
                  Review...
                </LoaderSpan>
              ) : (
                ' Submit Review'
              )}
            </GreenButton>
            <CancelButton onClick={handleClose} type="submit" variant="contained" disableripple>
              Cancel
            </CancelButton>
          </Box>
        </Box>
      </Modal>
      <ReviewFeedback
        open={state.openFeedbackModel}
        stateName="openFeedbackModel"
        handle={handleChange}
      />
    </div>
  );
};
export const DeleteReview = ({ apiCall, open, handle, stateName, data }) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const handleClose = () => {
    handle([stateName], false);
    apiCall('reviews');
  };

  const { loading, userInfo } = useSelector((state) => state.auth);
  const [isSubmitting,setIsSubmitting] = useState(false)
  let _body = { name: 'profileInfo', url: `/user/getUser/${id}` };
  const navigate = useNavigate();
  const handleSubmit = async () => {
    setIsSubmitting(true)
    dispatch(startLoading());
    const body = { url: `/review/${data?._id}/${id}` };
    await api
      .delete(body.url, body, config)
      .then((res) => {
        if (
          res.data.message == `This user doesn't exist.` ||
          res.data.message == 'This user has blocked you.'
        ) {
          handleClose();
          swalWithStyledButton
            .fire({
              icon: 'error',
              allowOutsideClick: false,
              allowEscapeKey: false,
              text: res.data.message,
              confirmButtonText: 'Reload',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            })
            .then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                dispatch(stopLoading());
                handle([stateName], false);
                dispatch(reload(userInfo?._id, navigate));
              } else if (result.isDenied) {
                dispatch(stopLoading());
                handle([stateName], false);
                dispatch(reload(userInfo?._id, navigate));
              }
            });
        } else {
          dispatch(getFunction(_body));
          handle();
          handleClose();
        }
        // handle()
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, { duration: 4000 });
          dispatch(stopLoading());
        }
      }).finally(()=>{
        setIsSubmitting(false)
      });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: 'max-content',
            height: 'max-content',
            alignItems: 'flex-start',
            p: 3,
          }}
        >
          <Header heading="Delete Review" handleClose={handleClose} />
          <DisputeDescription sx={{ mt: 2 }}>
            Are you sure you want to delete your review for <span>{getFullName(data?.hostId)}</span>
          </DisputeDescription>
          <Box sx={{ display: 'flex', mt: 4, mb: 1 }}>
            <RedButton
              type="submit"
              disabled={isSubmitting}
              onClick={handleSubmit}
              variant="contained"
              disableripple
              sx={{ mr: 2 }}
            >
              {isSubmitting || loading ? (
                <LoaderSpan>
                  <img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} /> Yes,
                  delete it...
                </LoaderSpan>
              ) : (
                '  Yes, delete it'
              )}
            </RedButton>
            <CancelButton onClick={handleClose} type="submit" variant="contained" disableripple>
              No, keep it
            </CancelButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
export const DeleteReply = ({ apiCall, open, handle, stateName, data }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const finalId = location.pathname.includes('public') ? id : data?.guestId?._id;
  const handleClose = () => {
    handle('openDeleteReply', false);
    apiCall('reviews');
  };

  const { loading, userInfo } = useSelector((state) => state.auth);

  const handleSubmit = async () => {
    const body = {
      url: `/review/deleteAReview/${data?._id}/${data?.messages?.[1]?._id}`,
      guestId: finalId,
    };

    dispatch(startLoading());
    await api
      .put(body.url, body, config)
      .then((res) => {
        if (
          res.data.message == `This user doesn't exist.` ||
          res.data.message == 'This user has blocked you.'
        ) {
          swalWithStyledButton
            .fire({
              icon: 'error',
              allowOutsideClick: false,
              allowEscapeKey: false,
              text: res.data.message,
              confirmButtonText: 'Reload',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            })
            .then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                dispatch(stopLoading());
                dispatch(reload(userInfo?._id, navigate));
              } else if (result.isDenied) {
                dispatch(stopLoading());
                dispatch(reload(userInfo?._id, navigate));
              }
            });
        } else {
          dispatch(stopLoading());
          handle();
        }
        handle('openDeleteReply', false);
        dispatch(reload(userInfo?._id, navigate));
        navigate(`/profile/${id}`);
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, { duration: 4000 });
          dispatch(stopLoading());
        }
      });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: 'max-content',
            height: 'max-content',
            alignItems: 'flex-start',
            p: 3,
          }}
        >
          <Header heading="Delete Reply" handleClose={handleClose} />
          <DisputeDescription sx={{ mt: 2 }}>
            Are you sure you want to delete your review for{' '}
            <span>{getFullName(data?.guestId)}</span>
          </DisputeDescription>
          <Box sx={{ display: 'flex', mt: 4, mb: 1 }}>
            <RedButton
              type="submit"
              onClick={handleSubmit}
              variant="contained"
              disableripple
              sx={{ mr: 2 }}
            >
              {loading ? (
                <LoaderSpan>
                  <img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} /> Yes,
                  delete it...
                </LoaderSpan>
              ) : (
                '  Yes, delete it'
              )}
            </RedButton>
            <CancelButton onClick={handleClose} type="submit" variant="contained" disableripple>
              No, keep it
            </CancelButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
export const EditReviewModal = ({ apiCall, open, handle, stateName, data, edited }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation()

  const [state, setState] = React.useState({
    message: data?.editData?.message,
    rating: data?.edited?.guestRating,
    openFeedbackModel: false,
  });

  const handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => handle([stateName], false);

  const { loading, userInfo } = useSelector((state) => state.auth);
  console.log('data: ==>', data);
  console.log('edited: ==>', edited);
  console.log('state: ==>', state);

  const handleFunction = () => {
    handleClose();
    apiCall('profileInfo');
    apiCall('reviews');
    // handleChange('rating', 0);
    handleChange('openFeedbackModel', true);
    dispatch(stopLoading());
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    dispatch(startLoading());
    if (state.message) {
      const body = {
        'url': `/review/editAReply/${edited?._id}/${data?.editData?._id}`, 
        guestId: edited?.guestId?._id, 
        guestName: capitalize(userInfo?.firstName) + " " + capitalize(userInfo?.lastName),
        guestImage: userInfo?.image,
        guestRating: state?.rating,
        message: state?.message,
        isReviewEdited: true,
        // hostId: edited?.hostId?._id, 
        // role: 'host',
    }
      await api
        .put(body.url, body, config)
        .then((res) => {
          if (
            res.data.message === `This user doesn't exist.` ||
            res.data.message == 'This user has blocked you.'
          ) {
            handleClose();

            swalWithStyledButton
              .fire({
                icon: 'error',
                allowOutsideClick: false,
                allowEscapeKey: false,
                text: res.data.message,
                confirmButtonText: 'Reload',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
              })
              .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  dispatch(stopLoading());
                  dispatch(reload(userInfo?._id, navigate));
                } else if (result.isDenied) {
                  dispatch(stopLoading());
                  dispatch(reload(userInfo?._id, navigate));
                }
              });
          } else {
            handleFunction();
          }
        })
        .catch((err) => {
          if (err.response) {
            toast.error(err.response.data.message, { duration: 4000 });
            dispatch(stopLoading());
          }
        })
        .finally(()=> setIsSubmitting(false));
    }
  };

  useEffect(() => {
    setState({
      message: data?.editData?.message || '',
      rating: data?.edited?.guestRating || 0,
      openFeedbackModel: false,
    });
  }, [data]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: 'max-content',
            height: 'max-content',
            alignItems: 'flex-start',
            p: 3,
          }}
        >
          <Header heading="Edit a review" handleClose={handleClose} />
          <Grid container>
            <Grid item xs={12}>
              <InfoHeading>Rate your overall experience</InfoHeading>
              <InfoDescription>
                <Rating
                  name="half-rating"
                  onChange={(event, newValue) => {
                    handleChange('rating', event.target.value);
                  }}
                  value={state.rating}
                  precision={0.5}
                />
              </InfoDescription>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={12}>
              <InfoHeading>Message</InfoHeading>
              <TextArea handle={handleChange} value={state?.message} placeholder="Tell others about your trip..." />
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', mt: 2 }}>
            <GreenButton
              disabled={isSubmitting || loading}
              type="submit"
              onClick={handleSubmit}
              variant="contained"
              disableripple
              sx={{ mr: 2, opacity: !state.message ? 0.4 : 1 }}
            >
              {isSubmitting || loading ? (
                <LoaderSpan>
                  <img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} /> Editing
                  Review...
                </LoaderSpan>
              ) : (
                ' Submit Review'
              )}
            </GreenButton>
            <CancelButton onClick={handleClose} type="submit" variant="contained" disableripple>
              Cancel
            </CancelButton>
          </Box>
        </Box>
      </Modal>
      <ReviewFeedback
        open={state.openFeedbackModel}
        stateName="openFeedbackModel"
        handle={handleChange}
      />
    </div>
  );
};