import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Grid, useMediaQuery, Drawer } from "@mui/material";
import { getDropdowns } from "../../features/general/action";
import { FilterGrid, Reset, Filter, GreenButton } from "./Elements";
import { Dropdown, CustomCheckbox } from "../../components/InputFields";

import speciesIcon from "../../assets/home/species.png";
import buildingIcon from "../../assets/home/building.png";
import activityIcon from "../../assets/home/activity.png";

import "./style.css";

const GetFilterBox = ({ children, mobileOpen, handleDrawerToggle, matches }) => {
  if (matches) {
    return (
      <Drawer anchor={"right"} open={mobileOpen} onClose={handleDrawerToggle}>
        <div style={{ padding: 20 }}>{children}</div>
      </Drawer>
    );
  } else if (!matches) {
    return children;
  }
};

const FilterBox = ({
  handleFilterButton,
  resetFunction,
  mobileOpen,
  handleDrawerToggle,
  handleChange,
  state,
}) => {
  const dispatch = useDispatch();

  const matches = useMediaQuery("(max-width:1199px)");

  const { types, species, states } = useSelector((state) => state.general);

  const [filteredSpecies,setFilteredSpecies] = useState([])

  useEffect(() => {
    dispatch(getDropdowns());
    // eslint-disable-next-line
  }, []);

  const handleStatusChange = (e) => {
    if (e.target.checked) {
      handleChange("userStatus", "verified");
    } else if (!e.target.checked) {
      handleChange("userStatus", "");
    }
  };
  useEffect(() => {
    const newSpecies = species?.filter((elm) => elm?.type?.name === state?.tradeType);
    setFilteredSpecies(newSpecies)
  }, [state?.tradeType]);

  const handleActivityChange = (handleFor,value) =>{
    handleChange(handleFor, value)
    handleChange('species', "");
  }
  return (
    <Grid container sx={{ width: "98%", margin: "auto" }}>
      <GetFilterBox mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} matches={matches}>
        <FilterGrid item md={12} lg={12}>
          <Filter>Filter</Filter>
          <Reset onClick={resetFunction}>Reset</Reset>
        </FilterGrid>
        <Grid item md={12} lg={12} sx={{ mt: 2 }}>
          <CustomCheckbox
            value={state.userStatus === "verified" ? true : false}
            handleChange={handleStatusChange}
            handleFor="userStatus"
            title={"Show verified users only"}
          />
        </Grid>
        <Grid item md={12} lg={12}>
          <Dropdown
            value={state.locationState}
            title="Any state"
            label="Any state"
            icon={buildingIcon}
            data={states}
            handleChange={handleChange}
            handleFor="locationState"
          />
        </Grid>
        <Grid item md={12} lg={12}>
          <Dropdown
            value={state.tradeType}
            title="Activity"
            label="Activity"
            icon={activityIcon}
            data={types}
            // handleChange={handleChange}
            handleChange={handleActivityChange}
            handleFor="tradeType"
          />
        </Grid>
        <Grid item md={12} lg={12}>
          <Dropdown
            value={state.species}
            title="Any species"
            label="Any species"
            icon={speciesIcon}
            data={filteredSpecies}
            handleChange={handleChange}
            handleFor="species"
          />
        </Grid>
        <Grid item md={12} lg={12} sx={{ mt: 4 }}>
          <GreenButton
            onClick={handleFilterButton}
            sx={{ height: "45px", width: "100%" }}
            type="submit"
            variant="contained"
            disableripple
            disabled={
              !state.species && 
              !state.tradeType && 
              !state.locationState && 
              !state.userStatus
            }
          >
            {" "}
            Apply{" "}
          </GreenButton>
        </Grid>
      </GetFilterBox>
    </Grid>
  );
};
export default FilterBox;
