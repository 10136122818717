import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sent: [],
  trip: [],
  inbox: [],
  trips: [],
  blocked: [],
  blockedBy: [],
  reviews: [],
  received: [],
  userInfo: [],
  followers: [],
  following: [],
  conversation: [],
  notifications: [],
  confirmedTrips: [],
  savedTrips: [],
  count: [{ read: 0, unread: 0 }],
  role: 'guest',
  isAuth: false,
  loading: false,
};

const authSlice = createSlice({
  name: 'authService',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.loading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },

    saveData: (state, { payload }) => {
      state[payload.name] = payload.data;
    },

    addMargin: (state) => {
      state.addMargin = true;
    },
    removeMargin: (state) => {
      state.addMargin = false;
    },

    join: (state) => {
      state.role = 'paid';
      state.isAuth = true;
    },

    logout: (state) => {
      state.role = 'guest';
      state.isAuth = false;
      state.userInfo = null;
      localStorage.removeItem('token');
      localStorage.setItem('searchedFromHome', false);
    },
    signSuccess: (state, { payload }) => {
      console.log(payload);
      state.isAuth = true;
      state.role = payload?.data?.role || payload?.doc?.role;
      state.userInfo = payload.data || payload.doc;
      if (!localStorage.getItem('token')) {
        localStorage.setItem('token', payload.token);
      }
    },
    verificationSuccess: (state, { payload }) => {
      state.isAuth = true;
      state.role = payload?.role;
      state.userInfo = payload.userInfo;
      localStorage.setItem('token', payload.token);
    },
    updateSuccess: (state, { payload }) => {
      state.userInfo = payload.data;
    },

    failedAuth: (state) => {
      state.role = 'guest';
      state.userInfo = [];
      state.isAuth = false;
    },

    failed: (state) => {},
  },
});
const authReducer = authSlice.reducer;
export default authReducer;

export const {
  logout,
  failedAuth,
  saveData,
  updateSuccess,
  verificationSuccess,
  addMargin,
  removeMargin,
  signSuccess,
  join,
  failed,
  startLoading,
  stopLoading,
} = authSlice.actions;
