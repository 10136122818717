import React, { useState, useEffect } from "react";

import api from "../../../api"

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getFullName, config } from "../../../utills/constants";
import { LeaveReviewBox, NoTripText, Text, } from "./../Elements"

const Component2 = ({ handleChange, info }) => {

    const { id } = useParams()

    const { userInfo, confirmedTrips } = useSelector(state => state.auth)
    const { reviews } = useSelector((state) => state.profile);

    const [state, setState] = useState({ isReviewed: false, isTripFound: false })

    const _handleChange = (name, value) => { setState(prevState => ({ ...prevState, [name]: value })); };

    const checkIfAlreadyReviewed = async () => {
        if(reviews?.offer){
            _handleChange('isTripFound', true);
            _handleChange('isReviewed', reviews?.is_reviewed);
          }else{
            _handleChange('isReviewed', false);
          }
        // const newArray = confirmedTrips?.filter(function (el) { return el?.offeredTo?._id === id || el?.offeredBy?._id === id })
        // if (newArray?.length > 0) {
        //     _handleChange('isTripFound', true)
        //     await api.get(`/review/checkIfAlreadyReviewed/${id}/${userInfo?._id}`, config)
        //         .then((data) => {
        //             if (data.data.message === 'You have already reviewed this user.') {
        //                 _handleChange('isReviewed', true)
        //             } else if (data.data.message !== 'No review found.') {
        //                 _handleChange('isReviewed', false)
        //             }
        //         })
        // } else _handleChange('isReviewed', false)
    }
    useEffect(() => {
        checkIfAlreadyReviewed()
        // eslint-disable-next-line
    }, [reviews?.data?.length])
    return <LeaveReviewBox >
        <NoTripText>No trip reviews received yet.</NoTripText>
        {!state?.isReviewed && id !== userInfo?._id && state.isTripFound &&
            <Text>Looks like you recently traded with {getFullName(info)}.
                <br />
                Would you like to
                <span onClick={() => handleChange('openLeaveReviewModal', true)}> leave a review?</span></Text>}
    </LeaveReviewBox >
}

export default Component2