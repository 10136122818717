import React, { useState } from 'react';

import { style } from './Elements';
import { Modal, Box } from '@mui/material';

import Step1 from './Step1';
import Step3 from './Step3';
import SuccessStep from './SuccessStep';

const ChoosePrefferedDates = ({ apiCall, res, open, handle, stateName }) => {
  const [state, setState] = useState({
    step: 1,
    preferedDates: [],
    tripDates: [],
    freeUser: false,
    preferedDatesText: '',
    openUnAvailableDays: false,
    unAvailableDaysText: '',
    selectedDay: [],
  });
  console.log(state);

  const handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleClose = () => {
    handle([stateName], false);
    handleChange('step', 1);
    handleChange('selectedDay', []);
    handleChange('tripDates', []);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: state.set !== 1 ? '575px' : '500px',
            height: state.step === 1 ? '435px' : 'max-content',
            alignItems: 'flex-start',
            p: 3,
          }}
        >
          {state.step === 1 && (
            <Step1
              res={res}
              state={state}
              handleChange={handleChange}
              handleClose={handleClose}
              setState={setState}
            />
          )}
          {state.step === 2 && (
            <Step3
              handle={handle}
              apiCall={apiCall}
              res={res}
              state={state}
              handleChange={handleChange}
              handleClose={handleClose}
            />
          )}
          {state.step === 3 && (
            <SuccessStep
              res={res}
              state={state}
              handle={handle}
              handleChange={handleChange}
              handleClose={handleClose}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default ChoosePrefferedDates;
