import React, { useMemo } from 'react';

import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CustomOrangeLargeButton } from '../Button';
import { MainGrid, MainContainer, LogoGrid, Text, Address } from './Elements';
import { Copyright, GetSupport, GetStartText, PhoneNumber, BrandName } from './Elements';

import insta from '../../assets/home/instagram.svg';
import footerLogo from '../../assets/logo-svg.svg';
import youtube from '../../assets/home/youtube.svg';
import facebook from '../../assets/home/facebook.svg';
import linkedin from '../../assets/home/linkedin.png';
import tiktok from '../../assets/home/tiktok.svg';

const Footer = ({ refs }) => {
  const { footer } = useSelector((state) => state.general);

  const navigate = useNavigate();
  const location = useLocation();

  const scrollToSection = (sectionRef) => {
    sectionRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  const handleNavigationAndScroll = (sectionRef, scrollTo) => {
    if (location.pathname !== '/') {
      navigate('/', { state: { scrollTo } });
    } else {
      scrollToSection(sectionRef);
    }
  };

  const homePageLinks = useMemo(
    () => [
      { title: 'Home', action: () => handleNavigationAndScroll(refs?.home,"home") },
      { title: 'Featured', action: () => handleNavigationAndScroll(refs?.featured,"featured") },
      { title: 'Testimonials', action: () => handleNavigationAndScroll(refs?.reviews,"reviews") },
      { title: 'Download', action: () => handleNavigationAndScroll(refs?.download, "download") },
    ],
    [location.pathname] // Re-run this useMemo when location changes
  );

  // const scrollToDownload = () =>
  //     refs?.download?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }),
  //   scrollToFeatured = () =>
  //     refs?.featured?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }),
  //   scrollToHome = () =>
  //     refs?.home?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }),
  //   scrollToReviews = () =>
  //     refs?.reviews?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });

  // const homePageLinks = useMemo(
  //   () => [
  //     { title: 'Home', scroll: scrollToHome },
  //     { title: 'Featured', scroll: scrollToFeatured },
  //     { title: 'Testimonials', scroll: scrollToReviews },
  //     { title: 'Download', scroll: scrollToDownload },
  //   ],
  //   []
  // );

  const aboutLinks = useMemo(
    () => [
      // { title: 'About', url: '/about' },
      { title: 'Sign In', url: '/signin' },
      { title: 'Terms and Conditions', url: '/termsandconditions' },
      { title: 'Privacy Policy', url: '/privacypolicy' },
    ],
    []
  );

  return (
    <MainGrid>
      <MainContainer container spacing={2}>
        <Grid container item xs={12} sm={6} md={6} lg={7}>
          <Grid item xs={12} sm={12} md={4} lg={5}>
            <div>
              <GetSupport>Get Support</GetSupport>
              <PhoneNumber>{footer?.phone}</PhoneNumber>
            </div>
            <Address sx={{ mr: { sm: 0, md: 4, lg: 10 } }}> {footer?.address}</Address>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            {homePageLinks?.map((res, i) => {
              return (
                <Text key={i} onClick={res.action}>
                  {res?.title}
                </Text>
              );
            })}
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={4}>
            {aboutLinks?.map((res, i) => {
              return (
                <Text key={i} onClick={() => navigate(res?.url)}>
                  {' '}
                  {res?.title}
                </Text>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3} style={{ margin: 'auto', textAlign: 'center' }}>
          <GetStartText>Ready to get started?</GetStartText>
          <CustomOrangeLargeButton onClick={() => navigate('/join')}>
            Join Now
          </CustomOrangeLargeButton>
        </Grid>
      </MainContainer>
      <MainContainer container spacing={2}>
        <Grid
          container
          spacing={2}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          {/* <LogoGrid item xs={12} sm={6} md={6} lg={3} onClick={scrollToHome}> */}
          <LogoGrid item xs={12} sm={6} md={6} lg={3} onClick={()=>handleNavigationAndScroll(refs.home)}>
            <img src={footerLogo} alt="footerLogo" width={80} style={{ paddingRight: 10 }} />{' '}
            <BrandName>TRIP TRADER</BrandName>
          </LogoGrid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Copyright>
              Copyright © {new Date().getFullYear()}, TripTrader LLC. All rights reserved.
            </Copyright>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={3}
            style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}
          >
            {[
              {
                icon: facebook,
                name: 'facebook',
                link: 'https://www.facebook.com/people/Trip-Trader/61561686449941',
              },
              { icon: insta, name: 'insta', link: 'https://www.instagram.com/_triptrader' },
              { icon: youtube, name: 'youtube', link: 'https://www.youtube.com/@triptrader' },
              { icon: tiktok, name: 'tiktok', link: 'https://www.tiktok.com/@trip.trader' },
              // { icon: linkedin, name: 'linkedin', link: '' },
            ].map((res, i) => {
              return (
                <a href={res.link} target="_blank">
                  <img src={res.icon} key={i} style={{ cursor: 'pointer' }} alt={res.name} />
                </a>
              );
            })}
          </Grid>
        </Grid>
      </MainContainer>
    </MainGrid>
  );
};
export default Footer;
