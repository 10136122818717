import React, { useState } from 'react';
import { Avatar, Box, Button, Modal, Typography } from '@mui/material';
import { Header } from './ModalHeader';
import { Description, DisputeDescription, RedButton, style } from './Elements';
import api from '../../api';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { signout } from '../../features/auth/action';
import toast from 'react-hot-toast';
import { CancelButton } from '../Button';
import { config, getSubsctiptionStatus } from '../../utills/constants';
import { FireStore } from '../../utills/FireStore';

const DeleteUserModal = ({ user }) => {
  const [show, setShow] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [primaryCard, setPrimaryCard] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState('freemium');
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const getSubsctiptionDetails = async () => {
    try {
      const [subsStatus, resp] = await Promise.all([
        getSubsctiptionStatus(user?.customerId),
        api.get(`/stripe/subscriptions/${user?.customerId}`),
      ]);
      const result = resp?.data?.subscriptions?.data;

      let data = null;
      if (result.length > 0) {
        const filterData = result.filter(
          (val) =>
            (val?.status === 'active' || val?.status === 'canceled') && val?.default_payment_method
        );

        for (let index = 0; index < result.length; index++) {
          const element = result[index];
          data = element;
          if (filterData.length > 0) {
            if (element.status === 'active' && data?.default_payment_method) {
              break;
            }

            if (element.status === 'canceled' && data?.default_payment_method) {
              data = element;
              break;
            }
          } else {
            break;
          }
        }
      }
      resp?.data?.subscriptions?.data && setPrimaryCard(data);
      setSubscriptionStatus(subsStatus);
    } catch (error) {
      console.error('error: ', error);
    }
  };

  const handleClose = () => {
    setShow(false);
    setIsConfirming(false);
  };

  const handleShow = () => {
    setShow(true);
    getSubsctiptionDetails();
  };

  const cancelSubscription = async (subscriptionStatus) => {
    try {
      const isPremium = subscriptionStatus === 'Premium';

      if (!isPremium) {
        console.log(`User is ${subscriptionStatus}`);
        return;
      }

      const response = await api.post(
        `/stripe/cancel-subscription`,
        { subscriptionId: primaryCard?.id },
        config
      );

      return response;
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const handleDelete = async (id) => {
    let body = {
      userId: id,
      registrationCode: '',
      notificationEnabled: false,
    };

    try {
      const [cancelResponse, deleteResponse] = await Promise.all([
        cancelSubscription(subscriptionStatus),
        api.delete(`user/${id}`),
      ]);
      setIsDeleted(true);
      console.log('--------> deleteResponse: ', deleteResponse);
      if (deleteResponse?.data?.message === 'User Deleted Successfully') {
        FireStore.updateUserinFirestore(id, deleteResponse?.data?.data || null);
        setTimeout(() => {
          navigation('/');
          dispatch(signout(body, () => {}));
          setShow(false);
        }, 3000);
      }
    } catch (error) {
      console.error('Error deleting account:', error);
      toast.error('Failed to delete the account. Please try again.');
    }
  };

  return (
    <>
      <span
        onClick={handleShow}
        style={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          gap: 10,
        }}
      >
        Delete Account
      </span>

      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="delete-account-modal-title"
        aria-describedby="delete-account-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: '400px',
            alignItems: 'center',
            p: 3,
          }}
        >
          <Header heading={'Delete Account'} handleClose={handleClose} />

          {isDeleted ? (
            <DisputeDescription sx={{ textAlign: 'center', my: 2 }}>
              Account Deleted successfully! logging out...
            </DisputeDescription>
          ) : isConfirming ? (
            <>
              <DisputeDescription sx={{ my: 2 }}>
                Are you sure you want to delete your account? This action is <b>non-reversible</b>.
                You will lose access to your account, and your subscription will be permanently
                canceled.
              </DisputeDescription>
              <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
                <RedButton variant="contained" onClick={() => handleDelete(user?._id)}>
                  Yes, Delete
                </RedButton>
                <CancelButton onClick={handleClose}>Cancel</CancelButton>
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ textAlign: 'center', my: 2 }}>
                <Avatar
                  sx={{ width: 90, height: 90, mx: 'auto', mb: 1 }}
                  src={user?.image}
                  alt="User Avatar"
                />
                <Description sx={{ fontWeight: 600 }}>
                  {`${user?.firstName} ${user?.lastName}`}
                </Description>
              </Box>

              <Description sx={{ fontWeight: 600, mb: 2 }}>
                {user?.subscription?.status} - {user?.subscription?.title}
              </Description>

              <DisputeDescription sx={{ textAlign: 'center', mb: 2 }}>
                Deleting your account will remove all your data permanently and cancel your
                subscription. This action cannot be undone.
              </DisputeDescription>

              <Box sx={{ width: '100%', mt: 3 }}>
                <RedButton sx={{ width: '100%', mb: 2 }} onClick={() => setIsConfirming(true)}>
                  Proceed to Delete
                </RedButton>
                <CancelButton onClick={handleClose} sx={{ width: '100%' }}>
                  Cancel
                </CancelButton>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default DeleteUserModal;
