import api from '../../api';
import toast from 'react-hot-toast';
import { config } from '../../utills';
import { signSuccess, failedAuth, saveData } from './reducer';
import { updateSuccess, logout, failed, startLoading, stopLoading } from './reducer';
import { FireStore } from '../../utills/FireStore';
import { searchFunction } from '../trip/action';
export const apiCall = (type, id, dispatch) => {
  let body = {};
  if (type === 'trips') {
    body['name'] = 'trips';
    body['url'] = `/trip?hostId=${id}`;
  } else if (type === 'photos') {
    body['name'] = 'photos';
    body['url'] = `/trip/getPhotos/${id}`;
  } else if (type === 'reviews') {
    body['name'] = 'reviews';
    body['url'] = `/user/reviews/${id}`;
  } else if (type === 'following') {
    body['name'] = 'following';
    body['url'] = `/user/following/${id}`;
  } else if (type === 'followers') {
    body['name'] = 'follower';
    body['url'] = `/user/followers/${id}`;
  } else if (type === 'profileInfo') {
    body['name'] = 'profileInfo';
    body['url'] = `/user/getUser/${id}`;
  }
  dispatch(getFunction(body));
};
export const createAccount = (body, navigate) => async (dispatch) => {
  dispatch(startLoading());
  await api
    .post(`/user`, body, config)
    .then((res) => {
      dispatch(signSuccess(res.data));
      dispatch(stopLoading());
      navigate();
    })
    .catch((err) => {
      if (err.response) {
        toast.error(err.response.data.message, { duration: 4000 });
        dispatch(stopLoading());
        dispatch(failedAuth(err));
      }
    });
};
export const updateAccount = (body, navigate, page, showToast = false) => async (dispatch, getState) => {
  const currentState = getState().auth;
  dispatch(startLoading());
  await api
    .put(`/user/web/${currentState?.userInfo?._id}`, body, config)
    .then((res) => {
      dispatch(updateSuccess(res.data));
      if (page == 'editProfilePage') {
        FireStore.updateUserinFirestore(currentState?.userInfo?._id, res?.data?.data);
      }
      dispatch(stopLoading());
      navigate();
      if(showToast){
        toast.success(res.data.message, { duration: 4000 });
      }
    })
    .catch((err) => {
      if (err.response) {
        toast.error(err.response.data.message, { duration: 4000 });
        dispatch(stopLoading());
        dispatch(failedAuth(err));
      }
    });
};
export const forgetPassword = (body, handleStep) => async (dispatch) => {
  dispatch(startLoading());
  await api
    .put(`/user/${body.url}`, body, config)
    .then((res) => {
      if (res.status === 200) {
        toast.success(res.data.message, { duration: 4000 });
        dispatch(stopLoading());
        handleStep();
      }
    })
    .catch((err) => {
      if (err.response) {
        toast.error(err.response.data.message, { duration: 4000 });
        dispatch(stopLoading());
        // dispatch(failedAuth(err));
      }
    });
};
export const getUserInfo = (id) => async (dispatch) => {
  await api
    .get(`/user?_id=${id}`, config)
    .then((data) => {
      dispatch(saveData({ name: 'userInfo', data: data.data.data[0] }));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(failed(err));
        dispatch(logout());
        dispatch(saveData({ name: 'confirmedTrips', data: [] }));
        dispatch(saveData({ name: 'conversation', data: [] }));
        dispatch(saveData({ name: 'count', data: [] }));
        dispatch(saveData({ name: 'follower', data: [] }));
        dispatch(saveData({ name: 'following', data: [] }));
        dispatch(saveData({ name: 'notifications', data: [] }));
        dispatch(saveData({ name: 'profileInfo', data: [] }));
        dispatch(saveData({ name: 'sent', data: [] }));
        dispatch(saveData({ name: 'trip', data: [] }));
        dispatch(saveData({ name: 'trips', data: [] }));
        dispatch(saveData({ name: 'reviews', data: [] }));
        dispatch(saveData({ name: 'received', data: [] }));
        dispatch(stopLoading());
      }
    });
};
export const get = (body) => async (dispatch) => {
  await api
    .get(body.url, config)
    .then((data) => {
      dispatch(saveData({ name: body.name, data: data.data[body.name] || data.data.data }));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(failed(err));
      }
    });
};
export const getFunction = (body) => async (dispatch, getState) => {
  dispatch(startLoading());
  await api
    .get(body.url, config)
    .then((data) => {
      if (body.name === 'notifications') {
        dispatch(saveData({ name: body.name, data: data.data[body.name] || data.data.data }));
        dispatch(saveData({ name: 'count', data: data.data.count }));
      } else {
        dispatch(
          saveData({
            name: body.name,
            data: data.data[body.name] || data.data.data || data.data.doc,
          })
        );
      }
      dispatch(stopLoading());
    })
    .catch((err) => {
      if (err.response) {
        if (body.name === 'notifications') {
          dispatch(saveData({ name: body.name, data: [] }));
          dispatch(saveData({ name: 'count', data: [{ read: 0, unRead: 0 }] }));
          dispatch(stopLoading());
        } else {
          dispatch(saveData({ name: body.name, data: [] }));
          dispatch(stopLoading());
        }
      }
    });
};

export const getFunctionWithoutLoading = (body) => async (dispatch, getState) => {
  await api
    .get(body.url, config)
    .then((data) => {
      dispatch(
        saveData({ name: body.name, data: data.data[body.name] || data.data.data || data.data.doc })
      );
    })
    .catch((err) => {
      if (err.response) {
        dispatch(saveData({ name: body.name, data: [] }));
      }
    });
};
export const updateFuntion = (body) => async (dispatch) => {
  dispatch(startLoading());
  await api
    .put(body.url, body, config)
    .then((res) => {
      dispatch(stopLoading());
    })
    .catch((err) => {
      if (err.response) {
        toast.error(err.response.data.message, { duration: 4000 });
        dispatch(stopLoading());
      }
    });
};
export const updateFuntionTest = (body, secondFunc) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const res = await api.put(body.url, body, config);
    if (res.status == 200) {
      await secondFunc();
    }
    dispatch(stopLoading());
  } catch (err) {
    if (err.response) {
      toast.error(err.response.data.message, { duration: 4000 });
    }
    dispatch(stopLoading());
  }
};

export const postFuntion = (body, navigate) => async (dispatch) => {
  dispatch(startLoading());
  await api
    .post(body.url, body, config)
    .then((res) => {
      dispatch(stopLoading());
      navigate();
    })
    .catch((err) => {
      if (err.response) {
        toast.error(err.response.data.message, { duration: 4000 });
        dispatch(stopLoading());
      }
    });
};
export const _updateFuntion = (body, handle) => async (dispatch) => {
  dispatch(startLoading());
  await api
    .put(body.url, body, config)
    .then((res) => {
      dispatch(stopLoading());
      handle();
    })
    .catch((err) => {
      if (err.response) {
        toast.error(err.response.data.message, { duration: 4000 });
        dispatch(stopLoading());
      }
    });
};
export const deleteFuntion = (body, handle) => async (dispatch) => {
  dispatch(startLoading());
  await api
    .delete(body.url, body, config)
    .then((res) => {
      dispatch(stopLoading());
      handle();
    })
    .catch((err) => {
      if (err.response) {
        toast.error(err.response.data.message, { duration: 4000 });
        dispatch(stopLoading());
      }
    });
};
export const _postFuntion = (body, handle) => async (dispatch) => {
  dispatch(startLoading());
  await api
    .post(body.url, body, config)
    .then((res) => {
      dispatch(stopLoading());
      handle();
    })
    .catch((err) => {
      if (err.response) {
        toast.error(err.response.data.message, { duration: 4000 });
        dispatch(stopLoading());
      }
    });
};
export const signIn = (body, navigate) => async (dispatch) => {
  dispatch(startLoading());
  await api
    .post(`/user/login`, body, config)
    .then((res) => {
      dispatch(signSuccess(res.data));
      apiCall('trips', res.data.doc._id, dispatch);
      apiCall('photos', res.data.doc._id, dispatch);
      apiCall('reviews', res.data.doc._id, dispatch);
      apiCall('following', res.data.doc._id, dispatch);
      apiCall('followers', res.data.doc._id, dispatch);
      apiCall('profileInfo', res.data.doc._id, dispatch);
      dispatch(stopLoading());
      navigate('/search');
    })
    .catch((err) => {
      if (err.response) {
        toast.error(err.response.data.message, { duration: 4000 });
        dispatch(stopLoading());
        dispatch(failedAuth(err));
      }
    });
};
export const reload = (id, navigate) => async (dispatch) => {
  dispatch(startLoading());
  apiCall('trips', id, dispatch);
  apiCall('photos', id, dispatch);
  apiCall('reviews', id, dispatch);
  apiCall('following', id, dispatch);
  apiCall('followers', id, dispatch);
  apiCall('profileInfo', id, dispatch);
  dispatch(stopLoading());
  dispatch(searchFunction(''));
  if(navigate){
    navigate('/search');
  }
};
export const signout = (body, navigate) => async (dispatch) => {
  dispatch(startLoading());
  dispatch(logout());
  dispatch(saveData({ name: 'confirmedTrips', data: [] }));
  dispatch(saveData({ name: 'conversation', data: [] }));
  dispatch(saveData({ name: 'count', data: [] }));
  dispatch(saveData({ name: 'follower', data: [] }));
  dispatch(saveData({ name: 'following', data: [] }));
  dispatch(saveData({ name: 'notifications', data: [] }));
  dispatch(saveData({ name: 'profileInfo', data: [] }));
  dispatch(saveData({ name: 'photos', data: [] }));
  dispatch(saveData({ name: 'inbox', data: [] }));
  dispatch(saveData({ name: 'sent', data: [] }));
  dispatch(saveData({ name: 'trip', data: [] }));
  dispatch(saveData({ name: 'trips', data: [] }));
  dispatch(saveData({ name: 'reviews', data: [] }));
  dispatch(saveData({ name: 'received', data: [] }));
  navigate('/');
  dispatch(stopLoading());
};
