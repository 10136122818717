import React from 'react';
import moment from 'moment';
import { Grid, Box } from '@mui/material';
import { DateWithoutFormat } from '../../../utills/constants';
import { CustomLabel } from '../../../components/InputFields/Elements';
import { ActivityDetail, ActivityHeading } from '../../Elements';

const TripInfo = ({ tripDetails }) => {
  const getTripAvailabilityFormat = (data) => {
    let favlbl = '';
    const sd = DateWithoutFormat(data?.availableFrom);
    const sdy = parseInt(new Date(sd).getFullYear());
    const ed = DateWithoutFormat(data?.availableTo);
    const edy = parseInt(new Date(ed).getFullYear());

    if (sdy == edy) {
      favlbl = moment.utc(sd).format('MMM DD') + ' - ' + moment.utc(ed).format('MMM DD, YYYY');
    } else {
      favlbl =
        moment.utc(sd).format('MMM DD, YYYY') + ' - ' + moment.utc(ed).format('MMM DD, YYYY');
    }
    return favlbl;
  };
  const data = [
    {
      title: 'Title',
      description: tripDetails?.title,
    },
    {
      title: 'Location',
      description: `${tripDetails?.location?.city}, ${tripDetails?.location?.state}`,
    },
    {
      title: 'Trade Type',
      description: tripDetails?.tradeType,
    },
    {
      sm: '12',
      md: '12',
      title: 'Using',
      description: (
        <Box style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 0.2 }}>
          {tripDetails?.tradeOptions?.length > 0
            ? tripDetails?.tradeOptions?.map((option) => {
                return <img src={option?.image} key={option?._id} width="20" height="20" />;
              })
            : 'N/A'}
        </Box>
      ),
    },
    {
      title: 'Category',
      description: tripDetails?.category,
    },
    {
      title: 'Species',
      description: tripDetails?.species,
    },
    {
      title: 'Trip Duration',
      description: `${tripDetails?.duration?.value} ${tripDetails?.duration?.title}`,
    },
    {
      title: 'In Return For',
      description: tripDetails?.returnActivity,
    },
    {
      title: 'Trip Availability',
      description: getTripAvailabilityFormat(tripDetails),
    },
    {
      sm: '12',
      md: '12',
      title: 'Trip Note',
      description: tripDetails?.tripNotes || 'N/A',
    },
  ];

  return (
    <>
      <Grid container>
        {data.map((item, index) => (
          <Grid item xs={item?.xs || 12} sm={item?.sm || 6} md={item?.md || 4} key={index}>
            {/* <CustomLabel
              sx={{ fontFamily: 'Poppins-Semibold', fontWeight: '600', marginBottom: 0 }}
            >
              {item.title}
            </CustomLabel> */}
            <ActivityHeading sx={{ textTransform: 'capitalize' }}>{item.title}</ActivityHeading>
            <ActivityDetail sx={{ mb: 1.5 }}> {item.description} </ActivityDetail>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default TripInfo;
