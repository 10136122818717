import React, { useState } from "react";
import * as yup from "yup";

import Sidebar from "../../components/Sidebar"
import Loader from "../../assets/loader1.gif"

import { useForm } from "react-hook-form";
import { Option } from "../MakeOffer/Elements";
import { MainBox, UserName } from "../Elements";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { postFuntion } from "../../features/auth/action"
import { useMediaQuery, Grid, TextField } from "@mui/material"
import { dropdownStyles } from "../../components/InputFields/styles"
import { FormBox, Edit, LoaderSpan, InstructionsSpan } from "./Elements"
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import { FullTextAreaWithRegister, Input } from "../../components/InputFields/Input"
import { CustomAutocomplete, CustomLabel2 } from "../../components/InputFields/Elements";

// const noOnlyWhitespace = yup
//   .string()
//   .test("no-only-whitespace", "Field cannot contain only spaces", value => value.trim().length > 0);

const schema = yup.object().shape({
    topic: yup.string().trim().required("Please select a topic."),
    subject: yup.string().trim().required("In a few words, what is your request about?"),
    description: yup.string().trim().required("Please describe the details of your request."),
})
const Support = () => {
    const matches600 = useMediaQuery('(max-width:600px)');
    const classes = dropdownStyles();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { register, handleSubmit, formState: { errors },setValue, trigger } = useForm({ resolver: yupResolver(schema) });
    const [loading, setLoading] = useState(false)
    const [topic, setTopic] = useState('Account & Profile')
    const { userInfo } = useSelector(state => state.auth)

    const handleTopicChange = (e, value) => {
        setTopic(value);
        setValue('topic', value || "");
        trigger('topic'); 
    };
    const navigationFunction = () => {
        setLoading(false);
        navigate('/support/success');
    }
    const submitFunction = async (formdata) => {
        formdata['url'] = '/support'
        formdata['email'] = userInfo?.email
        formdata['topic'] = topic
        setLoading(true)
        await dispatch(postFuntion(formdata, navigationFunction))
    }

    return <MainBox sx={{ marginTop: matches600 ? 6 : 0, height: '100vh' }} >
        <Sidebar />
        <FormBox sx={{ width: '40%' }}>
            <form onSubmit={handleSubmit(submitFunction)}>
                <UserName sx={{ fontSize: '18px' }}>Member Support</UserName>
                <InstructionsSpan >Please describe the details of your request below. We will respond as soon as possible (usually 1-3 business days).</InstructionsSpan>
                <Grid container spacing={matches600 ? 0 : 1}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <CustomLabel2>{'Select a Topic'}</CustomLabel2>
                        <CustomAutocomplete
                            disablePortal
                            filterOptions={(options, state) => options}
                            renderOption={(props, option) => {
                                return (<Option {...props}>{option}</Option>);
                            }}
                            onChange={handleTopicChange}
                            id="combo-box-demo"
                            options={[
                                'Account & Profile',
                                'ID Verification',
                                'Messaging',
                                'Privacy & Security',
                                'Trips & Trades',
                                'General Inquiry',
                                'Other'
                            ]}
                            defaultValue={'Account & Profile'}
                            popupIcon={<KeyboardArrowDownIcon />}
                            renderInput={(params) =>
                                <TextField {...params}
                                    sx={{ py: 0 }}
                                    className={classes.root}
                                    error={!!errors.topic}
                                    helperText={errors.topic?.message}
                                    InputProps={{
                                        ...params.InputProps,
                                    }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ my: 2 }}>
                        <Input placeholder={'Example: Central NC Whitetail Hunting'} error={errors.subject?.message} register={register} registerFor="subject" title={<>Subject <span style={{ color: '#B93B3B' }}>*</span></>} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <CustomLabel2>{<>Description <span style={{ color: '#B93B3B' }}>*</span></>}</CustomLabel2>
                        <FullTextAreaWithRegister register={register} registerFor="description" placeholder={'How can we help?'} error={errors.description?.message} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <InstructionsSpan sx={{ color: 'rgba(17, 17, 17, 0.6)', my: 0 }} >Please do not include sensitive personal information.</InstructionsSpan>

                    </Grid>
                </Grid>
                <Grid sx={{ display: 'flex', flexDirection: 'row', mt: 5, mb: 1, justifyContent: 'flex-start' }}>
                    <Edit type="submit" variant="contained" disableripple sx={{ marginRight: 2, px: { lg: 4 } }} >
                        {loading ? <LoaderSpan><img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} />Submit</LoaderSpan> : 'Submit'}
                    </Edit>
                </Grid>
            </form>
        </FormBox>
    </MainBox >
}
export default Support