import React from 'react';

import { Box, Modal } from '@mui/material';
import { style } from "./Elements"
import { CenterHeader } from './ModalHeader';
import { getFullName } from '../../utills/constants';
const PhotoModal = ({ data, open, handle }) => {
    const handleClose = () => handle('openPhoto', false);

    return (
      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              ...style,
              width: 550,
              height: 'max-content',
              py: 2,
              px: 2,
              alignItems: 'flex-start',
            }}
          >
            <CenterHeader heading={getFullName(data)} handleClose={handleClose} />

            <div
              style={{
                width: '100%',
                height: '420px',
                marginTop: 15,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'black',
                borderRadius:5
              }}
            >
              <img
                src={data?.image}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'scale-down',
                  imageRendering: 'auto',
                }}
              />
            </div>
          </Box>
        </Modal>
      </div>
    );
}

export default PhotoModal