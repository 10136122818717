import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import UserCard from './components/UserCard';

const PaymentCard = ({ card, primaryCard, setSelectedCard, StripeData }) => {
  const [changeCardModal, setChangeCardModal] = useState(false);

  return (
    <>
      {/* SELECTED CARD */}
      <UserCard
        card={card}
        onChangeCard={() => setChangeCardModal(true)}
        buttonText="Change Card"
        primaryCard={primaryCard}
      />

      {/* MODAL */}
      <Dialog maxWidth={'md'} open={changeCardModal} onClose={() => setChangeCardModal(false)}>
        <DialogTitle style={{ textAlign: 'end' }}>
          <span
            onClick={() => setChangeCardModal(false)}
            style={{ textAlign: 'end', cursor: 'pointer' }}
          >
            &#10005;
          </span>
        </DialogTitle>
        <DialogContent>
          {StripeData?.map((el, index) => {
            const isSelected = el?.id === card?.id;
            return (
              <UserCard
                key={index}
                isSelected={isSelected}
                card={el}
                buttonText={isSelected ? 'Selected' : 'Select Card'}
                onChangeCard={() => {
                  setSelectedCard(el);
                  setChangeCardModal(false);
                }}
                primaryCard={primaryCard}
              />
            );
          })}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PaymentCard;
