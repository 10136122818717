import React, { useEffect, useState } from 'react';

import Inbox from './Inbox';
import InboxMobile from './InboxMobile';
import Conversation from './Conversation';
import Sidebar from '../../components/Sidebar';
import ConversationMobile from './ConversationMobile';

import { MainBox } from './Elements';
import { useMediaQuery, Grid } from '@mui/material';
import { FireStore } from '../../utills/FireStore';
import { saveData } from '../../features/auth/reducer';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

const Messages = () => {
  const dispatch = useDispatch();

  const matches600 = useMediaQuery('(max-width:600px)');

  const { userInfo, inbox = [] } = useSelector((state) => state.auth);
  const location = useLocation();
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search);
  const userId1 = queryParams?.get('userId1') || "";

  const [state, setState] = useState({ isMobile: false, selectedChat: {}, messages: [] });

  const handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const [data, setData] = useState([]);
  
  const isUser = data?.find((user) => user?.userId1?._id === userId1);
  useEffect(() => {
    if (isUser) {
      let user;

      if (userInfo?._id === isUser?.userId1?._id) {
        user = isUser?.userId2;
      } else if (userInfo?._id === isUser?.userId2?._id) {
        user = isUser?.userId1;
      }
      handleChange('selectedChat', isUser);
      handleChange('receiverId', user?._id);
      handleChange('receiverFollowers', user?.followers);
      navigate('/messages', { replace: true });
    }
  }, [isUser]);
  
  const getInbox = async () => {
    const inbox = await FireStore.getAllCurrentUserRooms(userInfo?._id);
    // console.log('inbox INSIDE: ==================', inbox);
    dispatch(saveData({ name: 'inbox', data: inbox }));
    setData([...inbox]);
  };

  // console.log('inbox OUTSIDE: ==================', inbox);
  useEffect(() => {
    setData([...inbox]);
    // eslint-disable-next-line
  }, [inbox]);

  useEffect(() => {
    getInbox();
    // eslint-disable-next-line
  }, []);
  return (
    <MainBox sx={{ marginTop: matches600 ? 6 : 0 }}>
      <Sidebar />
      {matches600 ? (
        <Grid container>
          {!state?.isMobile ? (
            <InboxMobile
              getInbox={getInbox}
              parentState={state}
              handleParentChange={handleChange}
            />
          ) : (
            <ConversationMobile
              getInbox={getInbox}
              parentState={state}
              handleParentChange={handleChange}
            />
          )}
        </Grid>
      ) : (
        <>
          <Grid container>
            <Inbox
              data={data}
              setData={setData}
              getInbox={getInbox}
              parentState={state}
              handleParentChange={handleChange}
            />
            <Conversation
              getInbox={getInbox}
              parentState={state}
              handleParentChange={handleChange}
            />
          </Grid>
        </>
      )}
    </MainBox>
  );
};
export default Messages;
