import React, { useState, useEffect } from 'react';

import api from '../../../api';
import ProfileBox from './ProfileBox';
import FollowersBox from './FollowersBox';
import Sidebar from '../../../components/Sidebar';
import GreenLoader from '../../../assets/greenLoader.gif';

import { useNavigate, useParams } from 'react-router-dom';
import { config, getUserStatus, swalWithStyledButton } from '../../../utills/constants';
import { Box, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getFunction } from '../../../features/profile/action';
import { LeftGrid, RightGrid, MainGrid } from '../Private/Elements';
import { reload } from '../../../features/auth/action';
import '../style.css';
import { saveData, startLoading, stopLoading } from '../../../features/profile/reducer';
import FullPageLoader from '../../../components/common/FullPageLoader';

const Public = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const matches600 = useMediaQuery('(max-width:600px)');

  const [state, setState] = useState({ mobileOpen: false, isRefrsh: false });
  const [isLoadingApis, setIsLoadingApis] = useState(true);

  const { userInfo } = useSelector((state) => state.auth);

  const { loading, profileInfo } = useSelector((state) => state.profile);

  const handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleDrawerToggle = () => {
    handleChange('mobileOpen', !state.mobileOpen);
  };

  // const getReviews = async (body) => {
  //   dispatch(saveData({ name: body.name, data: [] }));
  //   dispatch(startLoading());
  //   await api
  //     .get(body.url, config)
  //     .then((data) => {
  //       dispatch(
  //         saveData({
  //           name: body.name,
  //           data: data.data,
  //         })
  //       );
  //       dispatch(stopLoading());
  //     })
  //     .catch((err) => {
  //       if (err.response) {
  //         dispatch(saveData({ name: body.name, data: [] }));
  //         dispatch(stopLoading());
  //       }
  //     });
  // };

  const apiCall = (type) => {
    let body = {};
    if (type === 'trips') {
      body['name'] = 'trips';
      body['url'] = `/trip?hostId=${id}&currentUserId=${userInfo?._id}`;
    } else if (type === 'photos') {
      body['name'] = 'photos';
      body['url'] = `/trip/getPhotos/${id}`;
    } else if (type === 'reviews') {
      body['name'] = 'reviews';
      body['url'] = `/user/reviews/${id}?accessible_user_id=${userInfo?._id}`;
    } else if (type === 'followers') {
      body['name'] = 'follower';
      body['url'] = `/user/followers/${id}`;
    } else if (type === 'following') {
      body['name'] = 'following';
      body['url'] = `/user/following/${id}`;
    } else if (type === 'profileInfo') {
      body['name'] = 'profileInfo';
      body['url'] = `/user/getUser/${id}`;
    }
    return dispatch(getFunction(body));
  };

  const checkIFBlocked = async () => {
    try {
      setIsLoadingApis(true);
      const blockedByUsers = await api.get(`/user/blockedByUsers/${userInfo?._id}`, config);
      const hasId = blockedByUsers?.data?.blockedBy.some((item) => item?.userId?._id == id);
      if (hasId) {
        swalWithStyledButton
          .fire({
            icon: 'error',
            allowOutsideClick: false,
            allowEscapeKey: false,
            text: `This user has blocked you.`,
            confirmButtonText: 'Reload',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          })
          .then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              // dispatch(reload(userInfo?._id, navigate));
              navigate('/search');
            } else if (result.isDenied) {
              navigate('/search');
              // dispatch(reload(userInfo?._id, navigate));
            }
          });

        return;
      }

      const result = await Promise.all([
        apiCall('profileInfo'),
        apiCall('photos'),
        apiCall('trips'),
        apiCall('followers'),
        apiCall('following'),
        apiCall('reviews'),
      ]);
      // console.log('result: ', result);
    } catch (error) {
      console.error('error in checkIFBlocked: ', error);
    } finally {
      setIsLoadingApis(false);
    }
  };

  useEffect(() => {
    checkIFBlocked();
    // eslint-disable-next-line
  }, [id]);

  return (
    <Box sx={{ marginTop: matches600 ? 6 : 0, display: 'flex', background: '#F9F9F9' }}>
      <Sidebar />
      <MainGrid container spacing={2} sx={{ position: 'relative' }}>
        {loading || isLoadingApis ? (
          <FullPageLoader
            isLoading={loading || isLoadingApis}
            width="80"
            background="transparent"
          />
        ) : getUserStatus(profileInfo) === 'active' ? (
          <>
            <LeftGrid item xs={12} sm={12} md={12} lg={7}>
              <ProfileBox
                handleChange={handleChange}
                apiCall={apiCall}
                handleDrawerToggle={handleDrawerToggle}
              />
            </LeftGrid>
            <RightGrid item sm={12} md={3} lg={4}>
              <FollowersBox mobileOpen={state.mobileOpen} handleDrawerToggle={handleDrawerToggle} />
            </RightGrid>
          </>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <span
              style={{
                fontFamily: 'Poppins-Light',
                fontSize: 14,
              }}
            >
              Sorry, Profile Doesn't Exist !
            </span>
          </div>
        )}
      </MainGrid>
    </Box>
  );
};
export default Public;
