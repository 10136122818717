import React from 'react';
import { Grid, Typography, Button, Chip } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const UserCard = ({ card, onChangeCard, primaryCard, isSelected, buttonText }) => {
  const getExpiry = (card) => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    const isExpired =
      card?.card?.exp_year < currentYear ||
      (card?.card?.exp_year === currentYear && card?.card?.exp_month < currentMonth);
    return isExpired;
  };

  const getPrimary = (card) => {
    const isPrimary = card?.id === primaryCard?.id;
    return isPrimary;
  };
  return (
    <Grid
      container
      sx={{
        margin: `5px 0px`,
        padding: 1,
        borderRadius: 2,
        boxShadow: 3,
        backgroundColor: getExpiry(card) ? '#f8d7da' : '#fff',
        border: isSelected ? '3px solid green' : '',
      }}
    >
      <Grid item xs={6} display="flex" alignItems="center">
        {card?.billing_details?.name ? card?.billing_details?.name : 'Card'}
      </Grid>
      <Grid item xs={6} display="flex" justifyContent="flex-end">
        <Button type="button" size="small" onClick={onChangeCard} disabled={isSelected}>
          {buttonText}
        </Button>
      </Grid>
      <Grid item xs={12} sm={8} display="flex" alignItems="center">
        <CreditCardIcon sx={{ marginRight: 1 }} />
        <Typography sx={{ fontSize: 'clamp(16px,5vw,25px)', letterSpacing: 2 }}>
          **** **** **** {card?.card?.last4}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} display="flex" alignItems="center" justifyContent="flex-end">
        <Typography variant="subtitle2" color="textSecondary" sx={{ fontSize: 14 }}>
          Expiry: {String(card?.card?.exp_month).padStart(2, '0')}/{card?.card?.exp_year}
        </Typography>
      </Grid>
      <Grid item xs={6} display="flex" alignItems="center">
        {getExpiry(card) ? (
          <ErrorOutlineIcon fontSize="small" sx={{ marginRight: 1, color: 'red' }} />
        ) : (
          <CheckCircleIcon fontSize="small" sx={{ marginRight: 1, color: 'green' }} />
        )}
        <Typography variant="subtitle2" color="textSecondary" sx={{ fontSize: 14 }}>
          {getExpiry(card) ? 'Expired' : 'Active'}
        </Typography>
      </Grid>
      <Grid item xs={6} display="flex" justifyContent="flex-end">
        {getPrimary(card) && <Chip label="Primary" color="success" size="small" />}
      </Grid>
    </Grid>
  );
};

export default UserCard;
