import React, { useState } from 'react';
import api from '../../api';
import toast from 'react-hot-toast';
import Loader from '../../assets/loader1.gif';

import { useNavigate } from 'react-router-dom';
import { LoaderSpan } from '../SignIn/Elements';
import { Grid, Avatar, Box } from '@mui/material';
import { reload } from '../../features/auth/action';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from '../../components/Modal/ModalHeader';
import { HeadingSub, GreyText, TripDetailsGrid } from './Elements';
import { FullTextArea } from '../../components/InputFields/Input';
import { config, getSubsctiptionStatus, swalWithStyledButton } from '../../utills/constants';
import { startLoading, stopLoading } from '../../features/auth/reducer';
import { TransparentBtn, ActivityDetail, ActivityHeading, GreenButton } from '../Elements';
import {
  getDurationFromDate,
  getFullName,
  capitalize,
  modifyDurationTitle,
} from '../../utills/constants';
import { FreeUserRestriction } from '../../components/Modal/FreeUser';
import { freemiumMakeOfferTrip } from '../../features/trip/action';
import moment from 'moment';

const Step3 = ({ handle, handleClose, handleChange, state, parentState }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [isOfferSent, setIsOfferSent] = useState(false);

  const { userInfo } = useSelector((state) => state.auth);
  const { hostOffer } = state;
  const { selectedTrip } = parentState;

  const getUnAvailableDays = () => {
    const a = state?.hostOffer?.unAvailableDays?.dayWeekText || '';
    const b = state?.hostOffer?.unAvailableDays?.excludeDateText || '';
    return a + ' ' + b;
  };
  const submitFunction = async () => {
    setLoading(true);
    const subsStatus = await getSubsctiptionStatus(userInfo?.customerId);
    dispatch(startLoading());
    setIsOfferSent(true);
    // console.log('subsStatus', subsStatus);
    let title = '';
    let dt = '';
    let dtitle = modifyDurationTitle(
      state?.hostOffer?.durationTitle || state?.hostOffer?.duration.title,
      state?.hostOffer?.durationValue
    );
    dt = (state?.hostOffer?.durationValue || state?.hostOffer?.duration.value) + ' ' + dtitle;
    title = dt + ' ' + state?.hostOffer?.species;
    let allUnavailableDates = [],
      unavailableDaysOfWeek = [],
      excludeSpecificDates = [];
    state?.hostOffer?.unAvailableDays?.allUnavailableDates?.length > 0 &&
      state?.hostOffer?.unAvailableDays?.allUnavailableDates.forEach((res) => {
        const utcFormat = moment.utc(res.month + '/' + res.day + '/' + res.year).format();
        const dateFormat = moment.utc(utcFormat).format('YYYY-MM-DD');
        allUnavailableDates.push(dateFormat);
      });
    state?.hostOffer?.unAvailableDays?.unavailableDaysOfWeek?.length > 0 &&
      state?.hostOffer?.unAvailableDays?.unavailableDaysOfWeek.forEach((res) => {
        const utcFormat = moment.utc(res.month + '/' + res.day + '/' + res.year).format();
        const dateFormat = moment.utc(utcFormat).format('YYYY-MM-DD');
        unavailableDaysOfWeek.push(dateFormat);
      });
    state?.hostOffer?.unAvailableDays?.excludeSpecificDates?.length > 0 &&
      state?.hostOffer?.unAvailableDays?.excludeSpecificDates.forEach((res) => {
        const utcFormat = moment.utc(res.month + '/' + res.day + '/' + res.year).format();
        const dateFormat = moment.utc(utcFormat).format('YYYY-MM-DD');
        excludeSpecificDates.push(dateFormat);
      });

    const unAvailableDays = {
      dayWeekText: state?.hostOffer?.unAvailableDays.dayWeekText,
      excludeDateText: state?.hostOffer?.unAvailableDays.excludeDateText,
      daysOfWeek: state?.hostOffer?.unAvailableDays.excludeDateText,
      unavailableDaysOfWeek: unavailableDaysOfWeek,
      excludeSpecificDates: excludeSpecificDates,
      allUnavailableDates: allUnavailableDates,
      repeatEvery: {
        endRepeatOn: new Date(),
        value: 1,
        title: 'weeks',
      },
    };

    const obj = { ...state?.hostOffer };
    obj.availableFrom = moment.utc(obj.availableFrom).format('YYYY-MM-DD');
    obj.availableTo = moment.utc(obj.availableTo).format('YYYY-MM-DD');

    let body = {
      url: '/offer',
      name: 'offer',
      preferredDates: state.preferredDates,
      offeredBy: userInfo?._id,
      offeredTo: selectedTrip?.hostId?._id,
      note: state.note,
      tripDates: state.preferredDates,
      hostTrip: {
        ...obj,
        tripId: state?.hostOffer?._id,
        title: title,
        unAvailableDays: unAvailableDays,
      },
      offeredTrip: { ...selectedTrip, tripId: selectedTrip?._id },
    };

    console.log('make offer', body);
    if (subsStatus !== 'Premium' && subsStatus !== 'PremiumC') {
      dispatch(freemiumMakeOfferTrip(body));
      navigate('/freePlan');
      setIsOfferSent(false);
      setLoading(false);
      return;
    }
    await api
      .post(body.url, body, config)
      .then((resp) => {
        if (
          resp?.data?.message == `This trip doesn't exist.` ||
          resp?.data?.message == `This user has blocked you.` ||
          resp?.data?.message == `This trip has been suspended.` ||
          resp?.data?.message == `This user has been deleted.`
        ) {
          handle('openMakeOffer', false);
          swalWithStyledButton
            .fire({
              icon: 'error',
              allowOutsideClick: false,
              allowEscapeKey: false,
              text: resp?.data?.message,
              confirmButtonText: 'Reload',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            })
            .then((result) => {
              dispatch(stopLoading());
              if (result.isConfirmed) {
                dispatch(reload(userInfo?._id, navigate));
              } else if (result.isDenied) {
                dispatch(reload(userInfo?._id, navigate));
              }
            });
        } else {
          dispatch(stopLoading());
          handleChange('step', 5);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, { duration: 4000 });
          dispatch(stopLoading());
        }
      });
    setTimeout(() => {
      setLoading(false);
      setIsOfferSent(false);
    }, 1000);
  };

  return (
    <>
      <Header handleClose={handleClose} heading="Make Offer" />
      <ActivityHeading sx={{ mt: 2, mb: 1 }}>IN RETURN FOR</ActivityHeading>
      <TripDetailsGrid container sx={{ background: '#F5F5F5', border: 'none' }}>
        <Grid item xs={4} lg={3} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Avatar src={selectedTrip?.hostId?.image} alt="useer" sx={{ width: 70, height: 70 }} />
        </Grid>
        <Grid
          item
          xs={8}
          lg={9}
          sx={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }}
        >
          <HeadingSub
            sx={{ fontSize: { xs: '13px', sm: '15px', md: '16px', lg: '16px' }, fontWeight: 600 }}
          >{`${selectedTrip?.species} ${selectedTrip?.tradeType}`}</HeadingSub>
          <HeadingSub sx={{ fontSize: { xs: '10px', sm: '10px', md: '12px', lg: '12px' } }}>
            Duration:{' '}
            {`${selectedTrip?.duration?.value} ${modifyDurationTitle(
              selectedTrip?.duration?.title,
              selectedTrip?.duration?.value
            )}`}
          </HeadingSub>
          <GreyText sx={{ fontSize: { xs: '10px' } }}>
            Hosted by {getFullName(selectedTrip?.hostId)}
          </GreyText>
        </Grid>
      </TripDetailsGrid>
      <Grid container sx={{ width: '100%', my: 1, maxHeight:400, overflow:"auto",pr: 1 }}>
        <Grid item xs={12} sx={{ my: 2 }}>
          <ActivityHeading>YOUR OFFERING</ActivityHeading>
          <ActivityDetail sx={{ color: '#101B10' }}>
            {`${hostOffer?.species} ${hostOffer?.tradeType}`}{' '}
          </ActivityDetail>
          <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 0.2, mt: 2 }}>
                    {hostOffer?.tradeOptions?.map((option) => {
                    return <img src={option?.image} key={option?._id} width="20" height="20" />;
                })}
          </div>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} sx={{ mb: 2 }}>
          <ActivityHeading>TRIP LOCATION</ActivityHeading>
          <ActivityDetail sx={{ color: '#101B10' }}>{`${capitalize(hostOffer?.location?.city)} ${
            hostOffer?.location?.state
          }`}</ActivityDetail>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} sx={{ mb: 2 }}>
          <ActivityHeading>TRIP DURATION</ActivityHeading>
          <ActivityDetail sx={{ color: '#101B10' }}>{`${
            hostOffer?.duration?.value
          } ${modifyDurationTitle(
            hostOffer?.duration?.title,
            hostOffer?.duration?.value
          )}`}</ActivityDetail>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} sx={{ mb: 2 }}>
          <ActivityHeading>TRIP Availability</ActivityHeading>
          <ActivityDetail sx={{ color: '#101B10' }}>
            {getDurationFromDate(hostOffer?.availableFrom, hostOffer?.availableTo)}
          </ActivityDetail>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mb: 2 }}>
          <ActivityHeading>UNAVAILABLE DAYS</ActivityHeading>
          <ActivityDetail sx={{ color: '#101B10' }}>{getUnAvailableDays()}</ActivityDetail>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mb: 2 }}>
          <ActivityHeading>TRIP NOTE</ActivityHeading>
          <ActivityDetail sx={{ color: '#101B10' }}>{hostOffer?.tripNotes || "N/A"}</ActivityDetail>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ActivityHeading>OFFER NOTE</ActivityHeading>
          <FullTextArea
            rows={2}
            onChange={(e) => handleChange('note', e.target.value)}
            placeholder={'Optional'}
            title="Note"
          />
        </Grid>
      </Grid>

      <Box
        sx={{
          display: 'flex',
          mt: 2,
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <GreyText sx={{ fontSize: { xs: '12px', sm: '12px', md: '14px', lg: '14px' } }}>
          Step 4 of 4
        </GreyText>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <TransparentBtn
            sx={{ height: '45px', px: { lg: 3 }, mr: 2 }}
            onClick={() => handleChange('step', 7)}
          >
            Edit My Offer
          </TransparentBtn>
          <GreenButton
            disabled={loading}
            onClick={submitFunction}
            type="submit"
            variant={isOfferSent ? 'outlined' : 'contained'}
            disableripple
          >
            {loading ? (
              <LoaderSpan>
                <img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} />
                Sending...
              </LoaderSpan>
            ) : (
              <>
                <p style={{ margin: 0 }}>
                  {userInfo?.subscription?.status === 'freemium' ? 'Subscribe to' : ''}
                  <p style={{ margin: 0 }}>Confirm and Send</p>
                </p>
              </>
            )}
          </GreenButton>
        </Box>
      </Box>
      <FreeUserRestriction open={state.freeUser} handle={handleChange} stateName="freeUser" />
    </>
  );
};

export default Step3;
