import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useMediaQuery, Grid, Avatar } from '@mui/material';
import { FirstMessage } from '../../components/Modal/Message';
import { DeclineBox } from '../../components/Modal/TripModals';
import { RatingBox, RatingCount, ReviewCount } from './Elements';
import { RedButton, ActivityFeatures, ArrowGrid, LeftBox } from './Elements';
import { ActivityHeading, ActivityDetail, CustomAvatar, NameSection } from '../Elements';
import { TransparentBtn, Edit, TripBox, RightBox, TripBoxHeader, CountName } from './Elements';
import { FreeUserRestriction } from '../../components/Modal/FreeUser';
import {
  getDurationFromDate,
  getFullName,
  modifyDurationTitle,
  formatDates,
  getSubsctiptionStatus,
  displaydate,
  getUserProfile,
} from '../../utills/constants';

import ChoosePrefferedDates from './Modal';
import star from '../../assets/home/star.png';
import clock from '../../assets/createTrip/clock.png';
import calender from '../../assets/createTrip/calender.png';
import locationx from '../../assets/createTrip/location.png';
import leftarrow from '../../assets/createTrip/left arrow.png';
import rightarrow from '../../assets/createTrip/right arrow.png';

import './style.css';
import { useSelector } from 'react-redux';

export const CustomTripBox = ({ loading, getTime, res, cancelOffer }) => {
  const navigate = useNavigate();

  const matches600 = useMediaQuery('(max-width:600px)');
  const matches420 = useMediaQuery('(max-width:420px)');
  const tripDatails = [
    {
      icon: clock,
      name: `${res?.hostTrip?.duration?.value} ${modifyDurationTitle(
        res?.hostTrip?.duration?.title,
        res?.hostTrip?.duration?.value
      )}`,
      name2: `${res?.offeredTrip?.duration?.value} ${modifyDurationTitle(
        res?.offeredTrip?.duration?.title,
        res?.offeredTrip?.duration?.value
      )}`,
    },
    {
      icon: calender,
      name: getDurationFromDate(res?.hostTrip?.availableFrom, res?.hostTrip?.availableTo),
      name2: formatDates(res?.preferredDates),
    },
    {
      icon: locationx,
      name: `${res?.hostTrip?.location?.city}, ${res?.hostTrip?.location?.state}`,
      name2: `${res?.offeredTrip?.location?.city}, ${res?.offeredTrip?.location?.state}`,
    },
  ];

  const [state, setState] = useState({
    loading: false,
    openDeclineBox: false,
    openMessage: false,
    openPrefferedDates: false,
  });
  const handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const deleteFunction = () => {
    cancelOffer(res?._id, 'sent', handleChange);
  };

  console.log('TRIPBOX RES: ', res);


  return (
    <TripBox container sx={{ width: '100%', mb: 2 }}>
      <TripBoxHeader item xs={12} sm={12} md={12} lg={12} sx={{ my: 0.6 }}>
        <LeftBox>
          <CustomAvatar src={getUserProfile(res?.offeredTo)} alt="useer" />
          <NameSection onClick={() => navigate(`/profile/public/${res?.offeredTo?._id}`)}>
            <span>{res?.offeredTo ? getFullName(res?.offeredTo) : "Trip Trader User"}</span>
            <RatingBox>
              <img src={star} className="imageShadow" alt="star" />
              <RatingCount>
                {' '}
                {res?.offeredTo?.rating > 0 && res?.offeredTo?.rating}{' '}
              </RatingCount>{' '}
              <ReviewCount> {res?.offeredTo?.reviews} reviews</ReviewCount>
            </RatingBox>
          </NameSection>
        </LeftBox>
        <RightBox>
          <CountName sx={{ color: 'rgba(17, 17, 17, 0.4)' }}>{getTime(res?.createdAt)}</CountName>
        </RightBox>
      </TripBoxHeader>
      <Grid item xs={matches420 ? 12 : 5.5} sm={5} md={5} lg={5} sx={{ mt: 2 }}>
        <ActivityHeading>YOU OFFERED</ActivityHeading>
        <ActivityDetail>
          {res?.hostTrip?.species} {res?.hostTrip?.tradeType}
        </ActivityDetail>
        {matches600 &&
          tripDatails?.map((res, i) => {
            return (
              <ActivityFeatures key={i}>
                <img src={res.icon} alt={res.name} style={{ marginRight: 10 }} />
                {res.name}
              </ActivityFeatures>
            );
          })}
      </Grid>
      <ArrowGrid item xs={matches420 ? 12 : 1} sm={2} md={2} lg={2} sx={{ my: matches420 ? 0 : 2 }}>
        <img src={rightarrow} alt="rightarrow" />
        <img src={leftarrow} alt="leftarrow" />
      </ArrowGrid>
      <Grid item xs={matches420 ? 12 : 5.5} sm={5} md={5} lg={4} sx={{ mt: 2 }}>
        <ActivityHeading>IN RETURN FOR</ActivityHeading>
        <ActivityDetail>
          {res?.offeredTrip?.species} {res?.offeredTrip?.tradeType}
        </ActivityDetail>
        {matches600 &&
          tripDatails?.map((res, i) => {
            return (
              <ActivityFeatures key={i}>
                <img src={res.icon} alt={res.name} style={{ marginRight: 10 }} />
                {res.name}
              </ActivityFeatures>
            );
          })}
      </Grid>
      {!matches600 && (
        <Grid item xs={12} sm={5} md={5} lg={5}>
          {tripDatails?.map((res, i) => {
            return (
              <ActivityFeatures key={i}>
                <img src={res.icon} alt={res.name} style={{ marginRight: 10 }} />
                {res.name}
              </ActivityFeatures>
            );
          })}
        </Grid>
      )}
      {!matches600 && <Grid item xs={12} sm={2} md={2} lg={2} sx={{ my: 2 }}></Grid>}
      {!matches600 && (
        <Grid item xs={12} sm={5} md={5} lg={5}>
          {tripDatails?.map((res, i) => {
            return (
              <ActivityFeatures key={i}>
                <img src={res.icon} alt={res.name} style={{ marginRight: 10 }} />
                {res.name2}
              </ActivityFeatures>
            );
          })}
        </Grid>
      )}
      {res?.note && (
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 1 }}>
          <ActivityHeading>OFFER NOTE</ActivityHeading>
          <ActivityFeatures>{res?.note}</ActivityFeatures>
        </Grid>
      )}
      {res?.status !== 'declined' && !matches600 && (
        <Grid>
          <RedButton sx={{ mt: 2, mb: 1 }} onClick={() => handleChange('openDeclineBox', true)}>
            Cancel Offer
          </RedButton>
        </Grid>
      )}
      {res?.status !== 'declined' && matches600 && (
        <Grid sx={{ width: '100%' }}>
          <RedButton
            sx={{ mt: 2, mb: 1, width: '100%' }}
            onClick={() => handleChange('openDeclineBox', true)}
          >
            Cancel Offer
          </RedButton>
        </Grid>
      )}
      <DeclineBox
        buttonText={'Yes, cancel it now'}
        loading={loading}
        title={res?.offeredTrip?.title}
        text="Cancel Trip"
        deleteFunction={deleteFunction}
        res={res}
        open={state.openDeclineBox}
        handle={handleChange}
        stateName="openDeclineBox"
      />
    </TripBox>
  );
};
export const ReceivedTripBox = ({ loading, apiCall, getTime, res, cancelOffer }) => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);
  const matches600 = useMediaQuery('(max-width:600px)');
  const matches420 = useMediaQuery('(max-width:420px)');

  const [state, setState] = useState({
    openDeclineBox: false,
    openMessage: false,
    openPrefferedDates: false,
    freeUser: false,
  });

  const handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const tripDatails = [
    {
      icon: clock,
      name: `${res?.hostTrip?.duration?.value} ${modifyDurationTitle(
        res?.hostTrip?.duration?.title,
        res?.hostTrip?.duration?.value
      )}`,
      name2: `${res?.offeredTrip?.duration?.value} ${modifyDurationTitle(
        res?.offeredTrip?.duration?.title,
        res?.offeredTrip?.duration?.value
      )}`,
    },
    {
      icon: calender,
      name: displaydate(res?.hostTrip?.availableFrom, res?.hostTrip?.availableTo),
      name2: formatDates(res?.preferredDates),
    },
    {
      icon: locationx,
      name: `${res?.hostTrip?.location?.city}, ${res?.hostTrip?.location?.state}`,
      name2: `${res?.offeredTrip?.location?.city}, ${res?.offeredTrip?.location?.state}`,
    },
  ];

  const openMessageBox = async () => {
    handleChange('selectedUser', res?.offeredBy);
    const subsStatus = await getSubsctiptionStatus(userInfo?.customerId);
    if (subsStatus !== 'Premium' && subsStatus !== 'PremiumC') {
      handleChange('freeUser', true);
      return;
    }
    handleChange('openMessage', true);
  };

  const deleteFunction = () => {
    cancelOffer(res?._id, 'received', handleChange);
  };
  return (
    <>
      <TripBox container sx={{ width: '100%', mb: 2 }}>
        <TripBoxHeader item xs={12} sm={12} md={12} lg={12} sx={{ my: 0.6 }}>
          <LeftBox>
            <Avatar src={getUserProfile(res?.offeredBy)} alt="useer" sx={{ width: 70, height: 70 }} />
            <NameSection onClick={() => navigate(`/profile/public/${res?.offeredBy?._id}`)}>
              <span>{res?.offeredBy ? getFullName(res?.offeredBy) : "Trip Trader User"}</span>
              <RatingBox>
                <img src={star} className="imageShadow" alt="star" />
                <RatingCount>
                  {' '}
                  {res?.offeredBy?.rating > 0 && res?.offeredBy?.rating}{' '}
                </RatingCount>{' '}
                <ReviewCount> {res?.offeredTo?.reviews} reviews</ReviewCount>
              </RatingBox>
            </NameSection>
          </LeftBox>
          <RightBox>
            <CountName sx={{ color: 'rgba(17, 17, 17, 0.4)' }}>{getTime(res?.createdAt)}</CountName>
          </RightBox>
        </TripBoxHeader>
        <Grid item xs={matches420 ? 12 : 5.5} sm={5} md={5} lg={5} sx={{ mt: 2 }}>
          <ActivityHeading>OFFERING</ActivityHeading>
          <ActivityDetail>
            {res?.hostTrip?.species} {res?.hostTrip?.tradeType}
          </ActivityDetail>
          {matches600 &&
            tripDatails?.map((res, i) => {
              return (
                <ActivityFeatures key={i}>
                  <img src={res.icon} alt={res.name} style={{ marginRight: 10 }} />
                  {res.name}
                </ActivityFeatures>
              );
            })}
        </Grid>
        <ArrowGrid
          item
          xs={matches420 ? 12 : 1}
          sm={2}
          md={2}
          lg={2}
          sx={{ my: matches420 ? 0 : 2 }}
        >
          <img src={rightarrow} alt="rightarrow" />
          <img src={leftarrow} alt="leftarrow" />
        </ArrowGrid>
        <Grid item xs={matches420 ? 12 : 5.5} sm={5} md={5} lg={4} sx={{ mt: 2 }}>
          <ActivityHeading>IN RETURN FOR</ActivityHeading>
          <ActivityDetail>
            {res?.offeredTrip?.species} {res?.offeredTrip?.tradeType}
          </ActivityDetail>
          {matches600 &&
            tripDatails?.map((res, i) => {
              return (
                <ActivityFeatures key={i}>
                  <img src={res.icon} alt={res.name} style={{ marginRight: 10 }} />
                  {res.name2}
                </ActivityFeatures>
              );
            })}
        </Grid>
        {!matches600 && (
          <Grid item xs={12} sm={5} md={5} lg={5}>
            {tripDatails?.map((res, i) => {
              return (
                <ActivityFeatures key={i}>
                  <img src={res.icon} alt={res.name} style={{ marginRight: 10 }} />
                  {res.name}
                </ActivityFeatures>
              );
            })}
          </Grid>
        )}
        {!matches600 && <Grid item xs={12} sm={2} md={2} lg={2} sx={{ my: 2 }}></Grid>}
        {!matches600 && (
          <Grid item xs={12} sm={5} md={5} lg={5}>
            {tripDatails?.map((res, i) => {
              return (
                <ActivityFeatures key={i}>
                  <img src={res.icon} alt={res.name} style={{ marginRight: 10 }} />
                  {res.name2}
                </ActivityFeatures>
              );
            })}
          </Grid>
        )}
        {res?.note && (
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 1 }}>
            <ActivityHeading>OFFER NOTE</ActivityHeading>
            <ActivityFeatures>{res?.note}</ActivityFeatures>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{ display: 'flex', mt: 2, mb: 1, justifyContent: 'space-around' }}
        >
          {matches600 ? (
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{
                  display: 'flex',
                  mt: 2,
                  mb: 1,
                  justifyContent: 'space-around',
                }}
              >
                <Edit onClick={() => handleChange('openPrefferedDates', true)}>
                  Choose Trip Date
                </Edit>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={12}
                lg={12}
                sx={{
                  display: 'flex',
                  mt: 2,
                  mb: 1,
                  justifyContent: 'space-around',
                }}
              >
                <RedButton
                  sx={{ mr: 2, width: '100%' }}
                  onClick={() => handleChange('openDeclineBox', true)}
                >
                  Decline
                </RedButton>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={12}
                lg={12}
                sx={{
                  display: 'flex',
                  mt: 2,
                  mb: 1,
                  justifyContent: 'space-around',
                }}
              >
                <TransparentBtn sx={{ width: '100%' }} onClick={openMessageBox}>
                  Message
                </TransparentBtn>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Edit
                  sx={{ width: '95%', mb: '1rem' }}
                  onClick={() => handleChange('openPrefferedDates', true)}
                >
                  Choose Trip Date
                </Edit>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4}>
                <RedButton
                  sx={{ width: '95%' }}
                  onClick={() => handleChange('openDeclineBox', true)}
                >
                  Decline
                </RedButton>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4}>
                <TransparentBtn sx={{ width: '95%' }} onClick={openMessageBox}>
                  Message
                </TransparentBtn>
              </Grid>
            </Grid>
          )}
        </Grid>
      </TripBox>
      <DeclineBox
        buttonText={'Yes, decline it now'}
        loading={loading}
        title={res?.offeredTrip?.title}
        text="Decline Offer"
        deleteFunction={deleteFunction}
        apiCall={apiCall}
        res={res}
        state={state}
        open={state.openDeclineBox}
        handle={handleChange}
        stateName="openDeclineBox"
      />
      <ChoosePrefferedDates
        apiCall={apiCall}
        res={res}
        state={state}
        open={state.openPrefferedDates}
        handle={handleChange}
        stateName="openPrefferedDates"
      />
      <FirstMessage
        data={state}
        open={state.openMessage}
        handle={handleChange}
        stateName="openMessage"
      />
      <FreeUserRestriction open={state.freeUser} handle={handleChange} stateName="freeUser" />
    </>
  );
};
