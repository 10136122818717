import React from 'react';
import moment from 'moment';
import Loader from '../../assets/loader1.gif';

import { Grid, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LoaderSpan } from '../SignIn/Elements';
import { monthName } from '../../utills/constants';
import { create } from '../../features/trip/action';
import { useSelector, useDispatch } from 'react-redux';
import { getDurationFromRange } from '../../utills/constants';
import { Text, FormBox3, Edit, TransparentBtn } from './Elements';
import { UserName, ActivityDetail, ActivityHeading } from '../Elements';
import { capitalize, modifyDurationTitle } from '../../utills/constants';
import '../Profile/style.css';

const Step3 = ({ state, handleChange, selectedDayRange }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.trip);

  const handleSubmit = () => {
    console.log('selectedDayRange', selectedDayRange);
    let allUnavailableDates = [];
    state?.unAvailableDays?.allUnavailableDates?.length > 0 &&
      state?.unAvailableDays?.allUnavailableDates.forEach((res) => {
        const utcFormat = moment.utc(res.month + '/' + res.day + '/' + res.year).format();
        const dateFormat = moment.utc(utcFormat).format('YYYY-MM-DD');
        allUnavailableDates.push(dateFormat);
      });
    // let allUnavailableDates = [];
    // state?.unAvailableDays?.allUnavailableDates?.length > 0 &&
    //   state?.unAvailableDays?.allUnavailableDates.forEach((res) => {
    //     allUnavailableDates.push(res.month + '/' + res.day + '/' + res.year);
    //   });
    let unavailableDaysOfWeek = [];
    state?.unAvailableDays?.unavailableDaysOfWeek?.length > 0 &&
      state?.unAvailableDays?.unavailableDaysOfWeek.forEach((res) => {
        const utcFormat = moment.utc(res.month + '/' + res.day + '/' + res.year).format();
        const dateFormat = moment.utc(utcFormat).format('YYYY-MM-DD');
        unavailableDaysOfWeek.push(dateFormat);
      });
    // let unavailableDaysOfWeek = [];
    // state?.unAvailableDays?.unavailableDaysOfWeek?.length > 0 &&
    //   state?.unAvailableDays?.unavailableDaysOfWeek.forEach((res) => {
    //     unavailableDaysOfWeek.push(res.month + '/' + res.day + '/' + res.year);
    //   });
    let excludeSpecificDates = [];
    state?.unAvailableDays?.excludeSpecificDates?.length > 0 &&
      state?.unAvailableDays?.excludeSpecificDates.forEach((res) => {
        const utcFormat = moment.utc(res.month + '/' + res.day + '/' + res.year).format();
        const dateFormat = moment.utc(utcFormat).format('YYYY-MM-DD');
        excludeSpecificDates.push(dateFormat);
      });
    // let excludeSpecificDates = [];
    // state?.unAvailableDays?.excludeSpecificDates?.length > 0 &&
    //   state?.unAvailableDays?.excludeSpecificDates.forEach((res) => {
    //     excludeSpecificDates.push(res.month + '/' + res.day + '/' + res.year);
    //   });
    let title = '';
    let dt = '';
    let dtitle = modifyDurationTitle(state?.durationTitle, state?.durationValue);
    dt = state?.durationValue + ' ' + dtitle;
    title = dt + ' ' + state?.species;
    const availableFrom = moment
      .utc(
        selectedDayRange?.from?.year +
          '-' +
          selectedDayRange?.from?.month +
          '-' +
          selectedDayRange?.from?.day
      )
      .format();
    const availableTo = moment
      .utc(
        selectedDayRange?.to?.year +
          '-' +
          selectedDayRange?.to?.month +
          '-' +
          selectedDayRange?.to?.day
      )
      .format();
    // const availableFrom = `${monthName(selectedDayRange?.from?.month)} ${
    //   selectedDayRange?.from?.day
    // }, ${selectedDayRange?.from?.year} `;
    // const availableTo = `${monthName(selectedDayRange?.to?.month)} ${selectedDayRange?.to?.day}, ${
    //   selectedDayRange?.to?.year
    // } `;
    let body = {
      ...state,
      // status: userInfo?.subscriptionStatus === 'freemium' ? 'suspended' : 'active',
      status: 'active',
      title: title,
      featuredDate: new Date(),
      unAvailableDays: {
        ...state?.unAvailableDays,
        allUnavailableDates: [...excludeSpecificDates, ...unavailableDaysOfWeek],
        excludeSpecificDates: excludeSpecificDates,
        unavailableDaysOfWeek: unavailableDaysOfWeek,
        repeatEvery: {
          endRepeatOn: new Date(),
          value: 2,
          title: 'weeks',
        },
      },
      location: { state: state?.locationState, city: state?.locationCity },
      duration: { title: state?.durationTitle, value: state?.durationValue },
      hostId: userInfo?._id,
      availableTo: availableTo,
      availableFrom: availableFrom,
    };
    console.log('create trip ', body);
    dispatch(create(body, navigate));
  };
  const getUnAvailableDays = () => {
    const a = state?.unAvailableDays?.dayWeekText || '';
    const b = state?.unAvailableDays?.excludeDateText || '';
    return a + ' ' + b;
  };
  return (
    <FormBox3>
      <UserName sx={{ fontSize: '18px' }}>Review Trip Details</UserName>
      <Text sx={{ textAlign: 'start', mb: 2, mt: 3 }}>
        Review your trip details below. If everything looks good, click Create Trip to make the trip
        available for trade offers.
      </Text>
      <Grid container sx={{ width: '100%', my: 1 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mb: 2 }}>
          <ActivityHeading>YOUR OFFERING</ActivityHeading>
          <ActivityDetail sx={{ color: '#101B10' }}>
            {state?.species} {state?.tradeType}
          </ActivityDetail>
          <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 0.2, mt: 2 }}>
                    {state?.tradeOptions?.map((option) => {
                    return <img src={option?.image} key={option?._id} width="20" height="20" />;
                })}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mb: 2 }}>
          <ActivityHeading>TRIP LOCATION</ActivityHeading>
          <ActivityDetail sx={{ color: '#101B10' }}>
            {capitalize(state?.locationCity)}, {state?.locationState}
          </ActivityDetail>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mb: 2 }}>
          <ActivityHeading>TRIP DURATION</ActivityHeading>
          <ActivityDetail sx={{ color: '#101B10' }}>
            {state?.durationValue} {modifyDurationTitle(state?.durationTitle, state?.durationValue)}
          </ActivityDetail>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mb: 2 }}>
          <ActivityHeading>TRIP Availability</ActivityHeading>
          <ActivityDetail sx={{ color: '#101B10' }}>
            {getDurationFromRange(selectedDayRange)}
          </ActivityDetail>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mb: 2 }}>
          <ActivityHeading>UNAVAILABLE DAYS</ActivityHeading>
          <ActivityDetail sx={{ color: '#101B10' }}>
            {state?.unAvailableDays ? getUnAvailableDays() : 'Null'}
          </ActivityDetail>
        </Grid>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <ActivityHeading>TRIP NOTE</ActivityHeading>
          <ActivityDetail sx={{ color: '#101B10' }} className="truncate-two-lines">
            {state?.tripNotes || "N/A"}
          </ActivityDetail>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ActivityHeading>TRIP PHOTOS</ActivityHeading>
          <Grid
            container
            sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
          >
            {state?.photos?.map((res, i) => {
              return (
                <Grid key={i} item xs={2} sm={2} md={2} lg={2} className="uploadedImages">
                  <img src={res} alt="images" />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      <Text sx={{ textAlign: 'start' }} onClick={() => handleChange('steps', 1)}>
        <span>Edit Trip Details</span>
      </Text>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'row',
          mt: 2,
          mb: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text sx={{ color: '#30563A', cursor: 'pointer' }} onClick={() => handleChange('steps', 2)}>
          Back
        </Text>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <TransparentBtn sx={{ px: { lg: 4 } }} onClick={() => handleChange('steps', 1)}>
            Cancel
          </TransparentBtn>
          <Edit onClick={handleSubmit}>
            {loading ? (
              <LoaderSpan>
                <img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} />
                Create Trip
              </LoaderSpan>
            ) : (
              'Create Trip'
            )}
          </Edit>
        </Box>
      </Grid>
    </FormBox3>
  );
};
export default Step3;
