import React, { useState } from 'react';
import { UserName, BrowseFiles } from '../Elements';
import { Grid, Box, FormHelperText } from '@mui/material';
import { uploadImagesToS3 } from '../../features/general/action';
import { UploadHeading, DashedUploadBox, UploadText } from './Elements';
import { Text, FormBox3, AddGrid, Edit, TransparentBtn } from './Elements';

import add from '../../assets/createTrip/add.png';
import cross from '../../assets/profile/cross.png';
import GreenLoader from '../../assets/greenLoader.gif';
import upload from '../../assets/create account/upload.png';
import uploadImages from '../../assets/createTrip/uploadImages.png';

import '../Profile/style.css';
import { useSelector } from 'react-redux';
import OpenLibrary from '../../components/Modal/OpenLibrary';
import { useNavigate } from 'react-router-dom';
import FullPageLoader from '../../components/common/FullPageLoader';
import CustomLightBox from '../../components/common/CustomLightBox';

const Step2 = ({ handleChange, state }) => {
  const [loading, setLoading] = useState(false);
  const [error, seterror] = useState(false);
  const { userInfo } = useSelector((state) => state.auth);
  const navigate = useNavigate()

  const [open, setOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleOpen = (index) => {
    setCurrentImageIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const nextImage = () => {
    setCurrentImageIndex((prev) => (prev + 1 < state?.photos?.length ? prev + 1 : prev));
  };
  const prevImage = () => {
    setCurrentImageIndex((prev) => (prev - 1 >= 0 ? prev - 1 : prev));
  };

  console.log('userInfo', userInfo);
  const uploadFunction = async (e) => {
    setLoading(true);
    seterror(false);
    let result = await uploadImagesToS3(e);
    let temp = [];
    result.forEach((res) => {
      temp.push(res.imgrUrl);
    });
    let arr = [...state.photos, ...temp];
    handleChange('photos', arr);
    setLoading(false);
  };
  const deleteImage = async (index) => {
    setLoading(true);
    let arr = [...state.photos];
    arr.splice(index, 1);
    handleChange('photos', arr);
    setLoading(false);
  };
  const handleSubmit = () => {
    if (state?.photos?.length === 0) {
      seterror(true);
    } else {
      seterror(false);
      handleChange('steps', 3);
    }
  };
  const checkValidity = () => {
    if (state?.photos?.length === 0) return false;
    else return true;
  };
  const handleDrop = (e) => {
    e.preventDefault();
    if(e){
      uploadFunction(e);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  return (
    <FormBox3 onDrop={handleDrop} onDragOver={handleDragOver}>
      <UserName sx={{ fontSize: '18px' }}>Create New Trip</UserName>
      {/* {!loading && (
        <div
          style={{
            position: 'absolute',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
            left: '50%',
            top: '50%',
            transform:"translate(-50%,-50%)"
          }}
        >
          <img src={GreenLoader} width="80" alt="GreenLoader" />
        </div>
      )} */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <UploadHeading sx={{ mb: 2, mt: 3 }}>
          <img src={uploadImages} alt="uploadImages" />
          <span>Trip Photos</span>
        </UploadHeading>
        <OpenLibrary handleChange={handleChange} statePhotos={state.photos} />
      </Box>
      <Text sx={{ textAlign: 'start', mb: 2 }}>
        Add pictures that showcase this trip to help members get a better idea of what to expect.
      </Text>
      <div style={{position:"relative"}}>
      <FullPageLoader isLoading={loading} width="80" background='transparent' />
      
      {state?.photos?.length > 0 ? (
        <Grid
          container
          sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
        >
          {state?.photos?.map((res, i) => {
            return (
              <Grid
                key={i}
                item
                xs={4}
                sm={3}
                md={3}
                lg={2.9}
                className="allimageParent"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}
              >
                <img src={res} alt="images"    onClick={() => handleOpen(i)} />
                <button className="closeallimageParent" onClick={() => deleteImage(i)}>
                  <img src={cross} alt="cross" />
                </button>
              </Grid>
            );
          })}
          <AddGrid item xs={4} sm={3} md={3} lg={2.9} className="allimageParent">
            <label
              htmlFor="myfile"
              className="filebutton"
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img src={add} alt="add" style={{ width: 15, height: 15 }} />
              <span>
                <input
                  accept="image/png,image/jpg, image/jpeg"
                  multiple={true}
                  type="file"
                  id="myfile"
                  onChange={uploadFunction}
                  style={{ display: 'none' }}
                  name="myfile"
                />
              </span>
            </label>
          </AddGrid>
        </Grid>
      ) : (
        <DashedUploadBox>
          <label className="filebutton">
            <img src={upload} alt="upload" />
            <span>
              <input
                multiple={true}
                accept="image/png,image/jpg, image/jpeg"
                type="file"
                id="myfile"
                onChange={uploadFunction}
                style={{ display: 'none' }}
                name="myfile"
              />
            </span>
          </label>
          <UploadText>
            Drag and drop here, or{' '}
            <BrowseFiles>
              <label className="filebutton">
                browse files
                <span>
                  <input
                    multiple={true}
                    type="file"
                    accept="image/png,image/jpg, image/jpeg"
                    id="myfile"
                    onChange={uploadFunction}
                    style={{ display: 'none' }}
                    name="myfile"
                  />
                </span>
              </label>
            </BrowseFiles>
          </UploadText>
        </DashedUploadBox>
      )}
      {error && (
        <FormHelperText sx={{ fontFamily: 'Poppins-Medium', color: '#d32f2f' }}>
          {'Please upload at least one image.'}
        </FormHelperText>
      )}
      </div>

      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'row',
          mt: 3,
          mb: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text sx={{ color: '#30563A', cursor: 'pointer' }} onClick={() => handleChange('steps', 1)}>
          Back
        </Text>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <TransparentBtn sx={{ px: { lg: 4 } }} onClick={() => navigate(-1)}>
            Cancel
          </TransparentBtn>
          <Edit sx={{ opacity: checkValidity() ? 1 : 0.5 }} onClick={handleSubmit}>
            Review Trip
          </Edit>
        </Box>
      </Grid>

      <CustomLightBox
        open={open}
        images={state?.photos}
        currentIndex={currentImageIndex}
        onClose={handleClose}
        onNext={nextImage}
        onPrev={prevImage}
      />
    </FormBox3>
  );
};
export default Step2;
