import React, { useState } from 'react';
import toast from 'react-hot-toast';
import ReplyIcon from '@mui/icons-material/Reply';
import { Avatar, Box, Button, CircularProgress, Modal } from '@mui/material';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  FacebookIcon,
  XIcon,
  WhatsappIcon,
  LinkedinIcon,
} from 'react-share';

import { Description, style, DisputeDescription, GreenButton } from './Elements';
import { Header } from './ModalHeader';
import { CancelButton } from '../Button';
import { FrontEndUrl } from '../../utills/constants';
import shareIcon from '../../assets/menu-icons/share-icon.png';

const ShareTrip = ({ data, title }) => {
  const [show, setShow] = useState(false);
  const [dynamicLink, setDynamicLink] = useState('');
  const [loading, setLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  
  const handleClose = () => {
    setShow(false);
    setIsCopied(false); // Reset button state on modal close
  };

  const handleShow = () => {
    setShow(true);
    createDynamicLink();
  };
  
  async function createDynamicLink() {
    setLoading(true);
    const apiKey = 'AIzaSyAlmf6JkvxhYJOr2DuUvpuwZxKFQJniCNc';
    const dynamicLinkApiUrl = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${apiKey}`;
    const itemLink = `${FrontEndUrl}/#/?mobile=TripDetail/${data?._id}/${data?.hostId?._id}&web=/trip-detail/${data?._id}`;

    try {
      const response = await fetch(dynamicLinkApiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          dynamicLinkInfo: {
            domainUriPrefix: 'https://triptrader.page.link',
            link: itemLink,
            androidInfo: {
              androidPackageName: 'com.triptrader',
              androidFallbackLink: itemLink,
            },
            iosInfo: {
              iosBundleId: 'com.triptrader',
              iosAppStoreId: '6449032922',
              iosFallbackLink: itemLink,
            },
          },
          suffix: {
            option: 'SHORT',
          },
        }),
      });

      const result = await response.json();
      setDynamicLink(result.shortLink);
    } catch (error) {
      toast.error('Failed to generate link, please try again');
    } finally {
      setLoading(false);
    }
  }

  const copyLink = async (link) => {
    try {
      await navigator.clipboard.writeText(link);
      toast.success('Link copied to clipboard!');
      setIsCopied(true);
    } catch (error) {
      toast.error('Failed to copy link, please try again.');
    }
  };
  
  console.log('dynamicLink: ', dynamicLink);
  
  return (
    <>
      <span
        onClick={handleShow}
        style={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: 10,
        }}
      >
        <img src={shareIcon} width="18" />
        {title && (
          <span
            style={{
              fontFamily: 'Poppins-Medium',
              fontSize: 'clamp(12px,1.5vw,14px)',
              color: 'rgb(16, 27, 16)',
              textTransform: 'capitalize',
            }}
          >
            {title}
          </span>
        )}
      </span>

      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: '388px',
            height: '580px',
            alignItems: 'center',
            px: 3,
            pb: 3,
            pt: 2,
          }}
        >
          <Header heading={'Share Trip'} handleClose={handleClose} />
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Avatar sx={{ width: 90, height: 90 }} src={data?.hostId?.image} alt="user-avatar" />
            <Description sx={{ fontWeight: 600, my: 1 }}>
              {data?.hostId?.firstName} {data?.hostId?.lastName}
            </Description>
          </Box>
          <Description sx={{ fontWeight: 600, my: 1 }}>
            {data?.title} - {data?.tradeType}
          </Description>

          <DisputeDescription sx={{ textAlign: 'center', mb: 2 }}>
            Share this trip with your friends and family by copying the link or sharing it directly
            on your favorite social platform!
          </DisputeDescription>

          {loading ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
              <CircularProgress size={32} />
              <Description sx={{ mt: 2 }}>Generating Dynamic Link...</Description>
            </Box>
          ) : (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 2, width: '100%' }}>
                <FacebookShareButton url={dynamicLink}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton url={dynamicLink} title={data?.title}>
                  <XIcon size={32} round />
                </TwitterShareButton>
                <WhatsappShareButton url={dynamicLink} title={data?.title}>
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
                <LinkedinShareButton url={dynamicLink}>
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>
              </Box>

              <Box sx={{ width: '100%', mt: 3 }}>
                <GreenButton
                  onClick={() => copyLink(dynamicLink)}
                  disabled={isCopied}
                  sx={{ width: '100%', whiteSpace: 'nowrap' }}
                  variant="contained"
                  disableripple
                >
                  {isCopied ? 'Link Copied!' : 'Copy Link'}
                </GreenButton>
              </Box>
            </>
          )}

          <CancelButton
            onClick={handleClose}
            type="button"
            variant="contained"
            disableripple
            sx={{ width: '100%', mt: 2 }}
          >
            Cancel
          </CancelButton>
        </Box>
      </Modal>
    </>
  );
};

export default ShareTrip;
