import React, { useState, useEffect } from 'react';

import { daysDropdown } from '../../data';
import { LoaderSpan } from '../SignIn/Elements';
import {
  DateWithoutFormat,
  convertDateStringToObject,
  formatDateStringToObject,
  monthName,
} from '../../utills/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery, Grid, Box, Checkbox, Skeleton, FormControlLabel } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { uploadImagesToS3 } from '../../features/general/action';
import { CustomLabel2 } from '../../components/InputFields/Elements';
import { FreeUserRestriction } from '../../components/Modal/FreeUser';
import { getFunction, _updateFuntion } from '../../features/auth/action';
import { UnAvailableDays } from '../../components/Modal/UnAvailableDaysEdit';
import { DateRangeInput } from '../../components/InputFields/DateRangeInput';
import { MainBox, UserName, InstructionsSpan } from '../Elements';
import api from '../../api';
import { config } from '../../utills/constants';
import { FormBox, Delete, Edit, AddGrid, TransparentBtn, Text } from './Elements';
import {
  Input,
  Dropdown,
  CustomCheckbox,
  SpeciesDropdown,
  EmptyDropdown,
} from '../../components/InputFields/CreateTripFormFields';
import {
  DeleteTripPhoto,
  SuspendTrip,
  DeleteTrip,
  ActivateTrip,
} from '../../components/Modal/TripModals';
import { modifyDurationTitle, getZero } from '../../utills/constants';
import moment from 'moment';
import Loader from '../../assets/loader1.gif';
import Sidebar from '../../components/Sidebar';
import add from '../../assets/createTrip/add.png';
import cross from '../../assets/profile/cross.png';
import speciesIcon from '../../assets/home/species.png';
import uploadImages from '../../assets/createTrip/uploadImages.png';

import '../Profile/style.css';
import { FullTextArea } from '../../components/InputFields/Input';
import OpenLibrary from '../../components/Modal/OpenLibrary';

const EditTrip = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const matches600 = useMediaQuery('(max-width:600px)');
  const [optionLoading, setOptionLoading] = useState(false);
  const [firstTime, setFirstTime] = useState(false);

  const [state, setState] = useState({
    typeOptionsDataList: [],
    typeOptionsData: [],
    typesData: [],
    saveLoading: false,
    activateLoading: false,
    deleteLoading: false,
    suspendLoading: false,
    openUnAvailableDays: false,
    deletePhoto: false,
    suspendTrip: false,
    deleteTrip: false,
    activateTrip: false,
    speciesData: [],
    tradeType: '',
    tradeOptions:[],
    acceptTradeOffers: false,
    availableFrom: new Date(),
    availableTo: new Date(),
    category: '',
    durationTitle: '',
    durationValue: '',
    locationCity: '',
    locationState: '',
    photos: [],
    returnActivity: '',
    species: '',
    status: 'active',
    title: '',
    tripNotes: '',
    unAvailableDays: {
      allUnavailableDates: [],
      dayWeekText: '',
      daysOfWeek: [],
      excludeDateText: '',
      excludeSpecificDates: [],
      repeatEvery: { endRepeatOn: null, value: null, title: 'weeks' },
      unavailableDaysOfWeek: [],
      disableDatesFormated: [],
    },
  });
  const handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const { trip, userInfo } = useSelector((state) => state.auth);

  const { types, species, states } = useSelector((state) => state.general);

  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null,
  });
  const [tempSelectedDate, setTempSelectedDate] = useState({ from: null, to: null });
  const getTrip = () => {
    const body = { url: `/trip?_id=${id}`, name: 'trip' };
    dispatch(getFunction(body));
  };

  const [isChange, setIsChange] = useState(false);

  useEffect(() => {
    setIsChange(false);
  }, [state?.tradeType]);

  const handleTripType = (e, value) => {
    handleChange('tradeOptions', []);
    handleChange('tradeType', value);
    const filteredSpecies = species?.filter((elm) => elm?.type?.name === value);
    handleChange('speciesData', filteredSpecies);
    setTimeout(() => {
      setIsChange(true);
    }, 500);
    handleChange('species', state?.species);
  };
  useEffect(() => {
    getTrip();
    const __availableFrom = {
      year: new Date(trip[0]?.availableFrom).getFullYear(),
      month: Number(moment?.utc(trip && trip[0]?.availableFrom).format('MM')),
      day: Number(moment?.utc(trip && trip[0]?.availableFrom).format('DD')),
    };
    const __availableTo = {
      year: new Date(trip[0]?.availableTo).getFullYear(),
      month: Number(trip && moment?.utc(trip[0]?.availableTo).format('MM')),
      day: Number(trip && moment?.utc(trip[0]?.availableTo).format('DD')),
    };
    setSelectedDayRange({ from: __availableFrom, to: __availableTo });
    setTempSelectedDate({ from: __availableFrom, to: __availableTo })

    let excludeSpecificDates = [],
      unavailableDaysOfWeek = [],
      allUnavailableDates = [];
    trip[0]?.unAvailableDays?.excludeSpecificDates.forEach((res) => {
      excludeSpecificDates.push({
        month: new Date(res).getMonth() + 1,
        year: new Date(res).getFullYear(),
        day: new Date(res).getUTCDate(),
      });
    });
    trip[0]?.unAvailableDays?.unavailableDaysOfWeek.forEach((res) => {
      unavailableDaysOfWeek.push({
        month: new Date(res).getMonth() + 1,
        year: new Date(res).getFullYear(),
        day: new Date(res).getUTCDate(),
      });
    });
    trip[0]?.unAvailableDays?.allUnavailableDates.forEach((res) => {
      allUnavailableDates.push({
        month: new Date(res).getMonth() + 1,
        year: new Date(res).getFullYear(),
        day: new Date(res).getUTCDate(),
      });
    });
    const unAvailableDays = {
      ...trip[0]?.unAvailableDays,
      excludeSpecificDates,
      unavailableDaysOfWeek,
      allUnavailableDates,
    };
    setState((prevState) => ({
      ...prevState,
      tradeType: trip[0]?.tradeType,
      acceptTradeOffers: trip[0]?.acceptTradeOffers,
      availableFrom: __availableFrom,
      availableTo: __availableTo,
      category: trip[0]?.category,
      durationTitle: trip[0]?.duration?.title,
      durationValue: trip[0]?.duration?.value,
      locationCity: trip[0]?.location?.city,
      locationState: trip[0]?.location?.state,
      photos: trip[0]?.photos,
      returnActivity: trip[0]?.returnActivity,
      species: trip[0]?.species,
      status: trip[0]?.status,
      title: trip[0]?.title,
      unAvailableDays: unAvailableDays,
    }));
    // eslint-disable-next-line
  }, []);
  let minDate = '';
  let maxDate = '';

  useEffect(() => {
    const startDate = DateWithoutFormat(trip && trip?.[0]?.availableFrom);
    const endDate = DateWithoutFormat(trip?.[0]?.availableTo);
    const start_date = moment(startDate).format('YYYY-MM-DD');
    const current_date = moment(new Date()).format('YYYY-MM-DD');
    if (minDate == '') {
      minDate =
        start_date < current_date
          ? moment(current_date).format('YYYY-MM-DD')
          : moment(start_date).format('YYYY-MM-DD');
    }
    if (maxDate == '') maxDate = moment(endDate).format('YYYY-MM-DD');

    const __availableFrom = formatDateStringToObject(minDate);
    const __availableTo = formatDateStringToObject(maxDate);

    // const __availableFrom = {
    //   year: new Date(trip[0]?.availableFrom).getFullYear(),
    //   month: Number(moment?.utc(trip && trip[0]?.availableFrom).format('MM')),
    //   day: Number(moment?.utc(trip && trip[0]?.availableFrom).format('DD')),
    // };
    // const __availableTo = {
    //   year: new Date(trip[0]?.availableTo).getFullYear(),
    //   month: Number(trip && moment?.utc(trip[0]?.availableTo).format('MM')),
    //   day: Number(trip && moment?.utc(trip[0]?.availableTo).format('DD')),
    // };
    setSelectedDayRange({ from: __availableFrom, to: __availableTo });
    setTempSelectedDate({ from: __availableFrom, to: __availableTo });
    let excludeSpecificDates = [],
      unavailableDaysOfWeek = [],
      allUnavailableDates = [];
    trip[0]?.unAvailableDays?.excludeSpecificDates.forEach((res) => {
      excludeSpecificDates.push({
        month: new Date(res).getMonth() + 1,
        year: new Date(res).getFullYear(),
        day: new Date(res).getUTCDate(),
        className: 'calendarSelectedDay',
      });
    });
    // trip[0]?.unAvailableDays?.unavailableDaysOfWeek.forEach((res) => {
    //   unavailableDaysOfWeek.push({
    //     month: new Date(res).getMonth() + 1,
    //     year: new Date(res).getFullYear(),
    //     day: new Date(res).getUTCDate(),
    //     className: 'customDisableDays',
    //   });
    // });
    let all_unavailable_dates = trip[0]?.unAvailableDays?.unavailableDaysOfWeek || [];
    all_unavailable_dates = trip[0]?.unAvailableDays?.unavailableDaysOfWeek.map((e) =>
      formatDateStringToObject(DateWithoutFormat(e))
    );

    trip[0]?.unAvailableDays?.allUnavailableDates.forEach((res) => {
      allUnavailableDates.push({
        month: new Date(res).getMonth() + 1,
        year: new Date(res).getFullYear(),
        day: new Date(res).getUTCDate(),
        className: 'customDisableDays',
      });
    });
    const unAvailableDays = {
      ...trip[0]?.unAvailableDays,
      excludeSpecificDates,
      unavailableDaysOfWeek: all_unavailable_dates,
      allUnavailableDates,
      disableDatesFormated: [],
    };
    console.log('unAvailableDays', unAvailableDays);
    const filteredSpecies = species?.filter((elm) => elm?.type?.name === trip[0]?.tradeType);
    handleChange('speciesData', filteredSpecies);
    setState((prevState) => ({
      ...prevState,
      tradeType: trip[0]?.tradeType,
      tradeOptions: trip[0]?.tradeOptions,
      acceptTradeOffers: trip[0]?.acceptTradeOffers,
      availableFrom: __availableFrom,
      availableTo: __availableTo,
      category: trip[0]?.category,
      durationTitle: trip[0]?.duration?.title,
      durationValue: trip[0]?.duration?.value,
      locationCity: trip[0]?.location?.city,
      locationState: trip[0]?.location?.state,
      photos: trip[0]?.photos,
      returnActivity: trip[0]?.returnActivity,
      species: trip[0]?.species,
      status: trip[0]?.status,
      title: trip[0]?.title,
      tripNotes: trip[0]?.tripNotes,
      unAvailableDays: unAvailableDays,
    }));
    // eslint-disable-next-line
  }, [trip]);

  useEffect(() => {
    setTimeout(() => {
      setIsChange(true);
    }, 1000);
    // eslint-disable-next-line
  }, [trip]);

  const uploadFunction = async (e) => {
    let result = await uploadImagesToS3(e);
    let temp = [];
    result.forEach((res) => {
      temp.push(res.imgrUrl);
    });
    let arr = [...state.photos, ...temp];
    handleChange('photos', arr);
  };

  const deleteImage = async (index) => {
    let arr = [...state.photos];
    arr.splice(index, 1);
    handleChange('photos', arr);
  };
  const handleOpenDaysModal = () => {
    if (selectedDayRange?.from !== null || selectedDayRange?.to !== null) {
      handleChange('openUnAvailableDays', true);
      setSelectedDayRange({ ...selectedDayRange });
    }
  };
  const handle = () => {
    handleChange('saveLoading', false);
    navigate(`/updateTrip/success/${id}`);
  };
  const handleUpdate = () => {
    handleChange('saveLoading', true);

    let disablesDatesFormat = [];
    state?.unAvailableDays?.disableDatesFormated?.length > 0 &&
      state?.unAvailableDays?.disableDatesFormated.forEach((res) => {
        disablesDatesFormat.push(res.month + '/' + res.day + '/' + res.year);
      });

    let title = '';
    let dt = '';
    let dtitle = modifyDurationTitle(state?.durationTitle, state?.durationValue);

    dt = state?.durationValue + ' ' + dtitle;
    title = dt + ' ' + state?.species;

    const availableFrom = `${monthName(selectedDayRange?.from?.month)} ${
      selectedDayRange?.from?.day
    }, ${selectedDayRange?.from?.year} `;
    const availableTo = `${monthName(selectedDayRange?.to?.month)} ${selectedDayRange?.to?.day}, ${
      selectedDayRange?.to?.year
    } `;

    let allUnavailableDates = [],
      unavailableDaysOfWeek = [],
      excludeSpecificDates = [];
    state?.unAvailableDays?.allUnavailableDates?.length > 0 &&
      state?.unAvailableDays?.allUnavailableDates.forEach((res) => {
        const utcFormat = moment.utc(res.month + '/' + res.day + '/' + res.year).format();
        const dateFormat = moment.utc(utcFormat).format('YYYY-MM-DD');
        allUnavailableDates.push(dateFormat);
      });
    state?.unAvailableDays?.unavailableDaysOfWeek?.length > 0 &&
      state?.unAvailableDays?.unavailableDaysOfWeek.forEach((res) => {
        const utcFormat = moment.utc(res.month + '/' + res.day + '/' + res.year).format();
        const dateFormat = moment.utc(utcFormat).format('YYYY-MM-DD');
        unavailableDaysOfWeek.push(dateFormat);
      });
    state?.unAvailableDays?.excludeSpecificDates?.length > 0 &&
      state?.unAvailableDays?.excludeSpecificDates.forEach((res) => {
        const utcFormat = moment.utc(res.month + '/' + res.day + '/' + res.year).format();
        const dateFormat = moment.utc(utcFormat).format('YYYY-MM-DD');
        excludeSpecificDates.push(dateFormat);
      });

    let body = {
      url: `/trip/${id}`,
      ...state,
      availableFrom: moment.utc(availableFrom).format(),
      availableTo: moment.utc(availableTo).format(),
      duration: { title: state.durationTitle, value: state.durationValue },
      location: { city: state.locationCity, state: state.locationState },
      title: title,
      unAvailableDays: {
        ...state?.unAvailableDays,
        allUnavailableDates: [...unavailableDaysOfWeek, ...excludeSpecificDates],
        unavailableDaysOfWeek,
        excludeSpecificDates,
        repeatEvery: {
          endRepeatOn: new Date(),
          value: 1,
          title: 'weeks',
        },
      },
      updated_by: userInfo?._id
    };
    console.log('edit Trip', body);
    dispatch(_updateFuntion(body, handle));
  };
  const getUnAvailableDays = () => {
    const a = state?.unAvailableDays?.dayWeekText || '';
    const b = state?.unAvailableDays?.excludeDateText || '';
    return a + ' ' + b;
  };
  const getSubsctiptionStatus = async () => {
    const customerId = userInfo?.customerId; // Replace with the actual customer ID
    let status = '';
    await api.get(`/stripe/getActive/${customerId}`, config).then((res) => {
      status = res.data.status;
    });
    return status;
  };
  const handleActivateTrip = async () => {
    // const subsStatus = await getSubsctiptionStatus();
    // console.log(subsStatus);
    // if (subsStatus !== 'Premium' && subsStatus !== 'PremiumC') {
    //   handleChange('freeUser', true);
    //   // setState((prevState) => ({ ...prevState, activateLoading: false }));
    // } else {
    //   handleChange('activateTrip', true);
    //   // setState((prevState) => ({ ...prevState, activateLoading: false }));
    // }
    handleChange('activateTrip', true);
    // setState((prevState) => ({ ...prevState, activateLoading: false }));
  };
  const handleDurationValue = (e) => {
    const input = e.target.value;
    if (selectedDayRange?.from == null && selectedDayRange?.to == null) {
      if (/^\d*$/.test(input)) {
        handleChange('durationValue', e.target.value);
      }
    } else if (selectedDayRange?.from != null && selectedDayRange?.to != null) {
      const fromDate = moment.utc(
        selectedDayRange?.from?.year +
          '-' +
          getZero(selectedDayRange?.from?.month) +
          '-' +
          selectedDayRange?.from?.day +
          ' 00:00:00',
        'YYYY-MM-DD HH:mm:ss'
      );
      const toDate = moment.utc(
        selectedDayRange?.to?.year +
          '-' +
          getZero(selectedDayRange?.to?.month) +
          '-' +
          selectedDayRange?.to?.day +
          ' 24:00:00',
        'YYYY-MM-DD HH:mm:ss'
      );
      const diffInDays = toDate.diff(fromDate, 'days');
      if (input > diffInDays) {
        // setSelectedDayRange({ from: null, to: null });
        // setTempSelectedDate({ from: null, to: null });
        clearSelectedDates()
        if (/^\d*$/.test(input)) {
          handleChange('durationValue', e.target.value);
        }
      } else {
        if (/^\d*$/.test(input)) {
          handleChange('durationValue', e.target.value);
        }
      }
    }
  };
  const handleDurationTitle = (name, value) => {
    if (selectedDayRange?.from == null && selectedDayRange?.to == null) {
      handleChange('durationTitle', value);
    } else if (selectedDayRange?.from != null && selectedDayRange?.to != null) {
      const fromDate = moment(
        selectedDayRange?.from?.year +
          '-' +
          getZero(selectedDayRange?.from?.month) +
          '-' +
          selectedDayRange?.from?.day +
          ' 00:00:00',
        'YYYY-MM-DD HH:mm:ss'
      );
      const toDate = moment(
        selectedDayRange?.to?.year +
          '-' +
          getZero(selectedDayRange?.to?.month) +
          '-' +
          selectedDayRange?.to?.day +
          ' 24:00:00',
        'YYYY-MM-DD HH:mm:ss'
      );
      const diffInDays = toDate.diff(fromDate, 'days');
      const _input = value == 'weeks' ? Number(state.durationValue) * 7 : state.durationValue;
      if (_input > diffInDays) {
        // setSelectedDayRange({ from: null, to: null });
        // setTempSelectedDate({from: null, to: null });
        clearSelectedDates()
        handleChange('durationTitle', value);
      } else {
        handleChange('durationTitle', value);
      }
    }
  };
  const clearSelectedDates = () => {
    setSelectedDayRange({ from: null, to: null });
    setTempSelectedDate({ from: null, to: null });
    handleChange('unAvailableDays', {
      dayWeekText: '',
      excludeDateText: '',
      daysOfWeek: [],
      disableDates: [],
      disableDatesText: '',
      repeatEvery: 'weeks',
      repeatEveryNumber: 1,
      disableDatesFormated: [],
      unavailableDaysOfWeek: [],
      allUnavailableDates: [],
      excludeSpecificDates: [],
    });
  };
  console.log('testing Abdullah', state.openUnAvailableDays, selectedDayRange);

  useEffect(() => {
    const uniqueCategoryNames = [...new Set(species?.map((item) => item?.type?.name))];
    const filteredCategoriesArray = types?.filter((item) =>
      uniqueCategoryNames?.includes(item?.name)
    );
    handleChange('typesData', filteredCategoriesArray);
  }, []);

  const checkValidity = () => {
    return (
      selectedDayRange['from'] !== null &&
      state?.tradeType?.trim() &&
      state?.locationState?.trim() &&
      state?.locationCity?.trim() &&
      state?.species &&
      state?.returnActivity?.trim() &&
      state.durationValue && state?.photos?.length
    );
  };

  const getTradeTypeOptions = async (tradeType) => {
    setOptionLoading(true)
    handleChange('typeOptionsData', []);
    const typeId = types?.find((el) => el?.name === tradeType)?._id;
    try {
      const response = await api.get(`/tradetypeoptions/list?typeId=${typeId}&status=active`, config);
      const activeOptions = response?.data?.data?.filter((el) => el?.status == 'active');
      handleChange('typeOptionsData', activeOptions);
    } catch (error) {
      console.error('error: ', error);
    } finally{
      setOptionLoading(false)
    }
  };

  useEffect(() => {
    if (state?.tradeType) {
      getTradeTypeOptions(state?.tradeType);
    }
  }, [state?.tradeType]);

  const handleCheckboxChange = (e, option) => {
    const { checked } = e.target;
    const tradeOption = {
      _id: option?._id,
      name: option?.name,
      image: option?.image,
    };

    if (checked) {
      // Add the trade option if it's checked
      handleChange('tradeOptions', [...state.tradeOptions, tradeOption]);
    } else {
      // Remove the trade option if it's unchecked
      const updatedTradeOptions = state.tradeOptions.filter((item) => item.name !== option.name);
      handleChange('tradeOptions', updatedTradeOptions);
    }
  };


  const getTradeTypeList = (options, tradeType) => {
    const deletedOptions = [];
    if (tradeType === trip?.[0]?.tradeType && !firstTime) {
      const optionsIds = options.map((el) => el?._id);
      trip?.[0]?.tradeOptions?.forEach((type) => {
        if (!optionsIds?.includes(type?._id)) {
          deletedOptions.push(type);
        }
      });
      setFirstTime(true);
    }

    let combinedArray = [...deletedOptions, ...options];
    console.log('combinedArray: ', combinedArray);

    handleChange('typeOptionsDataList', combinedArray);
  };

  useEffect(() => {
    getTradeTypeList(state?.typeOptionsData, state?.tradeType);
  }, [state?.typeOptionsData?.length, state?.tradeType]);
  

  return (
    <MainBox sx={{ marginTop: matches600 ? 6 : 0, height: '100%' }}>
      <Sidebar />
      {trip && trip[0] && (
        <FormBox sx={{ width: '40%' }}>
          <UserName sx={{ fontSize: '18px' }}>
            Edit Trip &nbsp;
            <span>{trip[0]?.status === 'suspended' ? '(Suspended)' : ''}</span>
          </UserName>
          <Grid container spacing={matches600 ? 0 : 1}>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 1 }}>
              <CustomLabel2>I want to trade...</CustomLabel2>
              <Dropdown
                value={state?.tradeType || trip?.[0].tradeType}
                placeholder="Example: Central NC Whitetail Hunting"
                data={state.typesData}
                handleChange={handleTripType}
                handleFor="tradeType"
              />
            </Grid>
            {optionLoading ? (
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              </Grid>
            ) : (
              state?.typeOptionsDataList?.length > 0 && (
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 1 }}>
                  <CustomLabel2>Using</CustomLabel2>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                    {state?.typeOptionsDataList?.map((option) => {
                      return (
                        <FormControlLabel
                          key={option?._id}
                          control={
                            <Checkbox
                              checked={state?.tradeOptions?.some((el) => el?.name === option?.name)}
                              onChange={(e) => handleCheckboxChange(e, option)}
                            />
                          }
                          label={
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                              <img src={option?.image} width="20" height="20" />
                              <CustomLabel2>{option?.name}</CustomLabel2>
                            </Box>
                          }
                        />
                      );
                    })}
                  </Box>
                </Grid>
              )
            )}
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 1 }}>
              <CustomLabel2>Located in... </CustomLabel2>
              <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <Grid item xs={8} lg={7}>
                  <Input
                    value={state?.locationCity}
                    placeholder={'Example: Southeastern'}
                    onChange={(e) => handleChange('locationCity', e.target.value)}
                  />
                </Grid>
                <Grid item xs={4} lg={5}>
                  <Dropdown
                    value={state.locationState || trip?.[0].location?.state}
                    placeholder="State"
                    data={states}
                    handleChange={handleChange}
                    handleFor="locationState"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 1 }}>
              <CustomLabel2>Please enter the species </CustomLabel2>

              {isChange ? (
                <SpeciesDropdown
                  value={state?.species}
                  label="Any species"
                  title="Species"
                  data={state?.speciesData}
                  handleChange={handleChange}
                  handleFor="species"
                  placeholder="Example: Whitetail Deer"
                  icon={speciesIcon}
                />
              ) : (
                <EmptyDropdown placeholder="Example: Whitetail Deer" />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Input
                value={state.returnActivity}
                title="In return for..."
                placeholder="Example: Florida Alligator Hunting"
                onChange={(e) => handleChange('returnActivity', e.target.value)}
              />
            </Grid>
            {/* <Grid item xs={12} sm={12} md={12} lg={12} sx={{ paddingTop: "0px !important" }}>
                    <CustomCheckbox value={state?.acceptTradeOffers} handleChange={handleChange} handleFor="acceptTradeOffers" title="Accept other trade offers" />
                </Grid> */}
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
              <CustomLabel2>Trip Duration </CustomLabel2>
              <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <Grid item xs={6} lg={2}>
                  <Input value={state.durationValue} onChange={handleDurationValue} />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Dropdown
                    value={state.durationTitle || trip?.[0].duration?.title}
                    placeholder={'Days'}
                    data={daysDropdown}
                    handleChange={handleDurationTitle}
                    handleFor="durationTitle"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <CustomLabel2>Trip Availability </CustomLabel2>
              <InstructionsSpan>
                Guests will be able to choose between these dates.
              </InstructionsSpan>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <DateRangeInput
                state={state}
                title=""
                sx={{ width: '100%' }}
                width="100%"
                handleChange={handleChange}
                selectedDayRange={selectedDayRange}
                setSelectedDayRange={setSelectedDayRange}
                setTempSelectedDate={setTempSelectedDate}
                tempSelectedDate={tempSelectedDate}
              />
              <button className="ClearDates" onClick={clearSelectedDates}>
                clear Dates
              </button>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              {state?.unAvailableDays?.daysOfWeek?.length > 0 ? (
                <Text sx={{ textAlign: 'start', mt: 2 }}>
                  <CustomLabel2>Unavailable Days</CustomLabel2>
                  {getUnAvailableDays()}
                  <span style={{ marginLeft: 20 }} onClick={handleOpenDaysModal}>
                    Edit
                  </span>
                </Text>
              ) : (
                <>
                  {selectedDayRange?.from !== null && selectedDayRange?.to !== null && (
                    <Text sx={{ textAlign: 'start', mt: 2 }} onClick={handleOpenDaysModal}>
                      <span>Set unavailable days</span>
                    </Text>
                  )}
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
              <CustomLabel2>Trip Note</CustomLabel2>
              <FullTextArea
                rows={2}
                onChange={(e) => handleChange('tripNotes', e.target.value)}
                placeholder={'Optional'}
                title="Trip Note"
                value={state?.tripNotes}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <CustomLabel2
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <img src={uploadImages} alt="uploadImages" style={{ marginRight: 5 }} />
                  Trip Photos
                </CustomLabel2>
                <OpenLibrary handleChange={handleChange} statePhotos={state.photos} />
              </Box>
              <Grid
                container
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                {state?.photos?.map((res, i) => {
                  return (
                    <Grid
                      key={i}
                      item
                      xs={4}
                      sm={3}
                      md={3}
                      lg={2.9}
                      className="allimageParent"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 1,
                      }}
                    >
                      <img src={res} alt="images" />
                      <button className="closeallimageParent" onClick={() => deleteImage(i)}>
                        <img src={cross} alt="cross" />
                      </button>
                    </Grid>
                  );
                })}
                <AddGrid item xs={4} sm={3} md={3} lg={3} className="editImagesAddBtn">
                  <label className="filebutton">
                    <img src={add} alt="add" />
                    <span>
                      <input
                        onChange={uploadFunction}
                        accept="image/png,image/jpg, image/jpeg"
                        multiple={true}
                        type="file"
                        id="myfile"
                        style={{ display: 'none' }}
                        name="myfile"
                      />
                    </span>
                  </label>
                </AddGrid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'row',
              mt: 2,
              mb: 1,
              alignItems: 'center',
              justifyContent: 'flex-start',
              flexWrap:"wrap"
            }}
          >
            <Edit
              sx={{ px: { lg: 3 }, mr: 2,  mb: { xs: 2, sm: 0 } }}
              onClick={handleUpdate}
              disabled={state?.saveLoading || !checkValidity()}
              as={'button'}
            >
              {state?.saveLoading ? (
                <LoaderSpan>
                  <img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} />
                  Save Trip
                </LoaderSpan>
              ) : (
                'Save Trip'
              )}
            </Edit>
            {trip[0]?.status === 'suspended' ? (
              <TransparentBtn
                onClick={handleActivateTrip}
                sx={{ px: { lg: 4 }, mr: 2,  mb: { xs: 2, sm: 0 }, background: '#F2F3F1',pointerEvents:!checkValidity() ? "none" : "" }}
              >
                {state?.activateLoading ? (
                  <LoaderSpan>
                    <img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} />
                    Activate
                  </LoaderSpan>
                ) : (
                  'Activate'
                )}
              </TransparentBtn>
            ) : (
              <TransparentBtn
                onClick={() => handleChange('suspendTrip', true)}
                sx={{ px: { lg: 4 }, mr: 2,  mb: { xs: 2, sm: 0 }, background: '#F2F3F1', pointerEvents:!checkValidity() ? "none" : "" }}
              >
                {state?.suspendLoading ? (
                  <LoaderSpan>
                    <img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} />
                    Suspend
                  </LoaderSpan>
                ) : (
                  'Suspend'
                )}
              </TransparentBtn>
            )}
             
            <Delete onClick={() => handleChange('deleteTrip', true)} sx={{ fontWeight: 'bold', mr: 2 , mb: { xs: 2, sm: 0 } }}>
              {state?.deleteLoading ? (
                <LoaderSpan>
                  <img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} />
                  Delete Trip
                </LoaderSpan>
              ) : (
                'Delete Trip'
              )}
            </Delete>

            <TransparentBtn sx={{ px: { lg: 4 }, mb: { xs: 2, sm: 0 } }} onClick={() => navigate(-1)}>
              Cancel
            </TransparentBtn>
          </Grid>
        </FormBox>
      )}
      <UnAvailableDays
        grandState={state}
        parentState={state}
        open={state.openUnAvailableDays}
        dateRange={selectedDayRange}
        stateName="openUnAvailableDays"
        handle={handleChange}
        handleState={handleChange}
      />
      <DeleteTripPhoto open={state.deletePhoto} stateName="deletePhoto" handle={handleChange} />
      <SuspendTrip
        getTrip={getTrip}
        id={id}
        data={state}
        open={state.suspendTrip}
        stateName="suspendTrip"
        handle={handleChange}
        selectedDayRange={selectedDayRange}
      />
      <DeleteTrip
        getTrip={getTrip}
        id={id}
        data={state}
        open={state.deleteTrip}
        stateName="deleteTrip"
        deleteLoading={state?.deleteLoading}
        handle={handleChange}
      />
      <ActivateTrip
        getTrip={getTrip}
        id={id}
        data={state}
        open={state.activateTrip}
        stateName="activateTrip"
        handle={handleChange}
        selectedDayRange={selectedDayRange}
      />
      <FreeUserRestriction
        getTrip={getTrip}
        id={id}
        data={state}
        open={state.freeUser}
        stateName="freeUser"
        handle={handleChange}
      />
    </MainBox>
  );
};
export default EditTrip;
