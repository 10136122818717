import React, { useEffect } from "react";
import MiscHeader from '../../components/Header/MiscHeader';
import Footer from '../../components/Footer';
import { Grid } from '@mui/material';
import { Black, MainHeading, Orange } from '../Misc/Elements';

import api from "../../api"

import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { saveData, verificationSuccess } from "../../features/auth/reducer";

const AccountVerified = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { id, mode } = useParams()

    const notificationBody = {
        title: `Email Verification`,
        userId: id,
        message: `Your email has been verified`,
        icon: "https://triptrader-assets.s3.amazonaws.com/correct-1677066046999.png",
        data: { topic: 'emailVerified' }
    };

    async function apiCall() {
        await api.get(`/user?_id=${id}`).then(async (res) => {
            if (res?.data?.data[0]?.isEmailVerified === false) {
                const body = { isEmailVerified: true }
                await api.put(`/user/verifyUser/${id}`, body).then(async (doc) => {
                    const payload = { userInfo: doc.data.data, role: 'user', token: doc.data.token }
                    dispatch(verificationSuccess(payload))
                    await api.post(`/user/sendNotification/${id}`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: notificationBody
                    })
                })
            }
        })
    }

    useEffect(() => {
        localStorage.setItem("searchedFromHome", false);

        apiCall()
    }, [])

    useEffect(() => {
        setTimeout(async function () {
            await api.get(`/user?_id=${id}`).then(async (res) => {
                if (res?.data?.data[0]?.isEmailVerified === true && mode == 'web') {
                    dispatch(saveData({ name: 'userInfo', data: res?.data?.data[0] }));
                    navigate('/search')
                }
            });
        }, 1000)
    })



    return (
      <div style={{ fontFamily: 'Poppins-Regular' }}>
        {/* <h2>Your account has been verified!</h2> */}
        <MiscHeader />
        <Grid
          container
          sx={{
            px: 5,
            width: '100%',
            background: '#F3F1EE',
            display: 'flow-root',
            height: '80vh',
            display: 'flex',
          }}
          alignItems={'center'}
        >
          <Grid item xs="12" textAlign={'center'}>
            <MainHeading>
              <Black sx={{ mr: 1, whiteSpace:"nowrap" }}>Your account is</Black> <Orange>Verified!</Orange>
            </MainHeading>
          </Grid>
        </Grid>
        <Footer />
      </div>
    );
}
export default AccountVerified