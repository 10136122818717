import React, { useState } from 'react';

import { Popover } from '@mui/material';

import EmojiPicker from 'emoji-picker-react';
import ClearOutlinelinedIcon from '@mui/icons-material/ClearOutlined';
import './emoji.css';

const Emoji = ({ data, open, handle }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const id = open ? 'simple-popover' : undefined;

  const handleEmoji = (value) => {
    var myString = data.message;
    myString += value.emoji;
    handle('message', myString);
    handle('openEmoji', false);
  };
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      class="Emojiwork"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <div
        className="closeemoji"
        onClick={() => {
          handle('openEmoji', false);
        }}
      >
        <ClearOutlinelinedIcon />
      </div>

      <EmojiPicker
        lazyLoadEmojis={true}
        previewConfig={{ showPreview: false }}
        onEmojiClick={handleEmoji}
        width={290}
      />
    </Popover>
  );
};

export default Emoji;
