import React, { useEffect, useState } from 'react';
import { Box, Grid, useMediaQuery } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
// Components
import Layout from '../../components/layout';
import api from '../../api';
import { config, getSubsctiptionStatus, isDurationAvailable } from '../../utills/constants';
import UserInfoWidget from '../../components/common/UserInfoWidget';
import { ActivityDetail, GreenButton, TransparentBtn, UserName } from '../Elements';
import { WhiteButton } from '../Search/Elements';
import MakeOffer from '../MakeOffer';
import { FreeUserRestriction } from '../../components/Modal/FreeUser';
import { Restriction } from '../../components/Modal/Restriction';
import { FirstMessage, MessageSent } from '../../components/Modal/Message';
import { saveData } from '../../features/profile/reducer';
import AvailableDates from './components/AvailableDates';
import TripBoxMenu from '../Search/TripBoxMenu';
import { getFunction } from '../../features/auth/action';
import { SaveTrip, UnSaveTrip } from '../../components/Modal/TripModals';
import TripInfo from './components/TripInfo';
import { FireStore } from '../../utills/FireStore';
import { FormBox } from '../SavedTrips/Elements';
import ShareTrip from '../../components/Modal/ShareTrip';
import CustomCarousel from '../../components/common/CustomCarousel';
import FullPageLoader from '../../components/common/FullPageLoader';
// MEDIA
import saveTripIcon from '../../assets/save trip icon.png';
import bookmark from '../../assets/search/bookmark.png';
import GreenLoader from '../../assets/greenLoader.gif';
import DetailStatus from './components/DetailStatus';

const TripDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { role, userInfo, isAuth, savedTrips } = useSelector((state) => state.auth);
  const [tripDetails, setTripDetails] = useState({});
  const [detailStatus, setDetailStatus] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [state, setState] = useState({
    unSaveTripModal: false,
    freeUser: false,
    savedTripModal: false,
    mobileOpen: false,
    openMakeOffer: false,
    restrictionModel: false,
    openMessage: false,
    openMessageSent: false,
    tradeType: '',
    step: 5,
    species: '',
    locationState: '',
    userStatus: '',
  });
  const matches575 = useMediaQuery('(max-width:575px)');

  const handleChange = (name, value) => {
    if (role === 'guest' && (name === 'openMakeOffer' || name === 'openMessage')) {
      setState((prevState) => ({ ...prevState, restrictionModel: true }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const isCurrentUser = (userId, hostId) => {
    if (!isAuth) {
      return false;
    }
    if (userId === hostId) {
      return true;
    } else {
      return false;
    }
  };

  const getErrorMessage = (message) => {
    if (message === 'No records found') {
      setDetailStatus('Trip not found or has been deleted');
    } else if (message === 'suspended') {
      setDetailStatus('You cannot view the Trip because Trip is Suspended');
    } else if (message === 'blocked') {
      setDetailStatus('User is Blocked');
    } else if (message === 'blockedByUser') {
      setDetailStatus('You cannot view the Trip because owner has blocked you.');
    } else if (message === 'user is deleted') {
      setDetailStatus('You cannot view the Trip because owner account has been deleted.');
    } else {
      setDetailStatus('');
    }
  };

  const getTripDetails = async (id, loading) => {
    if (loading) {
      setIsLoading(true);
    }
    try {
      // const response = await api.get(`/trip?_id=${id}&userId=${userInfo?._id}`, config);
      const tripParams = {
        _id: id,
        userId: userInfo?._id,
      };
      if (!isAuth) {
        delete tripParams?.userId;
      }
      const response = await api.get(`/trip/sharedTrip`, { params: tripParams }, config);
      setTripDetails(response?.data?.data?.[0]);
      if (isAuth) {
        const BlockedByUser = response?.data?.data?.[0]?.hostId?.followers?.find(
          (el) => el?.userId === userInfo?._id
        );
        // const BlockedTheUser = userInfo?.followers?.find(
        //   (el) => el?.userId  === response?.data?.data?.[0]?.hostId?._id
        // );
        // console.log('BlockedTheUser: ', BlockedTheUser);
        if (response?.data?.data?.[0]?.hostId?.userStatus === 1) {
          getErrorMessage('user is deleted');
          return;
        }
        if (BlockedByUser?.block) {
          getErrorMessage('blockedByUser');
          return;
        }
        if (BlockedByUser?.blockedBy) {
          getErrorMessage('blocked');
          return;
        }
        if (response?.data?.data?.[0]?.hostId?._id !== userInfo?._id) {
          getErrorMessage(response?.data?.data?.[0]?.status);
        }
      }
    } catch (error) {
      console.error('error: ', error);
      getErrorMessage(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMakeOffer = (data) => {
    if (!isAuth) {
      handleChange('restrictionModel', true);
      return;
    }
    handleChange('openMakeOffer', true);
    handleChange('selectedTrip', data);
  };

  const openMessageBox = async (hostdata) => {
    if (!isAuth) {
      handleChange('restrictionModel', true);
      return;
    }
    try {
      localStorage.setItem('customerId', hostdata?.customerId);
      const subsStatus = await getSubsctiptionStatus(userInfo?.customerId);
      if (subsStatus !== 'Premium' && subsStatus !== 'PremiumC') {
        handleChange('freeUser', true);
        return;
      }
      handleChange('selectedUser', hostdata);
      dispatch(saveData({ name: 'profileInfo', data: hostdata }));
      handleChange('openMessage', true);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const handleSaveTrip = async (data) => {
    if (!isAuth) {
      handleChange('restrictionModel', true);
      return;
    }
    setIsSaving(true);
    const payload = {
      tripId: data?._id,
      hostId: data?.hostId,
      userId: userInfo?._id,
    };
    try {
      const response = await api.post('/savetrips', payload, config);
      if (
        response.data.message == `This user doesn't exist.` ||
        response.data.message == 'This user has blocked you.'
      ) {
        toast.error('Cannot Save this Trip');
        return;
      }

      let _body = { name: 'savedTrips', url: `/savetrips?userId=${userInfo?._id}` };
      dispatch(getFunction(_body));
      getTripDetails(id);
      handleChange('savedTripModal', true);
    } catch (error) {
      console.error('error: ', error);
    } finally {
      setIsSaving(false);
    }
  };
  const handleUnSaveTrip = (data) => {
    if (!isAuth) {
      handleChange('restrictionModel', true);
      return;
    }
    handleChange('selectedTrip', data);
    handleChange('unSaveTripModal', true);
  };

  const unSaveTripApi = async () => {
    try {
      const response = await api.delete(`/savetrips/${state?.selectedTrip?.savedTrip}`, config);
      if (
        response.data.message == `This user doesn't exist.` ||
        response.data.message == 'This user has blocked you.'
      ) {
        toast.error('Cannot unSave this Trip');
        return;
      }
      let _body = { name: 'savedTrips', url: `/savetrips?userId=${userInfo?._id}` };
      dispatch(getFunction(_body));
      getTripDetails(id);
      handleChange('unSaveTripModal', false);
    } catch (error) {
      console.error('error: ', error);
    }
  };

  // const getSaveTripIcons = () => {
  //   const isSaved = tripDetails?.savedTrip;
  //   if (isSaved) {
  //     return (
  //       <img
  //         src={saveTripIcon}
  //         alt="unSaveTrip"
  //         onClick={() => handleUnSaveTrip(tripDetails)}
  //         style={{ cursor: 'pointer' }}
  //       />
  //     );
  //   } else {
  //     return (
  //       <img
  //         src={bookmark}
  //         alt="bookmark"
  //         onClick={() => handleSaveTrip(tripDetails)}
  //         style={{ cursor: 'pointer', pointerEvents: isSaving && 'none' }}
  //       />
  //     );
  //   }
  // };

  // const handleUnblock = async () => {
  //   let body = {
  //     name: 'blocked',
  //     url: `/user/unBlockAUser/${userInfo?._id}/${tripDetails?.hostId?._id}`,
  //   };
  //   try {
  //     const response = await api.put(body.url, body, config);
  //     FireStore.updateUserinFirestoreOnlyRoom(userInfo?._id, response?.data?.data);
  //     getTripDetails(id, true);
  //   } catch (error) {
  //     if (error.response) {
  //       toast.error(error.response.data.message, { duration: 4000 });
  //     }
  //   }
  // };

  const isTripSuspended = (status) => {
    return status === 'suspended';
  };

  const tripReactionActions = [
    {
      condition: !isCurrentUser(userInfo?._id, tripDetails?.hostId?._id),
      component: (
        <TripBoxMenu
          data={tripDetails}
          handleChange={handleChange}
          onBlockSuccess={() => getTripDetails(id, true)}
        />
      ),
    },
    {
      condition: true,
      component: <ShareTrip data={tripDetails} />,
    },
    {
      condition: !isCurrentUser(userInfo?._id, tripDetails?.hostId?._id),
      component: (
        <img
          src={tripDetails?.savedTrip ? saveTripIcon : bookmark}
          alt={tripDetails?.savedTrip ? 'unSaveTrip' : 'SaveTrip'}
          onClick={() =>
            tripDetails?.savedTrip ? handleUnSaveTrip(tripDetails) : handleSaveTrip(tripDetails)
          }
          style={{ cursor: 'pointer', pointerEvents: isSaving && 'none' }}
        />
      ),
    },
  ];

  useEffect(() => {
    getTripDetails(id);
  }, [id]);

  return (
    <>
      <Layout>
        {isLoading && <FullPageLoader isLoading={isLoading} width="80" />}
        {detailStatus ? (
          <>
            <DetailStatus
              detailStatus={detailStatus}
              // for unBlock User Button
              onSuccess={() => getTripDetails(id, true)}
              hostId={tripDetails?.hostId?._id}
            />
          </>
        ) : (
          <Box sx={{ marginTop: matches575 ? 6 : 0 }}>
            {tripDetails?._id && (
              <FormBox>
                <UserName sx={{ fontSize: '18px' }}>Trip Detail</UserName>
                <Grid container spacing={1.5} sx={{ my: 1.5 }}>
                  <Grid item xs={8}>
                    <UserInfoWidget user={tripDetails?.hostId} handleChange={handleChange} />
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        gap: 1,
                      }}
                    >
                      {isTripSuspended(tripDetails?.status) ? (
                        <ActivityDetail>(Suspended)</ActivityDetail>
                      ) : (
                        tripReactionActions?.map((el) => {
                          return <>{el?.condition && el?.component}</>;
                        })
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs="12">
                    <CustomCarousel images={tripDetails?.photos} />
                  </Grid>
                  <Grid item xs="12">
                    <TripInfo tripDetails={tripDetails} />
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    justifyContent: 'flex-end',
                    width: '100%',
                    mt: 'auto',
                  }}
                >
                  {isCurrentUser(userInfo?._id, tripDetails?.hostId?._id) ? (
                    <TransparentBtn
                      onClick={() => navigate(`/editTrip/${tripDetails?._id}`)}
                      sx={{ color: '#30563A', px: 3 }}
                    >
                      Edit Trip
                    </TransparentBtn>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      {tripDetails?._id && !isDurationAvailable(tripDetails) && (
                        <span
                          style={{
                            fontFamily: 'Poppins-Medium',
                            fontSize: 'clamp(12px,1.5vw,14px)',
                            color: 'red',
                            textTransform: 'capitalize',
                          }}
                        >
                          Trip Duration Not Available
                        </span>
                      )}

                      <Box
                        sx={{
                          display: 'flex',
                          gap: 1,
                          justifyContent: 'flex-end',
                          width: '100%',
                        }}
                      >
                        <GreenButton
                          onClick={() => handleMakeOffer(tripDetails)}
                          sx={{ width: '50%', whiteSpace: 'nowrap' }}
                          variant="contained"
                          disabled={tripDetails?._id && !isDurationAvailable(tripDetails)}
                        >
                          Make Offer
                        </GreenButton>

                        <TransparentBtn
                          onClick={() => openMessageBox(tripDetails?.hostId)}
                          sx={{ px: 3, width: '50%', whiteSpace: 'nowrap' }}
                        >
                          Message
                        </TransparentBtn>

                        {/* <WhiteButton
                        sx={{ height: 'unset', whiteSpace: 'nowrap' }}
                        onClick={() => openMessageBox(tripDetails?.hostId)}
                        type="submit"
                        variant="contained"
                        disableripple
                      >
                        Message
                      </WhiteButton> */}
                      </Box>
                    </Box>
                  )}
                </Box>
              </FormBox>
            )}
          </Box>
        )}
      </Layout>

      {/* Modals */}
      <FreeUserRestriction open={state.freeUser} handle={handleChange} stateName="freeUser" />
      <Restriction
        open={state.restrictionModel}
        handle={handleChange}
        stateName="restrictionModel"
      />
      <MakeOffer
        open={state.openMakeOffer}
        parentState={state}
        handle={handleChange}
        stateName="openMakeOffer"
      />
      <FirstMessage
        data={state}
        open={state.openMessage}
        handle={handleChange}
        stateName="openMessage"
      />
      <MessageSent
        data={state}
        open={state.openMessageSent}
        handle={handleChange}
        stateName="openMessageSent"
      />
      <SaveTrip
        data={state}
        handleSearchButton={() => getTripDetails(id)}
        open={state.savedTripModal}
        handle={handleChange}
        stateName="savedTripModal"
      />
      <UnSaveTrip
        deleteFunction={unSaveTripApi}
        data={state}
        open={state.unSaveTripModal}
        handle={handleChange}
        stateName="unSaveTripModal"
      />
    </>
  );
};

export default TripDetail;
