import React from 'react';
import {
  Dialog,
  DialogContent,
  IconButton,
  Box,
  DialogActions,
  Button,
  DialogTitle,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';

const CustomLightBox = ({ open, images, currentIndex, onClose, onNext, onPrev }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      PaperProps={{
        style: {
          background: 'rgba(0, 0, 0, 0.7)',
          backdropFilter: 'blur(10px)',
          boxShadow: 'none',
        },
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ height: '100vh', position: 'relative' }}
      >
        <IconButton
          onClick={onPrev}
          disabled={currentIndex === 0}
          sx={{
            color: 'white',
            position: 'absolute',
            left: '10px',
            top: { xs: 'calc(100% - 60px)', md: '50%' },
            transform: { md: 'translateY(-50%)' },
            zIndex: 2,
            backgroundColor: 'transparent',
          }}
        >
          <ArrowBackIosIcon sx={{ fontSize: { xs: '1.5rem', md: '2.5rem' } }} />
        </IconButton>

        <IconButton
          onClick={onClose}
          sx={{ color: 'white', position: 'absolute', right: '16px', top: '16px', zIndex: 2 }}
        >
          <CloseIcon fontSize="large" />
        </IconButton>

        <IconButton
          onClick={onNext}
          disabled={currentIndex === images?.length - 1}
          sx={{
            color: 'white',
            position: 'absolute',
            right: '10px',
            top: { xs: 'calc(100% - 60px)', md: '50%' },
            transform: { md: 'translateY(-50%)' },
            zIndex: 2,
            backgroundColor: 'transparent',
          }}
        >
          <ArrowForwardIosIcon sx={{ fontSize: { xs: '1.5rem', md: '2.5rem' } }} />
        </IconButton>

        <DialogContent
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            padding: 0,
          }}
        >
          <img
            src={images?.[currentIndex]}
            alt={`img-${currentIndex}`}
            style={{
              maxWidth: '90%',
              maxHeight: '90%',
              objectFit: 'contain',
            }}
          />
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default CustomLightBox;
