import React, { useState } from 'react';
import reportRed from '../../assets/messaging/report-red.png';
import { Avatar, Box, Grid, Modal } from '@mui/material';
import { Header } from './ModalHeader';
import { Description, RedButton, style, DisputeDescription, GreenButton } from './Elements';
import { LoaderSpan } from '../../pages/SignIn/Elements';
import { FullTextArea } from '../InputFields/Input';
import { CancelButton } from '../Button';
import { config } from '../../utills/constants';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import api from '../../api';
import Loader from '../../assets/loader1.gif';

const ReportTrip = ({ data, handleChange = () => {} }) => {
  const [show, setShow] = useState(false);
  const [reason, setReason] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isReported, setIsReported] = useState(false);
  const { userInfo, isAuth } = useSelector((state) => state.auth);
  const handleClose = () => {
    setShow(false);
    setIsReported(false);
    setReason('');
  };
  const handleShow = () => {
    if (!isAuth) {
      handleChange('restrictionModel', true);
      return;
    }
    setShow(true);
  };

  const handleReportTrip = async () => {
    setIsLoading(true);
    setIsReported(false);
    const body = {
      tripId: data?._id,
      reportby: userInfo?._id,
      reason: reason,
    };
    try {
      const response = await api.post('/reporttrips', body, config);
      toast.success(response.data.message, { duration: 4000 });
      // handleClose();
      setIsReported(true);
    } catch (error) {
      toast.error(error.response.data.message, { duration: 4000 });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div
        onClick={handleShow}
        style={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: 10,
        }}
      >
        <img src={reportRed} alt="report-icon" width="23" />
        <span
          style={{
            fontFamily: 'Poppins-Medium',
            fontSize: 'clamp(12px,1.5vw,14px)',
            color: 'rgb(16, 27, 16)',
            textTransform: 'capitalize',
          }}
        >
          Report Trip
        </span>
      </div>
      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: '388px',
            height: isReported ? '400px' : '580px',
            alignItems: 'center',
            px: 3,
            pb: 3,
            pt: 2,
          }}
        >
          <Header
            heading={isReported ? 'Trip Reported' : 'Report Trip'}
            handleClose={handleClose}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Avatar sx={{ width: 90, height: 90 }} src={data?.hostId?.image} alt="user-avatar" />
            <Description sx={{ fontWeight: 600, my: 1 }}>
              {data?.hostId?.firstName} {data?.hostId?.lastName}
            </Description>
          </Box>
          <Description sx={{ fontWeight: 600, my: 1 }}>
            {data?.title} - {data?.tradeType}
          </Description>
          <DisputeDescription sx={{ textAlign: 'center' }}>
            {isReported
              ? 'Thank You we will review your report and take any necessary action'
              : '    Tell us about the issues you are having or seeing with this Trip.'}
          </DisputeDescription>
          {isReported ? (
            <>
              <GreenButton
                onClick={handleClose}
                type="button"
                variant="contained"
                disableripple
                sx={{ width: '100%', mt: 1 }}
              >
                Done
              </GreenButton>
            </>
          ) : (
            <>
              <Box sx={{ width: '100%' }}>
                <FullTextArea
                  rows={4}
                  onChange={(e) => setReason(e.target.value)}
                  placeholder="Describe your concerns"
                />
              </Box>
              <Grid sx={{ width: '100%' }}>
                <RedButton
                  onClick={handleReportTrip}
                  type="button"
                  variant="contained"
                  disableripple
                  sx={{ width: '100%', mt: 1 }}
                  disabled={isLoading || reason === ''}
                >
                  {isLoading ? (
                    <LoaderSpan>
                      <img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} />{' '}
                      Report Trip...
                    </LoaderSpan>
                  ) : (
                    ' Report Trip'
                  )}
                </RedButton>
                <CancelButton
                  onClick={handleClose}
                  type="button"
                  variant="contained"
                  disableripple
                  sx={{ width: '100%', mt: 2 }}
                >
                  Cancel
                </CancelButton>
              </Grid>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default ReportTrip;
