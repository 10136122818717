import { styled } from '@mui/material/styles';
import { Autocomplete, Button } from '@mui/material';

export const GreenButton = styled(Button)(() => ({
    height: '40px',
    color: '#FFFFFF',
    display: 'flex',
    cursor: 'pointer',
    fontStyle: 'normal',
    borderRadius: "8px",
    padding: "8px 20px",
    alignItems: "center",
    flexDirection: "row",
    background: "#3C6B49",
    justifyContent: "center",
    border: '1px solid #3C6B49',
    textTransform: 'capitalize',
    fontFamily: 'Poppins-SemiBold',
    "&:disabled": {
        backgroundColor: '#3C6B49',
        color: '#FFFFFF',
        borderColor: '#3C6B49',
        boxShadow: 'none',
        },
    '&:hover': {
        backgroundColor: '#3C6B49',
        borderColor: '#3C6B49',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#3C6B49',
        borderColor: '#3C6B49',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem #3C6B49',
    },
}));

export const CancelButton = styled(Button)(() => ({
    background: "white",
    color: '#30563A',
    display: 'flex',
    flexDirection: "row",
    justifyContent: "center",
    border: '1px solid #DFDFDF',
    alignItems: "center",
    padding: "8px 15px",
    height: '40px',
    textTransform: 'capitalize',
    borderRadius: "8px",
    fontFamily: 'Poppins-SemiBold',
    fontStyle: 'normal',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: 'white',
        borderColor: '#DFDFDF',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: 'white',
        borderColor: '#DFDFDF',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem #DFDFDF',
    },
}));

export const CustomLabel = styled('h4')(() => ({
    marginBottom: 5,
    fontFamily: 'Poppins-Light',
    fontSize: '15px',
    color: "#101B10",
    display: 'flex',
    alignItems: 'center',
}));
export const CustomLabel1 = styled('h4')(() => ({
    marginTop: 5,
    marginBottom: 5,
    fontFamily: 'Poppins-Light',
    fontSize: '.9rem',
    color: "#0E2932",
    '& > :first-of-type': {
        color: "#0E2932",
        fontFamily: 'Poppins-Light',
        cursor: 'pointer',
        textUnderlineOffset: "3px",
        textDecorationLine: "underline",
    },
    '@media(max-width: 600px)': {
        fontSize: '.9rem',

    },
    '@media(max-width: 400px)': {
        fontSize: '.7rem',

    },
}));
export const Label = styled('span')(() => ({
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19px',
    color: '#0E2932',
    '@media(max-width: 600px)': {
        fontSize: '.9rem',
    },
    '@media(max-width: 400px)': {
        fontSize: '.7rem',
    },
}));
export const CustomLabelSmall = styled('h4')(() => ({
    marginTop: 5,
    marginBottom: 5,
    fontFamily: 'Poppins-Regular',
    fontSize: '.8rem',
    color: "#0E2932",
    '& > :first-of-type': {
        fontWeight: 400,
        color: "#1E3625",
        cursor: 'pointer',
        textUnderlineOffset: "3px",
        textDecorationLine: "underline",
    },
    '@media(max-width: 600px)': {
        fontSize: '.9rem',

    },
    '@media(max-width: 400px)': {
        fontSize: '.7rem',

    },
}));
export const CustomLabel2 = styled('h4')(() => ({
    marginTop: 5,
    marginBottom: 5,
    fontFamily: 'Poppins-SemiBold',
    fontSize: '.9rem',
    color: "#1E3625",
    
    '@media(max-width: 600px)': {
        fontSize: '.9rem',

    },
    '@media(max-width: 400px)': {
        fontSize: '.7rem',

    },
}));
export const CustomLabel3 = styled('h4')(() => ({
    marginTop: 5,
    marginBottom: 5,
    fontFamily: 'Poppins-SemiBold',
    fontSize: '.7rem',
    color: "#1E3625",
}));
export const CustomAutocomplete = styled(Autocomplete)(() => ({
    width: "100%",
    height:'50%',
    "& .MuiSvgIcon-root": {
        color: "#14181F",
    },
    ".css-1x8b7y8-MuiAutocomplete-root .MuiOutlinedInput-root":{
        padding:"5px 1px"
    }
}));

