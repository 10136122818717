import React, { useEffect, useState } from 'react';

import { UserName } from '../../Elements';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getFullName } from '../../../utills/constants';
import { Grid, Avatar, useMediaQuery, Badge, Button, Box } from '@mui/material';
import { updateAccount } from '../../../features/auth/action';
import { uploadImageToS3 } from '../../../features/general/action';
import { CoverGrid, Count, FollowerBox, ReviewTabs } from './Elements';
import { FollowerCountRow, FollowButton, FollowBtn } from './Elements';
import { CountName } from '../Elements';

import Trips from './Trips';
import Photos from './Photos';
import Reviews from './Reviews';
import EditIcon from '@mui/icons-material/Edit';
import star from '../../../assets/profile/star.png';
import editImage from '../../../assets/profile/editImage.png';
import verified from '../../../assets/profile/verified.png';

import '../style.css';
import MyPhotos from '../components/MyPhotos';

const ProfileBox = ({ handleDrawerToggle, apiCall }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();

  const matches600 = useMediaQuery('(max-width:600px)');

  const [selectedTab, setSelectedTab] = useState('one');
  const [nestedTab, setNestedTab] = useState('one');

  const { follower, following, userInfo } = useSelector((state) => state.auth);

  const getClassName = (value) => {
    if (value === selectedTab) {
      return 'tab selectedTab';
    } else return 'tab';
  };

  const updateImage = async (e) => {
    let result = await uploadImageToS3(e);
    let body = { image: result[0]?.imgrUrl };
    if (e.target.name === 'coverImage') {
      body = { coverImage: result[0]?.imgrUrl };
    }
    dispatch(updateAccount(body, () => {}, 'editProfilePage'));
  };
  useEffect(() => {
    if (location?.hash === '#trips') {
      setSelectedTab('two');
    } else {
      setSelectedTab('one');
    }
  }, []);

  const photosTabs = [
    {
      title: 'Trip Photos',
      tab: 'one',
    },
    {
      title: 'My Photos',
      tab: 'two',
    },
  ];

  return (
    <Grid container sx={{ background: 'white', height: 'max-content' }}>
      <CoverGrid item xs={12} sm={12} md={12} lg={12} image={userInfo?.coverImage}>
        <div className="cover-edit">
          <input
            onChange={updateImage}
            name="coverImage"
            type="file"
            id="coverUpload"
            accept=".png, .jpg, .jpeg"
          />
          <label htmlFor="coverUpload">
            <img src={editImage} alt="coverImage" />
          </label>
        </div>
      </CoverGrid>
      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        lg={6}
        sx={{ mt: -10, display: 'flex', alignItems: 'center', paddingLeft: matches600 ? 3 : 4 }}
      >
        <div className="avatar-upload">
          <div className="avatar-edit">
            <input
              onChange={updateImage}
              name="profileImage"
              type="file"
              id="imageUpload"
              accept=".png, .jpg, .jpeg"
            />
            <label htmlFor="imageUpload">
              <img src={editImage} alt="editImage" />
            </label>
          </div>
          <div className="avatar-preview">
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              badgeContent={
                userInfo?.identityStatus === 'verified' && (
                  <img alt="Remy Sharp" src={verified} style={{ width: '30px', height: '30px' }} />
                )
              }
            >
              <Avatar src={userInfo?.image} sx={{ width: 100, height: 100 }} />
            </Badge>
          </div>
        </div>
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        lg={6}
        sx={{ mt: 3, pr: 4, width: '90%', display: 'flex', justifyContent: 'flex-end' }}
      >
        {matches600 ? (
          <FollowButton>
            <EditIcon onClick={() => navigate('/profile/edit')} />
          </FollowButton>
        ) : (
          <FollowBtn onClick={() => navigate('/profile/edit')}>{'Edit Profile'}</FollowBtn>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          paddingLeft: matches600 ? 3 : 4,
        }}
      >
        <UserName>
          {getFullName(userInfo)}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: 10,
            }}
          >
            <img
              src={star}
              className="imageShadow"
              style={{ marginRight: 2, marginBottom: 2 }}
              alt={'star'}
            />
            {userInfo?.rating}
          </div>
        </UserName>

        <FollowerCountRow>
          <FollowerBox onClick={handleDrawerToggle}>
            <Count>{follower?.length}</Count>
            <CountName>Followers</CountName>
          </FollowerBox>
          <FollowerBox onClick={handleDrawerToggle}>
            <Count>{following?.length}</Count>
            <CountName>Following</CountName>
          </FollowerBox>
        </FollowerCountRow>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} sx={{ height: '54.4vh', background: 'white' }}>
        <ReviewTabs>
          <span value="one" className={getClassName('one')} onClick={() => setSelectedTab('one')}>
            Reviews
          </span>
          <span value="two" className={getClassName('two')} onClick={() => setSelectedTab('two')}>
            Trips
          </span>
          <span
            value="three"
            className={getClassName('three')}
            onClick={() => setSelectedTab('three')}
          >
            Photos
          </span>
        </ReviewTabs>
        {selectedTab === 'one' && <Reviews apiCall={apiCall} />}
        {selectedTab === 'two' && <Trips />}
        {selectedTab === 'three' && <MyPhotos />}
        {/* {selectedTab === 'three' && (
          <div>
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', my: 2, gap: 1, px: 2 }}>
              {photosTabs?.map((el) => {
                return (
                  <Button
                    variant={nestedTab === el?.tab ? 'contained' : 'outlined'}
                    onClick={() => setNestedTab(el?.tab)}
                    sx={{
                      backgroundColor: nestedTab === el?.tab ? '#326841' : 'transparent',
                      color: nestedTab === el?.tab ? '#fff' : '#326841',
                      borderColor: '#326841',
                      '&:hover': {
                        backgroundColor: nestedTab === el?.tab ? '#275133' : '#e0f2f1', // Slightly darker green on hover
                        borderColor: '#326841',
                      },
                    }}
                  >
                    {el?.title}
                  </Button>
                );
              })}
            </Box>
            {nestedTab === 'one' && <Photos apiCall={apiCall} />}
            {nestedTab === 'two' && <MyPhotos />}
          </div>
        )} */}
      </Grid>
    </Grid>
  );
};
export default ProfileBox;
