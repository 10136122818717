import moment from 'moment';
import Swal from 'sweetalert2';
import calender from '../assets/createTrip/calender.png';
import api from '../api';
// export const baseURL = 'https://devapi.trip-trader.com/api/'; // on working for developers
export const baseURL = 'https://clientdev.trip-trader.com/api/'; // for client
// export const baseURL = 'https://api.trip-trader.com/api/'; // for live and restricted to use
// export const baseURL = 'http://localhost:3001/api/';

// export const FrontEndUrl = `https://staging.trip-trader.com`; // For developers
export const FrontEndUrl = `https://dev.trip-trader.com` // For Client Dev
// export const FrontEndUrl = `https://trip-trader.com` // for live and restricted to use

// export const secretStripeKey =
//   'pk_live_51M9HIuBmhbfqULZ4zLBVSrEMd8hpXSfnEcUQvRnZHdPfkzKy5mXtLINeAaglb9ZoIg3muBImsh2X6Q01b8nylFXU00SiTzkpZp';
export const secretStripeKey =
  'pk_test_51M9HIuBmhbfqULZ4IstWDtc73GFl6mVRnA4jUcOR9BVRkndz1Ou2FSlOeP4WjGgYqlH4LflMtgUY8foGkY58lHAq00OGfQUjlR';

export const swalWithStyledButton = Swal.mixin({
  customClass: {
    confirmButton: 'reload-button',
  },
  buttonsStyling: false,
});
export const config = { headers: { 'Content-Type': 'application/json' } };
export const modifyDurationTitle = (title, value) => {
  if (title == 'days' && value == 1) {
    return 'Day';
  } else if (title == 'days' && value > 1) {
    return 'Days';
  } else if (title == 'weeks' && value > 1) {
    return 'Weeks';
  } else if (title == 'weeks' && value == 1) {
    return 'Week';
  } else return title;
};
export const capitalize = (s) => {
  return s ? s[0].toUpperCase() + s.slice(1) : '';
};

export const getFullName = (userInfo) => {
  const status = userInfo?.userStatus || 0
  if (status < 1) {
    return capitalize(userInfo?.firstName) + ' ' + capitalize(userInfo?.lastName);
  }
  return 'Trip Trader User';
};

export const getUserProfile = (userInfo) => {
  const status = userInfo?.userStatus || 0
  if (status < 1) {
    return userInfo?.image;
  }
  return '';
};

export const getUserStatus = (userInfo) => {
  const status = userInfo?.userStatus || 0
  if (status === 0) {
    return 'active';
  } else if (status === 1) {
    return 'deleted';
  }
};

export function DateWithoutFormat(date) {
  if (date) {
    return new Date(date)?.toISOString()?.slice(0, 10);
  }
}
export function convertDateStringToObject(dateString) {
  // Split the date string by "-"
  const parts = dateString.split('-');

  // Extract day, month, and year from the parts
  const day = parts[2];
  const month = parts[1];
  const year = parts[0];

  // Return an object with day, month, and year properties
  return {
    day: day,
    month: month,
    year: year,
  };
}

export function convertDateStringToObjectrrrr(dateString) {
  // Split the date string by "-"
  const parts = dateString.split('-');

  // Extract day, month, and year from the parts
  const day = parts[2].substr(0, 2);
  const month = parts[1];
  const year = parts[0];

  // Return an object with day, month, and year properties
  return {
    day: parseInt(day),
    month: parseInt(month),
    year: parseInt(year),
  };
}

export function formatDateStringToObject(dateString) {
  // Split the date string by "-"
  const parts = dateString.split('-');

  // Extract day, month, and year from the parts
  const day = parts[2];
  const month = parts[1];
  const year = parts[0];

  // Return an object with day, month, and year properties
  return {
    className: 'customDisableDays',
    month: parseInt(month),
    year: parseInt(year),
    day: parseInt(day),
  };
}
export const displaydate = (datefrom, dateto) => {
  const sd = DateWithoutFormat(datefrom);
  const sdy = parseInt(new Date(sd).getFullYear());
  const ed = DateWithoutFormat(dateto);
  const edy = parseInt(new Date(ed).getFullYear());
  let favlbl = '';

  if (sdy == edy) {
    return moment.utc(sd).format('MMM DD') + ' - ' + moment.utc(ed).format('MMM DD, YYYY');
  } else {
    return moment.utc(sd).format('MMM DD, YYYY') + ' - ' + moment.utc(ed).format('MMM DD, YYYY');
  }
};

export const monthName = (mon) => {
  return ['Jan', 'Feb', 'Mar', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'][
    mon - 1
  ];
};

export const CalendarIcon = () => {
  return <img alt="calendar" src={calender} style={{ zIndex: 20 }} />;
};
export const getSubsctiptionStatus = async (customerId) => {
  // const customerId = userInfo?.customerId; // Replace with the actual customer ID
  if (customerId) {
    let status = '';
    await api.get(`/stripe/getActive/${customerId}`, config).then((res) => {
      status = res.data.status;
    });
    return status;
  }
};

export const getDurationFromRange = (selectedDayRange) => {
  return `${monthName(selectedDayRange?.from?.month)} ${selectedDayRange?.from?.day}, ${
    selectedDayRange?.from?.year
  } - ${monthName(selectedDayRange?.to?.month)} ${selectedDayRange?.to?.day}, ${
    selectedDayRange?.to?.year
  }`;
};

export const getDurationFromDate = (availableFrom, availableTo) => {
  return `${moment.utc(availableFrom).format('MMM DD, yyyy')} - ${moment
    .utc(availableTo)
    .format('MMM DD, yyyy')}`;
};
export const getDurationFromDate_New = (dates) => {
  if (dates?.length > 1) {
    return `${moment(dates[0]).format('MMM DD, yyyy')} - ${moment(dates[dates?.length - 1]).format(
      'MMM DD, yyyy'
    )}`;
  } else if (dates?.length == 1) {
    return `${moment(dates[0]).format('MMM DD, yyyy')}`;
  }
};

export const getDateRange = (startDate, endDate) => {
  var dates = [],
    end = moment(endDate),
    diff = end?.diff(startDate, 'days');

  if (diff <= 0) {
    return;
  }

  for (var i = 0; i < diff; i++) {
    dates.push(end.subtract(1, 'd').format('DD/MMM/YYYY'));
  }

  return dates;
};

export const getDateRangeA = (startDate, totalDays, duration) => {
  var dates = [],
    diff = totalDays;
  if (diff <= 0) {
    return;
  }

  for (var i = 0; i < diff; i++) {
    if (!duration.includes(moment.utc(startDate).add(i, 'd').format('DD MMM YYYY'))) {
      dates.push(moment.utc(startDate).add(i, 'd').format('DD MMM YYYY'));
    } else {
      diff++;
    }
  }
  return dates;
};
export const getDateRangeInObjects = (startDate, endDate) => {
  var dates = [],
    end = moment(endDate),
    diff = endDate.diff(startDate, 'days');

  if (!startDate.isValid() || !endDate.isValid() || diff <= 0) {
    return;
  }

  for (var i = 0; i < diff; i++) {
    dates.push(end.subtract(1, 'd'));
  }

  return dates;
};

export const getZero = (value) => {
  if (value < 10) return `0${value}`;
  else return value;
};

export const getTime = (res) => {
  var a = moment();
  var b = moment(res);
  return a.diff(b, 'days') > 7 ? moment(res).format('MMM DD') : moment(res).fromNow();
};
export const dateToFromNowDaily = (myDate) => {
  var fromNow = moment(myDate).fromNow();
  return moment(myDate).calendar(null, {
    lastWeek: '[Last] dddd',
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    sameElse: function () {
      return '[' + fromNow + ']';
    },
  });
};
export const returnHeaderName = (location) => {
  if (location.pathname.substring(1).includes('profile')) {
    return 'Profile';
  } else if (location.pathname.substring(1).includes('editTrip')) {
    return 'Edit Trip';
  } else if (location.pathname.substring(1).includes('createTrip')) {
    return 'Create Trip';
  } else if (location.pathname.substring(1).includes('tradeOffer')) {
    return 'Trade Offer';
  } else if (location.pathname.substring(1).includes('savedTrips')) {
    return 'Saved Trips';
  } else if (location.pathname.substring(1).includes('confirmedTrips')) {
    return 'Confirmed Trips';
  } else if (location.pathname.substring(1).includes('trip-detail')) {
    return 'Trip Detail';
  } else return location.pathname.substring(1);
};

export const formatRange = (startDate, endDate, includeYear) => {
  if (startDate.isSame(endDate, 'day')) {
    // If the start and end dates are the same, format a single date
    return startDate.format('MMM DD' + (includeYear ? ', YYYY ' : ''));
  } else {
    // If the start and end dates are different, format a date range
    const startFormat = includeYear ? 'MMM DD' : 'MMM';
    return `${startDate.format(startFormat)} - ${endDate.format('MMM DD, YYYY')}`;
  }
};

export const formatDates = (dates) => {
  let formattedDates = [];

  // Create a copy of the dates array
  const sortedDates = [...dates];

  // Sort the dates in ascending order
  sortedDates.sort((a, b) => moment.utc(a) - moment.utc(b));

  let startDate = moment?.utc(sortedDates[0]);
  let endDate = moment?.utc(sortedDates[0]);

  let sameYear = true;
  const year = startDate.format('YYYY');

  for (let i = 1; i < sortedDates.length; i++) {
    const currentDate = moment?.utc(sortedDates[i]);
    const diff = currentDate.diff(endDate, 'days');

    if (diff === 1 && sameYear && currentDate.format('YYYY') === year) {
      // If the current date is consecutive, in the same year, and sameYear is true, update the end date
      endDate = currentDate;
    } else {
      // If the current date is not consecutive or not in the same year, add the formatted range to the result
      formattedDates.push(formatRange(startDate, endDate, sameYear));
      startDate = currentDate;
      endDate = currentDate;

      // Check if the current date is in the same year as the first date
      sameYear = currentDate.format('YYYY') === year;
    }
  }

  // Add the last formatted range to the result
  formattedDates.push(formatRange(startDate, endDate, sameYear));

  return formattedDates.join(',');
};
export const getDate = (unixTimestamp) => {
  // Convert Unix timestamp to milliseconds
  var milliseconds = unixTimestamp * 1000;

  // Create a new Date object using the milliseconds
  var date = new Date(milliseconds);

  // Define month names
  var monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  // Get the month, day, and year
  var month = monthNames[date.getMonth()];
  var day = date.getUTCDate();
  var year = date.getFullYear();

  // Create the formatted date string
  var formattedDate = `${month} ${day}, ${year}`;

  return formattedDate;
};

export const subtractPercentage = (amount, percentage) => {
  if (percentage < 0 || percentage > 100) {
    throw new Error('Percentage must be between 0 and 100');
  }

  const subtraction = (percentage / 100) * amount;
  const result = amount - subtraction;

  return result;
};

const getDifferenceInDays = (start, end) => {
  const startDate = new Date(`${start}T00:00:00.000Z`); // Force UTC
  const endDate = new Date(`${end}T00:00:00.000Z`); // Force UTC
  const diffTime = Math.abs(endDate - startDate);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1; // Convert milliseconds to days and add 1 to include both start and end dates
};

const getCurrentDateFormatted = () => {
  // const date = new Date();
  // return date.toISOString().split('T')[0] + 'T00:00:00Z';  //current date in UTC time with the time set to midnight (00:00:00)
  const now = new Date();
  return now.toLocaleDateString('en-CA');
};

export const isDurationAvailable = (trip) => {
  const { hostId, duration, availableFrom, availableTo, unAvailableDays = null } = trip;

  // // Step 1: Check if the hostId._id matches the current user's ID
  // if (hostId?._id === currentUser._id) {
  //   return false; // If the hostId matches the current user's ID, return false
  // }

  // Step 2: Convert duration into days
  let durationInDays = duration.value;
  if (duration.title === 'weeks') {
    durationInDays *= 7; // Convert weeks to days
  }

  // Step 3: Get the current date in UTC
  // const todayUTC = new Date().toISOString().split("T")[0]; //2024-09-25
  const todayDate = getCurrentDateFormatted();

  // Step 4: Check if the `availableTo` date is in the past
  if (availableTo.split('T')[0] < todayDate) {
    return false; // If the availableTo date is past the current date, return false
  }

  // Step 5: Calculate total available days between availableFrom and availableTo (UTC)
  const totalDays = getDifferenceInDays(availableFrom.split('T')[0], availableTo.split('T')[0]);

  // Step 6: Parse unavailable days (allUnavailableDates) without timezone shifts
  const unavailableDates = unAvailableDays?.allUnavailableDates?.map((date) => date) || [];

  // Step 7: Generate all available dates from availableFrom to availableTo (in UTC)
  let availableDates = [];
  let currentDate = new Date(`${availableFrom.split('T')[0]}T00:00:00.000Z`);
  const endDate = new Date(`${availableTo.split('T')[0]}T00:00:00.000Z`);

  // Loop through each date in the range, ensuring UTC format
  while (currentDate <= endDate) {
    const currentDateString = currentDate.toISOString().split('T')[0]; // Get the date part only (UTC)

    // Check if the current date is unavailable or is a past date
    const isUnavailable =
      unavailableDates?.includes(currentDateString) || currentDateString < todayDate;

    if (!isUnavailable) {
      availableDates.push(currentDateString); // Add to available dates if it's not unavailable and not a past date
    }

    // Move to the next day (UTC safe)
    currentDate.setUTCDate(currentDate.getUTCDate() + 1);
  }

  // Step 8: Check if we have enough available days for the required duration
  if (availableDates?.length >= durationInDays) {
    return true;
  }

  return false;
};

export const isCardExpired = (expMonth, expYear) => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-11, so we add 1
  const currentYear = currentDate.getFullYear();

  // If the expiration year is less than the current year, the card is expired
  if (expYear < currentYear) {
    return true;
  }

  // If the expiration year is the same, check if the expiration month is less than the current month
  if (expYear === currentYear && expMonth < currentMonth) {
    return true;
  }

  // If neither condition is met, the card is not expired
  return false;
};

export const isDurationEditAvailable = (trip) => {
  const { hostId, duration, availableFrom, availableTo, unAvailableDays = null } = trip;

  let durationInDays = duration.value;
  if (duration.title === 'weeks') {
    durationInDays *= 7; // Convert weeks to days
  }

  const todayDate = getCurrentDateFormatted();

  if (availableTo.split('T')[0] < todayDate) {
    return 'You cannot activate the trip because your available to date is in the past';
  }

  const unavailableDates = unAvailableDays?.allUnavailableDates?.map((date) => date) || [];

  let availableDates = [];
  let currentDate = new Date(`${availableFrom.split('T')[0]}T00:00:00.000Z`);
  const endDate = new Date(`${availableTo.split('T')[0]}T00:00:00.000Z`);

  while (currentDate <= endDate) {
    const currentDateString = currentDate.toISOString().split('T')[0];

    const isUnavailable =
      unavailableDates?.includes(currentDateString) || currentDateString < todayDate;

    if (!isUnavailable) {
      availableDates.push(currentDateString);
    }

    currentDate.setUTCDate(currentDate.getUTCDate() + 1);
  }

  if (availableDates?.length >= durationInDays) {
    return 'success';
  }

  return 'You cannot activate the trip because the remaining available days are less than the required duration';
};
