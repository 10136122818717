import api from '../../api';
import toast from 'react-hot-toast';

import { config } from '../../utills';
import {
  startLoading,
  stopLoading,
  saveData,
  freemiumMakeOffer,
  freemiumRecieveOfferTrips,
  freemiumMessage,
  conversationInboxMessage,
} from './reducer';
import { getUserInfo, signout } from '../auth/action';
export const create = (body, navigate) => async (dispatch) => {
  dispatch(startLoading());
  await api
    .post(`/trip`, body, config)
    .then((res) => {
      dispatch(stopLoading());
      dispatch(saveData({ name: 'trip', data: [res.data.data] }));
      navigate(`/createTrip/success/${res.data.data._id}`);
    })
    .catch((err) => {
      if (err.response) {
        toast.error(err.response.data.message, { duration: 4000 });
        dispatch(stopLoading());
      }
    });
};
export const getTrip = (id) => async (dispatch) => {
  dispatch(startLoading());
  await api
    .get(`/trip?_id=${id}`, config)
    .then((res) => {
      dispatch(stopLoading());
      dispatch(saveData({ name: 'trip', data: res.data.data }));
    })
    .catch((err) => {
      if (err.response) {
        toast.error(err.response.data.message, { duration: 4000 });
        dispatch(stopLoading());
      }
    });
};
export const searchTrips = (query, pageName, navigate) => async (dispatch, getState) => {
  dispatch(startLoading());
  const currentState = getState().auth;
  let baseUrl = '/trip/search';
  let queryUrl = '';
  if (currentState?.isAuth) {
    dispatch(getUserInfo(currentState?.userInfo?._id));

    const blockedUsers = [currentState?.userInfo?._id];
    const blockedUsersCall = await api.get(
      `/user/blockedUsers/${currentState?.userInfo?._id}`,
      config
    );
    blockedUsersCall?.data?.blocked?.forEach((res) => {
      if (res?.userId?._id) {
        blockedUsers.push(res?.userId?._id);
      }
    });
    const blockedByUsers = await api.get(
      `/user/blockedByUsers/${currentState?.userInfo?._id}`,
      config
    );
    blockedByUsers?.data?.blockedBy?.forEach((res) => {
      if (res?.userId?._id) {
        blockedUsers.push(res?.userId?._id);
      }
    });
    queryUrl = baseUrl + query + `&blockedUsers=${blockedUsers?.toString()}&page=1&limit=10000&userId=${currentState?.userInfo?._id}`;
  } else if (currentState?.role == 'guest') {
    queryUrl = baseUrl + query + `&page=1&limit=10000`;
  } else {
    dispatch(signout('', navigate));
    queryUrl = baseUrl + query + `&page=1&limit=10000`;
  }
  await api
    .get(queryUrl, config)
    .then((res) => {
      dispatch(stopLoading());
      dispatch(saveData({ name: 'trips', data: res.data.data }));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(saveData({ name: 'trips', data: [] }));
        if (pageName !== 'home') {
          toast.error(err.response.data.message, { duration: 4000 });
        }
        dispatch(stopLoading());
      }
    });
};

export const getAllTrips = () => async (dispatch, getState) => {
  dispatch(startLoading());

  const currentState = getState().auth;

  let baseUrl = '/trip/search?';
  let queryUrl = '';

  if (currentState?.isAuth) {
    const blockedUsers = [currentState?.userInfo?._id];
    currentState?.blocked?.forEach((res) => {
      if (res?.userId?._id) {
        blockedUsers.push(res?.userId?._id);
      }
    });
    queryUrl = baseUrl + `&blockedUsers=${blockedUsers?.toString()}&page=1&limit=10000&userId=${currentState?.userInfo?._id}`;
  } else {
    queryUrl = baseUrl + `&page=1&limit=10000`;
  }
  await api
    .get(queryUrl, config)
    .then((res) => {
      dispatch(stopLoading());
      dispatch(saveData({ name: 'trips', data: res.data.data }));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(saveData({ name: 'trips', data: [] }));
        dispatch(stopLoading());
      }
    });
};

export const searchFunction = (word) => async (dispatch, getState) => {
  console.log('getState', getState);
  dispatch(startLoading());

  const currentState = getState().auth;

  let baseUrl = '/trip/search?';

  let queryUrl = '';

  if (currentState?.isAuth) {
    const blockedUsers = [currentState?.userInfo?._id];
    console.log('blockedUsers', blockedUsers);

    const blockedUsersCall = await api.get(
      `/user/blockedUsers/${currentState?.userInfo?._id}`,
      config
    );
    blockedUsersCall?.data?.blocked?.forEach((res) => {
      if (res?.userId?._id) {
        blockedUsers.push(res?.userId?._id);
      }
    });
    const blockedByUsers = await api.get(
      `/user/blockedByUsers/${currentState?.userInfo?._id}`,
      config
    );

    blockedByUsers?.data?.blockedBy?.forEach((res) => {
      if (res?.userId?._id) {
        blockedUsers.push(res?.userId?._id);
      }
    });

    queryUrl =
      baseUrl + `query=${word}&blockedUsers=${blockedUsers?.toString()}&page=1&limit=10000&userId=${currentState?.userInfo?._id}`;
  } else {
    queryUrl = baseUrl + `query=${word}&page=1&limit=10000`;
  }
  await api
    .get(queryUrl, config)
    .then((res) => {
      dispatch(stopLoading());
      dispatch(saveData({ name: 'trips', data: res.data.data }));
      dispatch(stopLoading());
    })
    .catch((err) => {
      if (err.response) {
        dispatch(saveData({ name: 'trips', data: [] }));
        dispatch(stopLoading());
      }
    });
};
export const freemiumMakeOfferTrip = (body) => async (dispatch) => {
  console.log('from the Function', body);
  dispatch(freemiumMakeOffer(body));
};
export const freemiumRecieveOffer = (body) => async (dispatch) => {
  console.log('from the Function', body);
  dispatch(freemiumRecieveOfferTrips(body));
};
export const freemiumNote = (body) => async (dispatch) => {
  console.log('from the Function', body);
  dispatch(freemiumMessage(body));
};
export const conversationMessage = (body) => async (dispatch) => {
  console.log('from the Function', body);
  dispatch(conversationInboxMessage(body));
};
