import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Menu as MenuIcon } from "@mui/icons-material";
import { JoinNow, SignIn, JoinNowMob, SignInMob } from "./Elements"
import { List, ListItem, ListItemButton, Drawer } from '@mui/material';
import { AppBar, Box, Toolbar, IconButton, Typography, } from '@mui/material';

// import logo from "../../assets/logo-svg.svg"
import logo from "../../assets/logos/logo-lg.svg"
// import logo from "../../assets/logos/logo-lg.png"

const HomeHeader = ({ props }) => {

    const { window } = props || {};

    const navigate = useNavigate()

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => { setMobileOpen(!mobileOpen) };

    const container = window !== undefined ? () => window()?.document.body : undefined;

    return (
        <Box
        sx={{
          display: 'flex',
          pr: 3,
          pl: 4,
        //   background: '#3C6B49',
          background: '#283C2E',
          py: 2,
        }}
      >
            <AppBar position="static" sx={{ background: 'transparent', boxShadow: 'none', paddingLeft: 0, paddingRight: 0 }}>
                <Toolbar className="header-left-logo" sx={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Box sx={{
                        display: { md: 'none', sm: 'flex', xs: 'flex' },
                        flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', width: '100%'
                    }} >
                        <Typography
                            onClick={() => navigate('/')}
                            variant="h6"
                            component="div"
                            sx={{
                                flexGrow: 1,
                                fontFamily: "Suez One", display: 'flex', alignItems: 'center',
                            }}>

                            {/* <img src={logo} width={100} alt="logo" style={{ paddingRight: 10, paddingLeft: 10 }} />  TRIP TRADER */}
                            <img src={logo} alt="logo" width={70} style={{ paddingRight: 10, objectFit:"contain", height:"auto", maxWidth: "100%" }} /> TRIP TRADER
                        </Typography>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}>
                            <MenuIcon />
                        </IconButton>
                    </Box>
                    <Typography
                        onClick={() => navigate('/')}

                        variant="h5"
                        component="div"
                        sx={{ flexGrow: 1, fontFamily: "Suez One", display: { xs: 'none', sm: 'none', md: 'flex' }, alignItems: 'center' }} >
                        <img src={logo} alt="logo" width={80} style={{ paddingRight: 10, objectFit:"contain"  }} />  TRIP TRADER
                    </Typography>
                    <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
                        <SignIn href="/#/signin"> Sign In </SignIn>
                        <JoinNow href="/#/join"> Join Now </JoinNow>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    anchor={'top'}
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true
                    }}
                    sx={{
                        display: { sm: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%', background: 'transparent' },
                    }}
                >
                    <Box onClick={handleDrawerToggle} sx={{
                        p: 2,
                        backgroundColor: '#283C2E',
                        color: '#fff',
                        opacity: .8
                    }}>
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton sx={{ fontFamily: 'Poppins-SemiBold', disply: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: '100%' }} >
                                    <SignInMob href="/#/signin"> Sign In </SignInMob>
                                    <JoinNowMob href="/#/join"> Join Now </JoinNowMob>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Box>
                </Drawer>
            </Box>
        </Box >
    );
};
export default HomeHeader