import React, { useState } from "react";

import Component2 from "../ReviewComponent/Component2"
import PublicReviews from "../ReviewComponent/PublicReviews"

import { useSelector } from "react-redux";
import { MainReviewGrid } from "../Private/Elements"
import { LeaveReviewModal } from "../../../components/Modal/Review"


const Reviews = ({ apiCall }) => {

    const [state, setState] = useState({ openLeaveReviewModal: false })

    const handleChange = (name, value) => { setState(prevState => ({ ...prevState, [name]: value })); };

    const { reviews, profileInfo } = useSelector(state => state.profile)

    return <MainReviewGrid>
        {reviews?.data?.length === 0 ?
            <Component2 info={profileInfo} handleChange={handleChange} /> :
            <PublicReviews apiCall={apiCall} handleChange={handleChange} />}
        <LeaveReviewModal apiCall={apiCall} open={state.openLeaveReviewModal} stateName="openLeaveReviewModal" handle={handleChange} />
    </MainReviewGrid>
}
export default Reviews