import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
// API
import api from '../../../api';
import { config, swalWithStyledButton } from '../../../utills/constants';
// Util
import { uploadImagesToS3 } from '../../../features/general/action';
// Components
import NoData from '../ReviewComponent/NoData';
import { CountName, TransparentBtn } from '../Elements';
import { ScrollAbleGrid } from '../Private/Elements';
import { LoaderSpan } from '../../SignIn/Elements';
import CustomLightBox from '../../../components/common/CustomLightBox';
// icons
import cross from '../../../assets/profile/cross.png';
import Loader from '../../../assets/loader1.gif';
import { useParams } from 'react-router-dom';
import { startLoading, stopLoading } from '../../../features/auth/reducer';
import toast from 'react-hot-toast';

// const scrollGridStyles = {
//   pb: 3,
//   overflowY: 'auto',
//   maxHeight: '30vh',
//   scrollbarWidth: 'thin',
//   '&::-webkit-scrollbar': {
//     width: '8px',
//     marginLeft: '-4px',
//   },
//   '&::-webkit-scrollbar-track': {
//     background: 'white',
//   },
//   '&::-webkit-scrollbar-thumb': {
//     backgroundColor: '#D9D9D9',
//   },
//   '&::-webkit-scrollbar-thumb:hover': {
//     backgroundColor: '#D9D9D9',
//   },
//   '@media(max-width: 1000px)': {
//     maxHeight: '100%',
//   },
// };

const imgBoxStyles = {
  height: 200,
  position: 'relative',
  display: 'inline-block',
  pr: 1,
  pb: 1,
};

const MyPhotos = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { userInfo, loading: isUploading } = useSelector((state) => state.auth);
  const [photos, setPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [params, setParams] = useState({
    limit: 9999999999,
    // page: 1,
    userId: id,
  });
  const [open, setOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const isCurrentUser = (loginUserId, userId) => {
    return loginUserId === userId;
  };

  const handleOpen = (index) => {
    setCurrentImageIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const nextImage = () => {
    setCurrentImageIndex((prev) => (prev + 1 < photos.length ? prev + 1 : prev));
  };
  const prevImage = () => {
    setCurrentImageIndex((prev) => (prev - 1 >= 0 ? prev - 1 : prev));
  };

  const handleError = (error) => {
    console.log('error: ', error);
    if (error?.response?.data?.message === 'No records found') {
      setPhotos([]);
    }
  };

  const handleParamChange = (name, value) => {
    setParams((prevState) => ({ ...prevState, [name]: value }));
  };

  const getPhotos = async (params) => {
    // setIsLoading(true);
    try {
      const response = await api.get(`usergallery`, { params }, config);
      if (response?.data?.data) {
        setPhotos(response.data.data || []);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = async (event) => {
    try {
      dispatch(startLoading());
      const images = await uploadImagesToS3(event);
      const payload = images?.map((el) => {
        return { fileId: el?.imgrUrl, user: userInfo?._id };
      });
      const response = await api.post(`usergallery`, payload, config);
      if (response?.data?.data) {
        getPhotos(params);
      }
    } catch (error) {
      console.log('error: ', error);
    } finally {
      dispatch(stopLoading());
    }
  };

  const handleDelete = async (id) => {
    swalWithStyledButton
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        confirmButtonText: 'Yes, delete it!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await api.delete(`usergallery/${id}`, config);
            if (response?.data?.message === 'Record Deleted') {
              getPhotos(params);
              toast.success(response?.data?.message, { duration: 4000 });
            }
          } catch (error) {
            console.error('error: ', error);
          }
        }
      });
  };

  useEffect(() => {
    getPhotos(params);
  }, [params]);

  return (
    <>
      <Grid sx={{ px: 2, marginLeft: 2 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'baseline',
            justifyContent: 'space-between',
            mb: 2,
            mt: 0,
            p: 0,
          }}
        >
          <CountName>
            {photos?.length} Photo{photos?.length > 1 ? 's' : ''}
          </CountName>
          {isCurrentUser(userInfo?._id, id) && (
            <label
              htmlFor="myPhotos"
              style={{ margin: 0, pointerEvents: isUploading ? 'none' : '', cursor: 'pointer' }}
            >
              <TransparentBtn>
                {isUploading ? (
                  <>
                    <LoaderSpan>
                      <img src={Loader} width={22} alt="loading..." style={{ marginRight: 6 }} />
                      Uploading...
                    </LoaderSpan>
                  </>
                ) : (
                  <>
                    <span>Upload Media</span>
                    <input
                      accept="image/png,image/jpg, image/jpeg"
                      multiple={true}
                      type="file"
                      id="myPhotos"
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                    />
                  </>
                )}
              </TransparentBtn>
            </label>
          )}
        </Box>

        {isLoading ? (
          <div>
            <LoaderSpan>
              <img src={Loader} width={22} alt="loading..." style={{ marginRight: 6 }} />
              <span style={{ fontFamily: 'Poppins-SemiBold', fontSize: '13px' }}>Loading...</span>
            </LoaderSpan>
          </div>
        ) : photos?.length > 0 ? (
          //   <Grid container sx={scrollGridStyles}>
          <ScrollAbleGrid container>
            {photos?.map((res, index) => {
              return (
                <Grid item xs={12} md={6} lg={4} key={index} sx={imgBoxStyles}>
                  <img
                    src={res?.fileId}
                    alt="images"
                    onClick={() => handleOpen(index)}
                    style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 5 }}
                  />
                  {isCurrentUser(userInfo?._id, id) && (
                    <button className="close" onClick={() => handleDelete(res?._id)}>
                      <img src={cross} alt="cross" />
                    </button>
                  )}
                </Grid>
              );
            })}
          </ScrollAbleGrid>
        ) : (
          <NoData text={'No photos available.'} />
        )}
      </Grid>

      <CustomLightBox
        open={open}
        images={photos.map((photo) => photo.fileId)}
        currentIndex={currentImageIndex}
        onClose={handleClose}
        onNext={nextImage}
        onPrev={prevImage}
      />
    </>
  );
};

export default MyPhotos;
