import React, { useEffect, useState } from 'react';

import { GreenButton } from './Elements';
import { capitalize, getSubsctiptionStatus, isDurationAvailable, secretStripeKey } from '../../utills/constants';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Box, useMediaQuery, Typography } from '@mui/material';
import { getFunction, updateAccount } from '../../features/auth/action';
import { _postFuntion } from '../../features/auth/action';
import { SearchInput } from '../../components/InputFields';
import { Restriction } from '../../components/Modal/Restriction';
import { LinkSent } from '../../components/Modal/EmailVerification';
import { config, swalWithStyledButton } from '../../utills/constants';
import { FreeUserRestriction } from '../../components/Modal/FreeUser';
import { SaveTrip, UnSaveTrip } from '../../components/Modal/TripModals';
import { searchTrips, searchFunction, freemiumMakeOfferTrip } from '../../features/trip/action';
import { LeftGrid, RightGrid, SearchedRow, NestedGrid } from './Elements';

import { FirstMessage, MessageSent } from '../../components/Modal/Message';
import api from '../../api';
import TripBox from './TripBox';
import MakeOffer from '../MakeOffer';
import FilterBox from './FilterBox';
import Sidebar from '../../components/Sidebar';
import search from '../../assets/search/search.png';
import GreenLoader from '../../assets/greenLoader.gif';
import Layout from '../../components/layout';

import toast from 'react-hot-toast';

import './style.css';
import { useNavigate } from 'react-router-dom';
import { reload } from '../../features/auth/action';
import { stopLoading, startLoading } from '../../features/auth/reducer';
import FreeTrialModal from '../MakeOffer/FreeTrialModal';
const Search = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    dispatch(freemiumMakeOfferTrip({}));
  };

  const [state, setState] = useState({
    unSaveTripModal: false,
    freeUser: false,
    savedTripModal: false,
    mobileOpen: false,
    openMakeOffer: false,
    restrictionModel: false,
    openMessage: false,
    openMessageSent: false,
    tradeType: '',
    step: 5,
    species: '',
    locationState: '',
    userStatus: '',
  });

  const handleChange = (name, value) => {
    if (role === 'guest' && (name === 'openMakeOffer' || name === 'openMessage')) {
      setState((prevState) => ({ ...prevState, restrictionModel: true }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: value }));
    }
  };
  const { trips: result, loading } = useSelector((state) => state.trip);
  const trips = result?.filter((item) => {
    const isAvl = isDurationAvailable(item);
    if (isAvl) return item;
  });
  console.log('trips: ====>', trips);
  const { role, userInfo, isAuth, savedTrips } = useSelector((state) => state.auth);
  const matches = useMediaQuery('(max-width:1200px)');
  const matches600 = useMediaQuery('(max-width:600px)');
  const matches710 = useMediaQuery('(max-width:710px)');
  const freemiumMakeOffer = useSelector((state) => state.trip);
  console.log('offer', freemiumMakeOffer?.freemiumMakeOfferTrip);
  const handleDrawerToggle = () => {
    handleChange('mobileOpen', !state.mobileOpen);
  };

  const handleSearchButton = (type) => {
    if(type === "search"){
      handleChange("query",state?.query?.trim())
      if (!state?.query?.trim()) {
        toast.error('Empty Spacing Not Allowed', { duration: 4000 });
        return;
      }
    }
    var lowerCase = state?.query ? state?.query?.toLowerCase() : '';
    localStorage.setItem('searchedFromHome', false);
    dispatch(searchFunction(lowerCase?.trim()));
  };

  const reSendLink = () => {
    const body = {
      url: '/user/resendEmail/',
      userEmail: userInfo.email,
      userId: userInfo._id,
      mode: 'web',
    };
    dispatch(_postFuntion(body));
  };

  const handleFilterButton = () => {
    let query;
    if (state.userStatus === '') {
      query = `?query=${capitalize(state.query)}&location=${capitalize(
        state.locationState
      )}&species=${capitalize(state.species)}&tradeType=${capitalize(state.tradeType)}`;
    } else if (state.userStatus !== '') {
      query = `?query=${capitalize(state.query)}&userStatus=${
        state.userStatus
      }&location=${capitalize(state.locationState)}&species=${capitalize(
        state.species
      )}&tradeType=${capitalize(state.tradeType)}`;
    }
    handleDrawerToggle();
    localStorage.setItem('searchedFromHome', false);
    localStorage.setItem('queryFromHome', null);
    dispatch(searchTrips(query, 'search', navigate));
  };

  async function emailVerificationValidation() {
    if (isAuth) {
      await api.get(`/user?_id=${userInfo?._id}`).then(async (res) => {
        saveUserPlatformDetails(res?.data?.data[0])
        if (res?.data?.data[0]?.isEmailVerified === true) {
          handleChange('openLinkSent', false);
        } else if (res?.data?.data[0]?.isEmailVerified === false) {
          handleChange('openLinkSent', true);
        }
      });
    }
  }

  const getActiveStripe = async () => {
    if (userInfo?.customerId) {
      await api.get(`/stripe/getActive/${userInfo?.customerId}`);
    }
  };

  const saveUserPlatformDetails = async (userInfo) => {
    try {
      if(userInfo){
        const platformDetails = {
          device: 'web',
          deviceModel: navigator?.userAgentData?.platform || '',
          deviceName: navigator?.userAgent || '',
          apiLevel: '',
          appVersion: '',
        };
  
        let body = { signInDetails: platformDetails };
        if (userInfo && !userInfo?.signUpDetails) {
          body.signUpDetails = platformDetails;
          // alert("hi")
        }
        dispatch(updateAccount(body, ()=>{}, 'editProfilePage'));
      }
    } catch (error) {
      console.error('error: ', error);
    }
  };

  const apiCall = (type) => {
    let body = {};
    if (type === 'notifications') {
      body['name'] = 'notifications';
      body['url'] = `/notification/app/${userInfo?._id}?page=1&limit=10000`;
    }
    dispatch(getFunction(body));
  };
  useEffect(() => {
    getActiveStripe();
    if (role === 'user' && isAuth === true) {
      apiCall('notifications');
    }
  }, []);
  useEffect(() => {
    let searchedFromHome = localStorage.getItem('searchedFromHome');
    let queryFromHome = localStorage.getItem('queryFromHome');
    const dataFromHom = queryFromHome && JSON.parse(queryFromHome);
    console.log('dataFromHom', dataFromHom);
    if (searchedFromHome == 'true') {
      setState((prev) => ({
        ...prev,
        tradeType: dataFromHom?.tradeType,
        species: dataFromHom?.species,
        locationState: dataFromHom?.location,
      }));
      handleChange('query', dataFromHom?.query);
    }
    if (searchedFromHome == 'false' || searchedFromHome == null) {
      const query = `?`;
      dispatch(searchTrips(query, 'search', navigate));
      if (isAuth) {
        let body = {
          name: 'notifications',
          url: `/notification/app/${userInfo?._id}?page=1&limit=100000`,
        };
        dispatch(getFunction(body));
      }
    }
    emailVerificationValidation();
    // eslint-disable-next-line
  }, []);

  const resetFunction = () => {
    handleChange('userStatus', '');
    handleChange('locationState', '');
    handleChange('tradeType', '');
    handleChange('species', '');
    handleChange('query', '');
    const query = `?`;
    dispatch(searchTrips(query, 'search', navigate));
  };
  const updateUser = () => {
    // let body = { name: 'userInfo', url: `/user/getUser/${userInfo?._id}` };
    let body = { name: 'savedTrips', url: `/savetrips?userId=${userInfo?._id}` }
    dispatch(getFunction(body));
    handleSearchButton()
  };

  const unSaveTrip = async () => {
    // const saveTrip = savedTrips?.find((el)=>el?.tripId?._id === state?.selectedTrip?._id)
    dispatch(startLoading());
    // let body = {
    //   tripId: state.selectedTrip._id,
    //   hostId: state?.selectedTrip?.hostId?._id,
    //   name: 'userInfo',
    //   url: `/user/unSaveTrip/${userInfo?._id}`,
    // };
    // dispatch(updateFuntion(body))
    // updateUser()
    //
    // await api
    //   .put(body.url, body, config)
    await api
      .delete(`/savetrips/${state?.selectedTrip?.savedTrip}`, config)
      .then((res) => {
        // dispatch(stopLoading());
        if (
          res.data.message == `This user doesn't exist.` ||
          res.data.message == 'This user has blocked you.'
        ) {
          handleChange('unSaveTripModal', false);
          swalWithStyledButton
            .fire({
              icon: 'error',
              allowOutsideClick: false,
              allowEscapeKey: false,
              text: res.data.message,
              confirmButtonText: 'Reload',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            })
            .then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                dispatch(stopLoading());
                dispatch(reload(userInfo?._id, navigate));
                handleSearchButton();
              } else if (result.isDenied) {
                dispatch(stopLoading());
                dispatch(reload(userInfo?._id, navigate));
                handleSearchButton();
              }
            });
        } else {
          updateUser();
          handleChange('unSaveTripModal', false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, { duration: 4000 });
          dispatch(stopLoading());
        }
      });
  };

  const submitFunction = async () => {
    await api
      .post(
        freemiumMakeOffer?.freemiumMakeOfferTrip.url,
        freemiumMakeOffer?.freemiumMakeOfferTrip,
        config
      )
      .then((resp) => {
        handleOpen();
        if (
          resp?.data?.message == `This trip doesn't exist.` ||
          resp?.data?.message == `This user has blocked you.` ||
          resp?.data?.message == `This trip has been suspended.`
        ) {
          swalWithStyledButton
            .fire({
              icon: 'error',
              allowOutsideClick: false,
              allowEscapeKey: false,
              text: resp?.data?.message,
              confirmButtonText: 'Reload',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            })
            .then((result) => {
              dispatch(stopLoading());
              if (result.isConfirmed) {
                dispatch(reload(userInfo?._id, navigate));
              } else if (result.isDenied) {
                dispatch(reload(userInfo?._id, navigate));
              }
            });
        } else {
          dispatch(stopLoading());
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, { duration: 4000 });
        }
      });
  };
  const checkPremiumOfUser = async () => {
    const subsStatus = await getSubsctiptionStatus(userInfo?.customerId);
    if (subsStatus === 'Premium' || subsStatus === 'PremiumC') {
      if (Object.keys(freemiumMakeOffer?.freemiumMakeOfferTrip).length !== 0) {
        submitFunction();
      } else {
        console.log('Object is empty');
      }
    } else {
      dispatch(freemiumMakeOfferTrip({}));
    }
  };
  useEffect(() => {
    checkPremiumOfUser();
  }, []);
  return (
    <>
      {/* <Box sx={{ marginTop: matches600 ? 6 : 0, display: 'flex' }}> */}
      {/* <Grid container spacing={2}>
        <Grid item xs={0} sm={1.5} md={1} lg={0.8}>
          <Sidebar />
        </Grid>
        <LeftGrid item xs={12} sm={10.5} md={11} lg={8}>
          <Grid container spacing={2} sx={{ width: '97%', margin: 'auto' }}>
            {!matches600 && (
              <Grid item xs={12} sm={10} md={10} lg={10}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    fontFamily: 'Suez One',
                  }}
                >
                  TRIP TRADER
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} sm={10} md={10} lg={10}>
              <SearchInput
                handleSearch={handleSearchButton}
                handleChange={handleChange}
                icon={search}
                state={state}
                isEndAdornment={matches ? true : false}
                handleClick={handleDrawerToggle}
              />
            </Grid>
            {!matches600 && (
              <Grid item sm={2} md={2} lg={2} sx={{ pr: 1 }}>
                <GreenButton
                  onClick={handleSearchButton}
                  sx={{ height: '45px', width: '100%' }}
                  type="submit"
                  variant="contained"
                  disableripple
                >
                  {' '}
                  Search{' '}
                </GreenButton>
              </Grid>
            )}
            <SearchedRow
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{ px: { lg: 20, sm: 0, cursor: 'unset' } }}
            >
              {trips?.length > 0 && trips?.length + ` results found`}
              {trips?.length === 0 && `No results found`}
            </SearchedRow>
            <Grid item md={12} lg={12}>
              <NestedGrid container md={12} lg={12} sx={{ opacity: loading ? 0.5 : 1 }}>
                <>
                  {' '}
                  {loading && (
                    <div
                      style={{
                        position: 'absolute',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        textAlign: 'center',
                        left: '38%',
                        top: '50%',
                        zIndex: '99999',
                      }}
                    >
                      <img src={GreenLoader} width="80" alt="GreenLoader" />
                    </div>
                  )}
                  {trips?.map((res, i) => {
                    return (
                      <Grid
                        key={i}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        sx={{
                          marginBottom: matches600 ? 5 : 0,
                          paddingRight: matches710 ? '1rem' : 0,
                          pointerEvents: loading ? 'none' : '',
                        }}
                      >
                        <TripBox
                          handleSearchButton={handleSearchButton}
                          key={i}
                          state={state}
                          data={res}
                          handleChange={handleChange}
                        />
                      </Grid>
                    );
                  })}
                </>
              </NestedGrid>
            </Grid>
          </Grid>
        </LeftGrid>
        <RightGrid item xs={0} sm={0} md={0} lg={3.2}>
          <FilterBox
            resetFunction={resetFunction}
            handleFilterButton={handleFilterButton}
            handleChange={handleChange}
            state={state}
            mobileOpen={state.mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
          />
        </RightGrid>
      </Grid> */}
      <Layout>
        <LeftGrid item xs={12} sm={12} md={12} lg={9}>
          <Grid container spacing={2} sx={{ width: '97%', margin: 'auto' }}>
            {!matches600 && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    fontFamily: 'Suez One',
                  }}
                >
                  TRIP TRADER
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <SearchInput
                handleSearch={handleSearchButton}
                handleChange={handleChange}
                icon={search}
                state={state}
                isEndAdornment={matches ? true : false}
                handleClick={handleDrawerToggle}
              />
            </Grid>
            {!matches600 && (
              <Grid item sm={2} md={2} lg={2} sx={{ pr: 1 }}>
                <GreenButton
                  onClick={()=>handleSearchButton("search")}
                  sx={{ height: '45px', width: '100%' }}
                  type="submit"
                  variant="contained"
                  disableripple
                  disabled={!state?.query}
                >
                  {' '}
                  Search{' '}
                </GreenButton>
              </Grid>
            )}
            <SearchedRow
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{ px: { lg: 20, sm: 0, cursor: 'unset' } }}
            >
              {trips?.length > 0 && trips?.length + ` results found`}
              {trips?.length === 0 && `No results found`}
            </SearchedRow>
            <Grid item md={12} lg={12}>
              <NestedGrid container md={12} lg={12} sx={{ opacity: loading ? 0.5 : 1 }} spacing={2}>
                <>
                  {' '}
                  {loading && (
                    <div
                      style={{
                        position: 'absolute',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        textAlign: 'center',
                        left: '38%',
                        top: '50%',
                        zIndex: '99999',
                      }}
                    >
                      <img src={GreenLoader} width="80" alt="GreenLoader" />
                    </div>
                  )}
                  {trips?.map((res, i) => {
                    return (
                      <Grid
                        key={i}
                        item
                        xs={12}
                        // sm={6}
                        md={6}
                        // lg={4}
                        xl={4}
                        sx={{
                          // marginBottom: matches600 ? 5 : 0,
                          // paddingRight: matches710 ? '1rem' : 0,
                          pointerEvents: loading ? 'none' : '',
                        }}
                      >
                        <TripBox
                          handleSearchButton={handleSearchButton}
                          key={i}
                          state={state}
                          data={res}
                          handleChange={handleChange}
                        />
                      </Grid>
                    );
                  })}
                </>
              </NestedGrid>
            </Grid>
          </Grid>
        </LeftGrid>
        <RightGrid item sx={{ display: { xs: 'none', lg: 'block' } }} lg={3}>
          <FilterBox
            resetFunction={resetFunction}
            handleFilterButton={handleFilterButton}
            handleChange={handleChange}
            state={state}
            mobileOpen={state.mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
          />
        </RightGrid>
      </Layout>

      <FreeUserRestriction open={state.freeUser} handle={handleChange} stateName="freeUser" />
      <SaveTrip
        data={state}
        handleSearchButton={handleSearchButton}
        open={state.savedTripModal}
        handle={handleChange}
        stateName="savedTripModal"
      />
      <Restriction
        open={state.restrictionModel}
        handle={handleChange}
        stateName="restrictionModel"
      />
      <FirstMessage
        data={state}
        open={state.openMessage}
        handle={handleChange}
        stateName="openMessage"
      />
      <MakeOffer
        open={state.openMakeOffer}
        parentState={state}
        handle={handleChange}
        stateName="openMakeOffer"
      />
      <MessageSent
        data={state}
        open={state.openMessageSent}
        handle={handleChange}
        stateName="openMessageSent"
      />
      <UnSaveTrip
        deleteFunction={unSaveTrip}
        data={state}
        open={state.unSaveTripModal}
        handle={handleChange}
        stateName="unSaveTripModal"
      />
      <LinkSent
        open={state?.openLinkSent}
        reSendLink={reSendLink}
        handle={handleChange}
        stateName={'openLinkSent'}
        data={userInfo}
      />
      <FreeTrialModal
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        handleOpen={handleOpen}
        OfferSent={'Offer sent to'}
        freeTrialData={freemiumMakeOffer?.freemiumMakeOfferTrip?.offeredTrip?.hostId}
      />
    </>
  );
};
export default Search;
