import React from 'react';
import MiscHeader from '../../components/Header/MiscHeader';
import Footer from '../../components/Footer';
import { Grid } from '@mui/material';
import { Black, MainHeading, Orange } from '../Misc/Elements';
import { GreenButton } from '../Elements';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div>
      <MiscHeader />
      <Grid
        container
        sx={{
          px: 5,
          width: '100%',
          background: '#F3F1EE',
          display: 'flow-root',
          height: '80vh',
          display: 'flex',
        }}
        alignItems={'center'}
      >
        <Grid item xs="12" textAlign={'center'}>
          <MainHeading>
            <Black sx={{ mr: 1 }}>404</Black> <Orange>Error</Orange>
          </MainHeading>
          <GreenButton onClick={() => navigate('/')}>Go Back To Home</GreenButton>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

export default NotFound;
