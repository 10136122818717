import React, { useEffect, useState } from 'react';
import CustomTripBox from './TripBox';
import Sidebar from '../../components/Sidebar';

import { FormBox } from './Elements';
import { useMediaQuery, Box, Button, Tab } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getFunction } from '../../features/auth/action';
import { MainBox, UserName, CountName } from '../Elements';
import { Tabs } from '../TradeOffers/Elements';

const TradeOffer = () => {
  const dispatch = useDispatch();

  const [filteredTrips, setFilteredTrips] = useState([]);
  const [tab, setTab] = useState('upcoming');

  const { userInfo, confirmedTrips } = useSelector((state) => state.auth);

  const matches600 = useMediaQuery('(max-width:600px)');

  const apiCall = () => {
    let body = { name: 'confirmedTrips', url: `/offer/getConfirmedTrips/${userInfo?._id}` };
    dispatch(getFunction(body));
  };

  const getFilteredTrips = (type, trips) => {
    setTab(type);
    if (type === 'upcoming') {
        const now = new Date();
        const date = now.toLocaleDateString("en-CA");
        const today = new Date(date).toISOString();
        const newTrips = trips?.filter((trip) => {
          const allPreferredDatesPast = trip?.preferredDates?.some(
            (date) => date >= today
          );
          const allTripDatesPast = trip?.tripDates?.some((date) => date >= today);
    
          return allPreferredDatesPast || allTripDatesPast;
        });
      setFilteredTrips(newTrips);
    } else if (type === 'past') {
        const now = new Date();
        const date = now.toLocaleDateString("en-CA");
        const today = new Date(date).toISOString();
        const newTrips = trips?.filter((trip) => {
          const allPreferredDatesPast = trip?.preferredDates?.every(
            (date) => date < today
          );
          const allTripDatesPast = trip?.tripDates?.every((date) => date < today);
    
          return allPreferredDatesPast && allTripDatesPast;
        });
      setFilteredTrips(newTrips);
    }
  };

  useEffect(() => {
    getFilteredTrips('upcoming', confirmedTrips);
  }, [confirmedTrips]);

  useEffect(() => {
    apiCall();
    // eslint-disable-next-line
  }, []);

  const handleChange = (event, newValue) => {
    getFilteredTrips(newValue, confirmedTrips);
  };

  return (
    <MainBox sx={{ marginTop: matches600 ? 6 : 0 }}>
      <Sidebar />
      <FormBox>
        <UserName sx={{ fontSize: '18px' }}>Confirmed Trips</UserName>
        <Tabs value={tab} onChange={handleChange}>
          <Tab label="Upcoming Trips" value="upcoming"  />
          <Tab label="Previous Trips" value="past" />
        </Tabs>
        {/* <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', my: 2, gap: 1 }}>
          <Button
            variant={tab === 'upcoming' ? 'contained' : 'outlined'}
            onClick={() => getFilteredTrips('upcoming', confirmedTrips)}
            sx={{
              backgroundColor: tab === 'upcoming' ? '#326841' : 'transparent',
              color: tab === 'upcoming' ? '#fff' : '#326841',
              borderColor: '#326841',
              '&:hover': {
                backgroundColor: tab === 'upcoming' ? '#275133' : '#e0f2f1', // Slightly darker green on hover
                borderColor: '#326841',
              },
            }}
          >
            Upcoming Trips
          </Button>
          <Button
            variant={tab === 'past' ? 'contained' : 'outlined'}
            onClick={() => getFilteredTrips('past', confirmedTrips)}
            sx={{
              backgroundColor: tab === 'past' ? '#326841' : 'transparent',
              color: tab === 'past' ? '#fff' : '#326841',
              borderColor: '#326841',
              '&:hover': {
                backgroundColor: tab === 'past' ? '#275133' : '#e0f2f1', // Slightly darker green on hover
                borderColor: '#326841',
              },
            }}
          >
            Previous Trips
          </Button>
        </Box> */}
        {filteredTrips?.length === 0 && (
          <>
            {/* <UserName sx={{ fontSize: '18px' }}>Confirmed Trips</UserName> */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '77vh',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CountName>You have no confirmed trip(s) yet.</CountName>
            </Box>
          </>
        )}
        {filteredTrips?.length > 0 && (
          <>
            {/* <UserName sx={{ fontSize: '18px' }}>Confirmed Trips</UserName> */}
            <CountName sx={{ mb: 2, mt: 1 }}>
              You have {filteredTrips?.length} {tab === "upcoming" ? "upcoming" : "previous"} trip(s)
            </CountName>
            {filteredTrips?.map((res, i) => {
              return <CustomTripBox res={res} key={i} />;
            })}
          </>
        )}
      </FormBox>
    </MainBox>
  );
};
export default TradeOffer;
